import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { ResponsiveDirective } from './shared/directives/responsive.directive';
import { ResponsivePipe } from './pipe/responsive.pipe';

@NgModule({
  declarations: [ResponsiveDirective, ResponsivePipe],
  imports: [CommonModule, LayoutModule],
  exports: [ResponsiveDirective, ResponsivePipe],
})
export class ResponsiveModule {}
