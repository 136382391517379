/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Component,
  ViewEncapsulation,
  Input,
  OnChanges,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AnnotationComponent } from '../../news-annotation/news-annotation.component';
import { MatLegacyTabNav as MatTabNav } from '@angular/material/legacy-tabs';
import { debounceTime } from 'rxjs/operators';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-count',
  templateUrl: './news-count.component.html',
  styleUrls: ['./news-count.component.scss'],
})
export class NewsCountComponent implements OnChanges, OnInit, OnDestroy {
  @Input() newsfeedData: any;

  @ViewChild(MatTabNav) public matTabNav: MatTabNav;

  valueSubject = new BehaviorSubject<any>({ type: null, value: null });

  params: any;
  // titolo$: Observable<YukkApi>
  previousValue: any;
  datacount: any;
  note$: Observable<any>;
  keyd: string;
  notes = [];
  iselect = true;
  ngxpie = {
    data: null,
    color: {
      domain: [
        this.colorpipe.transform(1),
        this.colorpipe.transform(0.5),
        this.colorpipe.transform(0),
        this.colorpipe.transform(-1),
      ],
    },
  };
  moreinfo = false;
  onote = true;
  noise = true;
  duplicate: boolean;
  showArticleSentiment: boolean;
  titleEntity: boolean;
  licensedContent: boolean;
  fadeout: boolean;
  dimmed: boolean;
  selectedOptions = ['en', 'de'];
  loading: boolean;
  newstype: string;
  navLinks = [];
  project: any;

  relevancy = {
    sentence_with_entity_threshold: 0.35,
    entity_span_threshold: 0.9,
    high_entity_article_threshold: 0.0,
    elasticsearch_score_decay: 0.5,
    entity_boost: 100,
  };

  screenSize$ = this.breakpointService.screenSize$;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    public config: ConfigService,
    private colorpipe: ColorPipe,
    public dialog: MatDialog,
    private breakpointService: BreakpointService,
  ) {
    this.project = this.config.appConfig.routing.project;

    this.route.queryParams.subscribe((params) => {
      if (this.routing.isNewsletter() && this.auth.newsletterParams) {
        this.params = this.auth.newsletterParams;
      } else {
        this.params = params;
      }

      this.noise = params.noise === 'true';
      this.licensedContent = params.license === 'true';
      this.duplicate = true;
      if (params.duplicate) {
        this.duplicate = params.duplicate === 'true';
      }
      this.showArticleSentiment = false;
      this.titleEntity = params.title === 'true';
      this.fadeout = true;
      this.dimmed = true;
      if (this.routing.isScanner()) {
        this.navLinks = this.auth.featureFlags.showTopNews
          ? ['Relevant', 'Chronological', 'Stories', 'Events']
          : (this.navLinks = ['Chronological', 'Stories', 'Events']);
      } else if (
        params.type &&
        [
          'hierarchy',
          'industry',
          'sector',
          'supersector',
          'subsector',
        ].includes(params.type) &&
        !this.routing.isNewsaudit()
      ) {
        this.navLinks = this.auth.featureFlags.showTopNews
          ? ['Relevant', 'Chronological', 'Stories', 'Bookmarked']
          : ['Chronological', 'Stories', 'Bookmarked'];
      } else if (
        params.type &&
        [
          'hierarchy',
          'industry',
          'sector',
          'supersector',
          'subsector',
        ].includes(params.type) &&
        this.routing.isNewsaudit()
      ) {
        if (
          !this.auth.featureFlags.flaggingEnabled &&
          !auth.featureFlags.dismissingEnabled
        ) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Bookmarked']
            : ['Chronological', 'Bookmarked'];
        } else if (!auth.featureFlags.flaggingEnabled) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Bookmarked', 'Dismissed']
            : ['Chronological', 'Bookmarked', 'Dismissed'];
        } else if (!auth.featureFlags.dismissingEnabled) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Bookmarked', 'Flagged']
            : ['Chronological', 'Bookmarked', 'Flagged'];
        } else {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? [
                'Relevant',
                'Chronological',
                'Bookmarked',
                'Flagged',
                'Dismissed',
              ]
            : ['Chronological', 'Bookmarked', 'Flagged', 'Dismissed'];
        }
      } else if (
        !(this.routing.isQuery() || this.routing.isSearch()) &&
        this.routing.isNewsaudit()
      ) {
        this.subscription = this.breakpointService.screenSize$.subscribe(
          (screen: { isMobile: boolean }) => {
            if (screen.isMobile) {
              this.navLinks = this.auth.featureFlags.showTopNews
                ? [
                    'Relevant',
                    'Chronological',
                    'Stories',
                    'Events',
                    'Bookmarked',
                  ]
                : ['Chronological', 'Stories', 'Events', 'Bookmarked'];
            } else {
              if (
                !this.auth.featureFlags.flaggingEnabled &&
                !auth.featureFlags.dismissingEnabled
              ) {
                this.navLinks = this.auth.featureFlags.showTopNews
                  ? ['Relevant', 'Chronological', 'Bookmarked']
                  : ['Chronological', 'Bookmarked'];
              } else if (!auth.featureFlags.flaggingEnabled) {
                this.navLinks = this.auth.featureFlags.showTopNews
                  ? ['Relevant', 'Chronological', 'Bookmarked', 'Dismissed']
                  : ['Chronological', 'Bookmarked', 'Dismissed'];
              } else if (!auth.featureFlags.dismissingEnabled) {
                this.navLinks = this.auth.featureFlags.showTopNews
                  ? ['Relevant', 'Chronological', 'Bookmarked', 'Flagged']
                  : ['Chronological', 'Bookmarked', 'Flagged'];
              } else {
                this.navLinks = this.auth.featureFlags.showTopNews
                  ? [
                      'Relevant',
                      'Chronological',
                      'Bookmarked',
                      'Flagged',
                      'Dismissed',
                    ]
                  : ['Chronological', 'Bookmarked', 'Flagged', 'Dismissed'];
              }
            }
          },
        );
      } else if (
        (this.routing.isQuery() || this.routing.isSearch()) &&
        this.routing.isNewsaudit()
      ) {
        if (
          !this.auth.featureFlags.flaggingEnabled &&
          !auth.featureFlags.dismissingEnabled
        ) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Bookmarked']
            : ['Chronological', 'Bookmarked'];
        } else if (!auth.featureFlags.flaggingEnabled) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Bookmarked', 'Dismissed']
            : ['Chronological', 'Bookmarked', 'Dismissed'];
        } else if (!auth.featureFlags.dismissingEnabled) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Bookmarked', 'Flagged']
            : ['Chronological', 'Bookmarked', 'Flagged'];
        } else {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? [
                'Relevant',
                'Chronological',
                'Bookmarked',
                'Flagged',
                'Dismissed',
              ]
            : ['Chronological', 'Bookmarked', 'Flagged', 'Dismissed'];
        }
      } else if (this.routing.isQuery() || this.routing.isSearch()) {
        if (this.routing.isChart() || this.routing.isChart2()) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Stories', 'Bookmarked']
            : ['Chronological', 'Stories', 'Bookmarked'];
        } else {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Stories', 'Bookmarked']
            : ['Chronological', 'Stories', 'Bookmarked'];
        }
      } else {
        if (
          (this.routing.isChart() || this.routing.isChart2()) &&
          (!this.routing.isFolio() || params?.portfolioId)
        ) {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Stories', 'Events', 'Bookmarked']
            : ['Chronological', 'Stories', 'Events', 'Bookmarked'];
        } else {
          this.navLinks = this.auth.featureFlags.showTopNews
            ? ['Relevant', 'Chronological', 'Stories', 'Bookmarked']
            : ['Chronological', 'Stories', 'Bookmarked'];
        }
      }
      if (!(this.routing.isFolio() || this.routing.isMarket())) {
        this.navLinks = this.navLinks.filter((link) => link !== 'Relevant');
      }
      if (!this.auth.featureFlags.bookmarkingEnabled) {
        this.navLinks = this.navLinks.filter((link) => link !== 'Bookmarked');
      }
      if (this.auth.showArticleSentiment) {
        this.showArticleSentiment = this.auth.showArticleSentiment;
      }
      if (params.fadeout) {
        this.fadeout = params.fadeout === 'true';
      }
      if (params.dimmed) {
        this.dimmed = params.dimmed === 'true';
      }
      if (!params.news && params.newsday) {
        this.keyd = params.type + ':' + params.id;
        this.auth.getNote(this.keyd).subscribe((risp) => {
          let newnote = [];
          if (risp.length) {
            newnote = risp.filter((item) => item.position === params.newsday);
          }
          if (this.notes !== newnote) {
            this.notes = newnote;
          }
        });
      }
      if (params.note) {
        this.auth.getNoteByid(params.note).subscribe((risp) => {
          this.dialog.closeAll();
          const dialogRef = this.dialog.open(AnnotationComponent, {
            autoFocus: false,
            data: risp,
          });
          dialogRef.afterClosed().subscribe(() => {
            this.router.navigate([], {
              queryParams: {
                note: null,
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          });
        });
      }

      if (
        (params.id || params.isin) &&
        routing.reFresh(params, this.previousValue, [
          'update',
          'updatePayload',
          'type',
          'id',
          'isin',
          'time',
          'period',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks',
          'panels',
          'tag',
          'participant',
          'newsday',
          'newstype',
          'eventid',
          'event_ids',
          'newsfeedFromQuery',
          'factuality',
          'temporality',
        ])
      ) {
        this.loading = true;
        if (this.auth.featureFlags.showTopNews) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.params.newstype
            ? (this.newstype = this.params.newstype)
            : this.routing.isFolio() || this.routing.isMarket()
              ? (this.newstype = 'Relevant')
              : (this.newstype = 'Chronological');
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.params.newstype
            ? (this.newstype = this.params.newstype)
            : (this.newstype = 'Chronological');
        }
        if (this.newstype === 'Relevant') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else if (this.newstype === 'Chronological') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else if (this.newstype === 'Events') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else if (this.newstype === 'Stories') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
          if (!this.params.duplicate) {
            this.duplicate = false;
          }
        }
      }
      this.previousValue = params;
    });
  }

  ngOnChanges() {
    if (this.newsfeedData) {
      this.injectNewsfeed(this.newsfeedData);
    } else {
      this.loading = true;
    }

    setTimeout(() => {
      if (this.matTabNav) {
        this.matTabNav._alignInkBarToSelectedTab();
      }
    }, 500);
  }

  ngOnInit() {
    this.valueSubject.pipe(debounceTime(1000)).subscribe((obj) => {
      if (obj.type) {
        this.router.navigate([], {
          queryParams: {
            [obj.type]: obj.value,
          },
          queryParamsHandling: 'merge',
          replaceUrl: false,
        });
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  injectNewsfeed(result) {
    if (this.auth.featureFlags.showTopNews) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.params.newstype
        ? (this.newstype = this.params.newstype)
        : this.routing.isFolio() || this.routing.isMarket()
          ? (this.newstype = 'Relevant')
          : (this.newstype = 'Chronological');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.params.newstype
        ? (this.newstype = this.params.newstype)
        : (this.newstype = 'Chronological');
    }
    if (this.newstype === 'Relevant') {
      if (!this.params.title) {
        this.titleEntity = false;
      }
      if (!this.params.license) {
        this.licensedContent = false;
      }
    } else if (this.newstype === 'Chronological') {
      if (!this.params.title) {
        this.titleEntity = false;
      }
      if (!this.params.license) {
        this.licensedContent = false;
      }
    } else if (this.newstype === 'Events') {
      if (!this.params.title) {
        this.titleEntity = false;
      }
      if (!this.params.license) {
        this.licensedContent = false;
      }
    } else if (this.newstype === 'Stories') {
      if (!this.params.title) {
        this.titleEntity = false;
      }
      if (!this.params.license) {
        this.licensedContent = false;
      }
    } else {
      if (!this.params.title) {
        this.titleEntity = false;
      }
      if (!this.params.license) {
        this.licensedContent = false;
      }
      if (!this.params.duplicate) {
        this.duplicate = false;
      }
    }

    if (result.from_index == null) {
      this.datacount = undefined;
    } else {
      this.datacount = {
        positiv: result.sentiment_counts.pos,
        neutral: result.sentiment_counts.neu,
        negativ: result.sentiment_counts.neg,
        total: result.news_total_count,
      };
      this.ngxpie.data = [
        {
          name: 'Positive',
          value: this.datacount.positiv,
        },
        {
          name: 'Neutral',
          value: this.datacount.neutral,
        },
        {
          name: 'Negative',
          value: this.datacount.negativ,
        },
      ];
    }
    this.loading = false;
  }

  allNote() {
    this.router.navigate([], {
      queryParams: { allnote: !this.params.allnote ? 'true' : null },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  newNote() {
    this.dialog.open(AnnotationComponent, {
      autoFocus: false,
      // panelClass: 'panelAnnotation',
      data: {
        newone: true,
        entity: this.params.type + ':' + this.params.id,
        newsday: this.params.newsday,
      },
    });
  }

  outNote() {
    this.router.navigate([], {
      queryParams: {
        note: null,
        newsday: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  isActive(event) {
    if (
      !this.params.filter ||
      (event === 'pos' && this.params.filter === 'pos')
    ) {
      return true;
    }
    if (
      !this.params.filter ||
      (event === 'neu' && this.params.filter === 'neu')
    ) {
      return true;
    }
    if (
      !this.params.filter ||
      (event === 'neg' && this.params.filter === 'neg')
    ) {
      return true;
    }
    return false;
  }

  getType(entity) {
    return entity.split(':')[0];
  }

  getId(entity) {
    return entity.split(':')[1];
  }

  getTooltip(tab) {
    if (tab === 'Events') {
      return 'Events excerpts from the articles';
    } else if (tab === 'Stories') {
      return 'Articles grouped in stories';
    } else if (tab === 'Relevant') {
      return 'The most relevant articles';
    } else if (tab === 'Chronological') {
      return 'Articles sorted by latest date and time';
    } else if (tab === 'Bookmarked') {
      return 'Articles that you’ve saved';
    } else if (tab === 'Flagged') {
      return 'Articles flagged for action';
    } else if (tab === 'Dismissed') {
      return 'Dismissed articles';
    }
  }

  linkNote(note) {
    this.router.navigate([], {
      queryParams: {
        note: note.uid,
        type: note.entity.split(':')[0],
        id: note.entity.split(':')[1],
        newsday: note.position,
        news: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  isNoise(value) {
    this.router.navigate([], {
      queryParams: {
        noise: value,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  isLicensedContent(value) {
    this.router.navigate([], {
      queryParams: {
        license: value,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  isPress(value) {
    this.router
      .navigate([], {
        queryParams: {
          project: value ? 'pressrelations' : null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      })
      .then(() => {
        location.reload();
      });
  }

  isDupli() {
    this.router.navigate([], {
      queryParams: {
        duplicate: this.duplicate,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  changeNoSentiments() {
    this.auth.showArticleSentiment = this.showArticleSentiment;
    const newCustomSettings = Object.assign({}, this.auth.cockpitSettings, {
      showArticleSentiment: this.showArticleSentiment,
    });
    this.auth.cockpitSettings = newCustomSettings;
    this.auth.setCustomSettings(newCustomSettings).subscribe();
  }

  isTitleEntity() {
    this.router.navigate([], {
      queryParams: {
        title: this.titleEntity,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  fadeoutSeen() {
    this.router.navigate([], {
      queryParams: {
        fadeout: this.fadeout,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  dimResolved() {
    this.router.navigate([], {
      queryParams: {
        dimmed: this.dimmed,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  onNgModelChange() {}

  changeNews(value) {
    if (
      value !== 'Events' &&
      (this.params.newstype === 'Events' || this.newstype === 'Events')
    ) {
      this.router.navigate([], {
        queryParams: {
          newstype: value,
          eventsFromScanner: null,
          // eventid: null,
          // eventype: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          newstype: value,
          eventsFromScanner:
            this.routing.isScanner() && value === 'Events' ? 'true' : null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  renameTab(name) {
    if (name === 'Chronological') {
      return 'All News';
    } else if (name === 'Relevant') {
      return 'Top News';
    } else {
      return name;
    }
  }

  sliderLabel(value: number): string {
    return `${value}`;
  }

  applyRelevancyFilter(event, type) {
    this.valueSubject.next({ type: type, value: event.value });
  }

  resetRelevancyFilters() {
    this.relevancy = {
      sentence_with_entity_threshold: 0.35,
      entity_span_threshold: 0.9,
      high_entity_article_threshold: 0.0,
      elasticsearch_score_decay: 0.5,
      entity_boost: 100,
    };
    this.router.navigate([], {
      queryParams: {
        sentence_with_entity_threshold: 0.35,
        entity_span_threshold: 0.9,
        high_entity_article_threshold: 0.0,
        elasticsearch_score_decay: 0.5,
        entity_boost: 100,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }
}
