<div class="chat__main">
  <app-chat-navigation
    *ngIf="!(screenSize$ | async)?.isMobile"
  ></app-chat-navigation>

  <div class="chat__conversation">
    <div class="chat__container">
      <div
        class="chat__top-fade"
        [class.theme-light]="params?.theme === 'light'"
      ></div>

      <div class="chat__output">
        <div class="chat__output-inner" id="llmContainer">
          <div *ngIf="chatDetails && chatDetails.length > 0">
            <app-chat-message-container
              [chatDetails]="chatDetails"
              [loading]="loadingAnswer"
              [error]="error"
              (relatedQuestionSubmitted)="submitRelatedQuestions($event)"
              (errorTriggered)="handleError()"
            >
            </app-chat-message-container>
          </div>
          <ng-container *ngIf="chatDetails?.length === 0 && !loading">
            <app-chat-welcome-container
              [userName]="userAccountInfo?.first_name"
              (exampleQuestionSubmitted)="submitRelatedQuestions($event)"
            >
            </app-chat-welcome-container>
          </ng-container>
        </div>
      </div>

      <div class="chat__input" [class.mobile]="(screenSize$ | async)?.isMobile">
        <div
          class="chat__bottom-fade"
          [class.theme-light]="params?.theme === 'light'"
        ></div>
        <form class="chat__input-box" (ngSubmit)="onSubmit()">
          <input
            matInput
            [(ngModel)]="chatInput"
            type="email"
            placeholder="Type a message..."
            name="chat_input"
            class="chat__input-field"
          />
          <button mat-raised-button type="submit" id="chat-question-submit">
            <mat-icon>send</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
