/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ViewEncapsulation,
  OnDestroy,
  Input,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { YukkApi } from '../../../../service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, interval } from 'rxjs';
import { RoutingService } from '../../../../service/routing.service';
import { ConfigService } from '../../../../service/config.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewsViewComponent } from '../news-view/news-view.component';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth.service';
import * as FileSaver from 'file-saver';
import { SafeHtmlPipe } from 'src/app/pipe/safehtml.pipe';
import { HighlightPipe } from 'src/app/pipe/highlight.pipe';
// import * as memoizee from 'memoizee';
import { v1 as uuid } from 'uuid';
import { NewsletterService } from 'src/app/service/newsletter.service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';

// export function memoize() {
//   return function(target, key, descriptor) {
//     const oldFunction = descriptor.value;
//     const newFunction = memoizee(oldFunction);
//     descriptor.value = function () {
//       return newFunction.apply(this, arguments);
//     };
//   };
// }

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss'],
})
export class NewsFeedComponent
  implements OnDestroy, OnChanges, AfterViewChecked
{
  @Input() newsfeedData: any;

  // @ViewChild('recommendationsDialog') recommendationsDialog: ElementRef;

  @ViewChild('newsfeedContainer') newsfeedContainer: ElementRef;
  storyContentHeight = 140;

  infonews: any;
  newsfeed: any = [];
  morenews: any = [];
  storyNewsfeed: any = [];
  storyNewsIds: any = [];
  stars = [];
  loading: boolean;
  scrollnext: string;
  scrollIndex: number;
  params: any;
  intopscrol: boolean;
  chips = [];
  readyscroll: boolean;
  match: string;
  tag: string;
  activenews: string;
  inews: boolean;
  fullscreen: boolean;
  project: string;
  previousValue: any;
  timer: any;
  queryParamsSub: any;
  countnews = 0;
  newsids = [];
  auditedIds = [];
  dismissedIds = [];
  newsletterDismissedIds = [];
  resolvedIds = [];
  auditedCases = [];
  dismissedCases = [];
  loadnews: number;
  duplicate = true;
  titleEntity = false;
  licensedContent = false;
  fadeout = true;
  dimmed = true;
  newnewsid: any;
  frametime: any;
  nodata: boolean;
  arraid: any;
  newstype: string;
  bookmarked: any;
  inMenu = false;
  inButton = false;
  selectedArticles = [];
  messages: any[] = [];
  showRecommendations = false;
  newsfeedCopy: any = [];
  recommendationsApplied = false;
  newsfeedRef: string;
  deduplicatedEventsTitles = new Set();
  filteredEvents = [];
  bookmarkingToolPopup: boolean;

  keywordSearch: UntypedFormControl;

  strippersList = [
    'Private Limited Company\\b',
    'GmbH & Co\\. Beteiligungs KG\\b',
    'mbH & Co\\. KG\\b',
    'GmbH & Co\\. KG\\b',
    'GMBH & CO\\. KG\\b',
    'BV & Co\\. KG\\b',
    'CO LLC\\b',
    'CO\\., LTD\\.',
    'CO\\., INC\\.',
    'PTE\\. LIMITED\\b',
    'PTE\\. LTD\\b',
    'Pte\\. Ltd\\b',
    'Pty Ltd\\b',
    'Group GmbH\\b',
    'Holdings Group\\b',
    'Holding Group\\b',
    'Group\\b',
    'GROUP\\b',
    'Holdings\\b',
    'Holding\\b',
    'HOLDING\\b',
    'Corporation\\b',
    'CORPORATION\\b',
    'CORP\\.',
    'Corp\\.',
    'CORP\\b',
    'Corp\\b',
    'GMBH\\b',
    'GmbH\\b',
    'PLC\\b',
    'Plc\\b',
    'plc\\b',
    'LTD\\.',
    'LTD\\b',
    'Ltd\\.',
    'Ltd\\b',
    'Inc\\.',
    'Inc\\b',
    'S\\.A\\.',
    'SA\\b',
    'AG\\b',
    'LLC\\b',
    'LIMITED\\b',
    'Limited\\b',
    'CO\\.',
    'SRL\\b',
    'PTE\\.',
    'L\\.P\\.',
    'LP\\b',
    'Partners\\b',
    'Pty\\b',
    'B\\.V\\.',
    'INCORPORATED\\b',
    'Incorporated\\b',
    'INC\\.',
    'INC\\b',
    'SIA\\b',
    'L\\.L\\.C\\.',
    'N\\.V\\.',
    'NV\\b',
    'Aktiengesellschaft\\b',
  ];

  screenSize$ = this.breakpointService.screenSize$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public dialog: MatDialog,
    public auth: AuthService,
    public config: ConfigService,
    public newsletterService: NewsletterService,
    private cdr: ChangeDetectorRef,
    private breakpointService: BreakpointService,
  ) {
    this.keywordSearch = new UntypedFormControl('');
    this.keywordSearch.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
        this.loading = true;
        this.auth.keywords = value;
        this.router.navigate([], {
          queryParams: {
            updateNewsfeed: Math.random(),
          },
          queryParamsHandling: 'merge',
        });
      });

    const nref = uuid();
    this.newsfeedRef = nref;

    this.project = this.config.appConfig.routing.project;

    let bookmarking_tool_popup;
    if (this.config.appConfig.setup.useStorage) {
      bookmarking_tool_popup = localStorage.getItem('bookmarking_tool_popup');
    }
    if (bookmarking_tool_popup) {
      this.bookmarkingToolPopup = bookmarking_tool_popup === 'true';
    } else {
      this.bookmarkingToolPopup = true;
    }

    this.queryParamsSub = this.route.queryParams.subscribe((params) => {
      if (!this.routing.reFresh(params, this.previousValue, ['news'])) {
        this.showRecommendations = false;
        this.selectedArticles = [];
      }

      // this.selectedArticles = [];

      if (this.routing.isNewsletter() && this.auth.newsletterParams) {
        this.params = Object.assign({}, this.auth.newsletterParams, {
          duplicate: 'true',
          fadeout: params.fadeout ? params.fadeout : 'true',
          dimmed: 'false',
          noise: params.noise ? params.noise : 'false',
          title: params.title ? params.title : 'false',
          license: params.license ? params.license : 'false',
        });
      } else {
        this.params = params;
      }

      if (this.config.appConfig.setup.useStorage) {
        if (localStorage.getItem('news_curation')) {
          this.stars = localStorage.getItem('news_curation').split(',');
        }
      }

      if (
        params.news &&
        this.routing.reFresh(params, this.previousValue, ['news']) &&
        (!this.auth.newsfeedRef || this.auth.newsfeedRef !== this.newsfeedRef)
      ) {
        this.yukkApi
          .newsviewNew({ id: params.news, params: this.params })
          .subscribe((result) => {
            const resCopy = JSON.parse(JSON.stringify(result));
            this.loadnews = undefined;
            this.activenews = params.news;
            let highlight = null;
            if (
              Object.prototype.hasOwnProperty.call(
                resCopy,
                'query_highlights',
              ) &&
              resCopy.query_highlights.length !== 0
            ) {
              highlight = encodeURIComponent(
                JSON.stringify(
                  resCopy.query_highlights.map((item) => {
                    return {
                      where: item.where,
                      span: item.span,
                    };
                  }),
                ),
              );
            }
            if (this.auth.featureFlags.activityLogging) {
              this.auth
                .userActivity({
                  environment: this.config.appConfig.routing.project,
                  url: this.router.url,
                  activity_type: 'article_open',
                  article_id: params.news,
                })
                .subscribe();
            }
            this.dialog
              .open(NewsViewComponent, {
                autoFocus: false,
                panelClass: 'panelNews',
                disableClose: false,
                closeOnNavigation: true,
                data: {
                  newsinfo: result,
                  params: Object.assign({}, params, { highlight: highlight }),
                  arraid:
                    this.newstype === 'Stories' || this.newstype === 'Relevant'
                      ? this.storyNewsIds
                      : this.arraid,
                  newsfeed:
                    this.newstype === 'Stories' || this.newstype === 'Relevant'
                      ? this.storyNewsfeed
                      : this.newsfeed,
                  currentnews: this.newsfeed.filter((item) => {
                    return item.id === params.news;
                  })[0],
                },
              })
              .afterClosed()
              .subscribe((response) => {
                if (response === 'audited' || response === 'dismissed') {
                  this.newsfeed = this.newsfeed.filter((result2) => {
                    return result2.id !== params.news;
                  });
                  this.arraid = this.newsfeed.filter((result2) => {
                    return result2 !== params.news;
                  });
                  this.newsids = this.newsfeed.filter((result2) => {
                    return result2 !== params.news;
                  });
                  if (response === 'audited') {
                    this.auditedIds.push(params.news);
                  } else if (response === 'dismissed') {
                    this.dismissedIds.push(params.news);
                  }
                }
                if (this.auth.featureFlags.activityLogging) {
                  this.auth
                    .userActivity({
                      environment: this.config.appConfig.routing.project,
                      url: this.router.url,
                      activity_type: 'article_close',
                      article_id: params.news,
                    })
                    .subscribe();
                }
                if (response !== 'changed' && response !== 'resolved') {
                  this.router.navigate([], {
                    queryParams: {
                      news: null,
                      highlight: null,
                      audit: null,
                      dismiss: null,
                      resolve: null,
                      check: null,
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: false,
                  });
                }
                if (response === 'resolved') {
                  this.router.navigate([], {
                    queryParams: {
                      news: null,
                      highlight: null,
                      audit: null,
                      dismiss: null,
                      resolve: null,
                      check: null,
                      update: Math.random(),
                    },
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                  });
                }
              });
          });
      }

      this.licensedContent = params.license === 'true';
      this.titleEntity = params.title === 'true';
      this.duplicate = true;
      if (params.duplicate) {
        this.duplicate = params.duplicate === 'true';
      }

      if (
        (params.id || params.isin || this.routing.isHot()) &&
        this.routing.reFresh(params, this.previousValue, [
          'update',
          'updatePayload',
          'type',
          'id',
          'isin',
          'time',
          'period',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks',
          'panels',
          'tag',
          'participant',
          'newsday',
          'newstype',
          'eventid',
          'event_ids',
          'filter',
          'noise',
          'license',
          'duplicate',
          'title',
          'fadeout',
          'dimmed',
          'newsaudit',
          'newsfeedFromQuery',
          'factuality',
          'temporality',
          'sentence_with_entity_threshold',
          'entity_span_threshold',
          'high_entity_article_threshold',
          'elasticsearch_score_decay',
          'entity_boost',
        ])
      ) {
        if (this.auth.featureFlags.showTopNews) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.params.newstype
            ? (this.newstype = this.params.newstype)
            : this.routing.isFolio() || this.routing.isMarket()
              ? (this.newstype = 'Relevant')
              : (this.newstype = 'Chronological');
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.params.newstype
            ? (this.newstype = this.params.newstype)
            : (this.newstype = 'Chronological');
        }

        if (this.newstype === 'Relevant') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else if (this.newstype === 'Chronological') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else if (this.newstype === 'Events') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else if (this.newstype === 'Stories') {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
        } else {
          if (!this.params.title) {
            this.titleEntity = false;
          }
          if (!this.params.license) {
            this.licensedContent = false;
          }
          if (!this.params.duplicate) {
            this.duplicate = false;
          }
        }
        this.nodata = false;
        this.loading = true;
      }
      this.previousValue = params;
    });
  }

  ngAfterViewChecked() {
    if (this.newsfeedContainer && !this.routing.isScanner()) {
      if (this.routing.isStoryReader()) {
        this.storyContentHeight =
          this.newsfeedContainer.nativeElement.offsetHeight - 172;
      } else {
        this.storyContentHeight =
          this.newsfeedContainer.nativeElement.offsetHeight - 232;
      }
      this.cdr.detectChanges();
    }
  }

  inTerval() {
    if (
      this.routing.isToday(this.params.time) &&
      !this.routing.isHot() &&
      this.newstype === 'Chronological'
    ) {
      const intervalValue = 120000;
      // if (this.routing.isQuery()) {
      //   intervalValue = 120000;
      // }
      this.timer = interval(intervalValue).subscribe(() => {
        this.yukkApi
          .newsfeed({
            returnRecommendations:
              this.routing.isNewsletter() &&
              this.newsletterService &&
              this.newsletterService.newsletter &&
              this.newsletterService.newsletter['contents'].includes(
                'recommended_news',
              ),
            params: this.params,
            custom: {
              // next: 'null',
              size: 10,
              phrase:
                this.routing.isNewsletter() &&
                this.newsletterService &&
                this.newsletterService.newsletter &&
                this.newsletterService.newsletter['contents'].includes(
                  'recommended_news',
                )
                  ? this.newsletterService.newsletter[
                      'recommended_news_phrases'
                    ]
                  : null,
              similarity_threshold:
                this.routing.isNewsletter() &&
                this.newsletterService &&
                this.newsletterService.newsletter &&
                this.newsletterService.newsletter['contents'].includes(
                  'recommended_news',
                )
                  ? this.newsletterService.newsletter[
                      'recommended_news_threshold'
                    ]
                  : null,
              exclude_dismissed: this.routing.isNewsletter(),
              newsletter_id:
                this.routing.isNewsletter() &&
                this.previousValue &&
                this.previousValue.id
                  ? this.previousValue.id
                  : null,
              license: false,
            },
          })
          .subscribe((result) => {
            this.countnews = 0;
            result.news.forEach((news) => {
              if (
                !this.newsids.includes(news.id) &&
                !this.newnewsid.includes(news.id) &&
                !this.auditedIds.includes(news.id) &&
                !this.dismissedIds.includes(news.id) &&
                !this.newsletterDismissedIds.includes(news.id)
              ) {
                if (
                  this.routing.isNewsaudit() &&
                  !(this.newstype === 'Flagged') &&
                  !(this.newstype === 'Dismissed')
                ) {
                  let events = [];
                  let participants = [];

                  if (news.events && news.events.length !== 0) {
                    events = Array.from(
                      new Set(
                        news.events.map((event) => {
                          if (
                            event.participants &&
                            event.participants.length !== 0
                          ) {
                            event.participants.forEach((participant) => {
                              participants.push(participant.name);
                            });
                          }
                          return event.name;
                        }),
                      ),
                    );
                  }

                  participants = Array.from(new Set(participants));

                  const noEntities = ['No entity matches'];

                  let entities = news.matches;

                  if (this.routing.isFolio() || this.routing.isHomepage()) {
                    const portfolioEntities = this.auth.folio.content;
                    entities = entities
                      .filter((item) => {
                        return portfolioEntities.includes(
                          item.entity.compound_key,
                        );
                      })
                      .map((element) => element.entity.name);
                  } else {
                    entities = entities.map((element) => element.entity.name);
                  }

                  if (entities.length === 0) {
                    entities = noEntities;
                  }

                  news['auditEvents'] = events;
                  news['auditEntities'] = entities;
                  news['auditParticipants'] = participants;
                }

                news.inew = true;
                if (!news.title) {
                  news.title = news.content;
                }
                const proportions = { pos: 0, neu: 0, neg: 0 };
                news.matches.forEach((item) => {
                  if (
                    this.params.type + ':' + this.params.id ===
                    item.entity.compound_key
                  ) {
                    news.sentiment = item.sentiment;
                    if (!item.implied && item.spans.length > 0) {
                      proportions[item.sentiment] += 1;
                    }
                  } else if (
                    (this.routing.isFolio() || this.routing.isHomepage()) &&
                    this.auth.folio &&
                    !this.params.portfolioId
                  ) {
                    this.auth.folio.content.forEach((portfolioEntity) => {
                      if (portfolioEntity === item.entity.compound_key) {
                        news.sentiment = item.sentiment;
                        if (!item.implied && item.spans.length > 0) {
                          proportions[item.sentiment] += 1;
                        }
                      }
                    });
                  }
                });
                if (
                  proportions.pos !== 0 ||
                  proportions.neu !== 0 ||
                  proportions.neg !== 0
                ) {
                  const propSum =
                    proportions.pos + proportions.neu + proportions.neg;
                  news.sentiment_proportions = {
                    pos: proportions.pos / propSum,
                    neu: proportions.neu / propSum,
                    neg: proportions.neg / propSum,
                  };
                }
                this.newsfeed.push(news);
                this.newnewsid.push(news.id);
                this.countnews++;
              }
            });
            if (this.countnews > 0) {
              this.frametime = {
                today: true,
                twentyfourhours: true,
                week: true,
                month: true,
                quarter: true,
                year: true,
              };
              this.iSort(this.newsfeed);
              this.newsfeed = this.iDuplicate(this.newsfeed);
              this.newsfeed = this.iTitleEntity(this.newsfeed);
              this.newsfeed = this.iLicensedContent(this.newsfeed);
              this.newsfeed.map((news) => this.inFrame(news));
              this.newsfeed.map((news) => this.formatTitle(news));
              this.arraid = this.newsfeed.map((news) => news.id);
            }
          });
      });
    } else {
      if (this.timer) {
        this.timer.unsubscribe();
      }
    }
  }

  ngOnChanges() {
    if (this.newsfeedData && !this.newsfeedData.reqError) {
      this.duplicate = true;
      if (this.params.duplicate) {
        this.duplicate = this.params.duplicate === 'true';
      }
      if (this.params.title) {
        this.titleEntity = this.params.title === 'true';
      }
      if (this.params.license) {
        this.licensedContent = this.params.license === 'true';
      }
      if (this.params.fadeout) {
        this.fadeout = this.params.fadeout === 'true';
      }
      if (this.params.dimmed) {
        this.dimmed = this.params.dimmed === 'true';
      }
      this.chips = [];
      this.newsfeed = [];
      this.morenews = [];
      this.newnewsid = [];
      this.arraid = [];
      this.loading = true;
      this.scrollnext = 'null';
      this.scrollIndex = 0;
      if (this.auth.featureFlags.showTopNews) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.params.newstype
          ? (this.newstype = this.params.newstype)
          : this.routing.isFolio() || this.routing.isMarket()
            ? (this.newstype = 'Relevant')
            : (this.newstype = 'Chronological');
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.params.newstype
          ? (this.newstype = this.params.newstype)
          : (this.newstype = 'Chronological');
      }
      if (this.newstype === 'Relevant') {
        if (!this.params.title) {
          this.titleEntity = false;
        }
        if (!this.params.license) {
          this.licensedContent = false;
        }
      } else if (this.newstype === 'Chronological') {
        if (!this.params.title) {
          this.titleEntity = false;
        }
        if (!this.params.license) {
          this.licensedContent = false;
        }
      } else if (this.newstype === 'Events') {
        if (!this.params.title) {
          this.titleEntity = false;
        }
        if (!this.params.license) {
          this.licensedContent = false;
        }
      } else if (this.newstype === 'Stories') {
        if (!this.params.title) {
          this.titleEntity = false;
        }
        if (!this.params.license) {
          this.licensedContent = false;
        }
      } else {
        if (!this.params.title) {
          this.titleEntity = false;
        }
        if (!this.params.license) {
          this.licensedContent = false;
        }
        if (!this.params.duplicate) {
          this.duplicate = false;
        }
      }
      this.frametime = {
        today: true,
        twentyfourhours: true,
        week: true,
        month: true,
        quarter: true,
        year: true,
      };
      this.intopscrol = true;
      this.readyscroll = true;
      this.match = 'all';
      this.tag = '';
      this.fullscreen = this.params.fullscreen;
      this.countnews = 0;
      this.nodata = false;
      if (this.params.id) {
        this.match = this.params.id;
      }
      if (this.params.tag) {
        this.tag = this.params.tag;
      }
      this.chips = [];
      if (this.params.tag) {
        const name = this.params.tag.split('|')[1];
        this.chips.push(name);
      }
      this.readyscroll = true;
      // this.ngOnDestroy();
      if (this.timer) {
        this.timer.unsubscribe();
      }

      if (this.newstype === 'Stories' || this.newstype === 'Relevant') {
        this.injectNewsfeed(this.newsfeedData);
      } else {
        this.inNews();
        this.inTerval();
      }

      // if (this.routing.isNewsletter() && this.auth.newsletterParams && this.newsfeedData && (this.params.newstype === 'Bookmarked') && !this.newsfeedData.fromAssistedBookmarking && !this.recommendationsApplied && this.bookmarkingToolPopup) {
      //   this.openRecommendationsDialog(this.recommendationsDialog);
      // }
    } else if (this.newsfeedData && this.newsfeedData.reqError) {
      this.nodata = true;
      this.loading = false;
    } else {
      this.loading = true;
    }
  }

  ngOnDestroy() {
    if (this.timer) {
      this.timer.unsubscribe();
    }
    if (this.queryParamsSub) {
      this.queryParamsSub.unsubscribe();
    }
    this.auth.keywords = '';
  }

  injectNewsfeed(result) {
    if (
      (this.newstype === 'Stories' &&
        Object.prototype.hasOwnProperty.call(result, 'stories')) ||
      (this.newstype === 'Relevant' &&
        Object.prototype.hasOwnProperty.call(result, 'stories'))
    ) {
      this.loading = false;

      let storiesArray = JSON.parse(JSON.stringify(result.stories));

      if (!Array.isArray(storiesArray)) {
        storiesArray = [];
      }

      storiesArray.forEach((story) => {
        if (story.main_article && story.story_size && story.story_size > 0) {
          this.newsfeed.push({
            story: story.main_article.title,
            article: story.main_article,
            news: [],
            size: story.story_size,
            id: story.story_id,
          });
          this.morenews.push(false);
        }
      });

      this.newsfeed.map((story) => this.formatTitle(story.article));

      // this.newsfeed.sort((first, second) => (second.news.length - first.news.length));

      // this.newsfeed = this.newsfeed.map(item => {
      //   return {
      //     story: item.story,
      //     article: item.article,
      //     news: this.iLicensedContent(this.iTitleEntity(this.iDuplicate(item.news))),
      //     size: item.size,
      //     id: item.id
      //   };
      // });

      // if ((this.newsfeed.length === 0) && (this.params.newstype === undefined) && (!this.params.tabsEventsFromScanner || result.tabsEventsFromScanner)) {
      //
      //   this.router.navigate([], {
      //     queryParams: {
      //       newstype: 'Chronological',
      //       changeTab: 'true'
      //     },
      //     queryParamsHandling: 'merge',
      //     replaceUrl: true
      //   });
      //
      // }
    } else if (
      this.routing.isNewsaudit() &&
      !(this.newstype === 'Flagged') &&
      !(this.newstype === 'Dismissed')
    ) {
      forkJoin([
        this.auth.getAuditedIds(),
        this.auth.getDismissedIds(),
      ]).subscribe((results) => {
        let auditedIds = [];
        let dismissedIds = [];
        let allIds = [];

        for (const prop of Object.keys(results[0])) {
          auditedIds = auditedIds.concat(results[0][prop]);
        }

        for (const prop of Object.keys(results[1])) {
          dismissedIds = dismissedIds.concat(results[1][prop]);
        }

        this.auditedIds = auditedIds;
        this.dismissedIds = dismissedIds;

        allIds = Array.from(new Set(auditedIds.concat(dismissedIds)));

        if (result.news.length > 0) {
          result.news.map((news) => {
            let events = [];
            let participants = [];

            if (news.events && news.events.length !== 0) {
              events = Array.from(
                new Set(
                  news.events.map((event) => {
                    if (event.participants && event.participants.length !== 0) {
                      event.participants.forEach((participant) => {
                        participants.push(participant.name);
                      });
                    }
                    return event.name;
                  }),
                ),
              );
            }

            participants = Array.from(new Set(participants));

            const noEntities = ['No entity matches'];

            let entities = news.matches;

            if (this.routing.isFolio()) {
              const portfolioEntities = this.auth.folio
                ? this.auth.folio.content
                : [];
              entities = entities
                .filter((item) => {
                  return portfolioEntities.includes(item.entity.compound_key);
                })
                .map((element) => element.entity.name);
            } else {
              entities = entities.map((element) => element.entity.name);
            }

            if (entities.length === 0) {
              entities = noEntities;
            }

            news['auditEvents'] = events;
            news['auditEntities'] = entities;
            news['auditParticipants'] = participants;

            // const auditedArticles = ( this.params.newstype === 'Flagged' ) && auditedIds.includes(news.id);
            // const dismissedArticles = ( this.params.newstype === 'Dismissed' ) && dismissedIds.includes(news.id);
            const otherArticles =
              this.params.newstype !== 'Flagged' &&
              this.params.newstype !== 'Dismissed' &&
              !allIds.includes(news.id);

            if (otherArticles) {
              this.newsids.push(news.id);
              if (!news.title) {
                news.title = news.content;
              }
              const proportions = { pos: 0, neu: 0, neg: 0 };
              news.matches.forEach((item) => {
                if (
                  this.params.type + ':' + this.params.id ===
                  item.entity.compound_key
                ) {
                  news.sentiment = item.sentiment;
                  if (!item.implied && item.spans.length > 0) {
                    proportions[item.sentiment] += 1;
                  }
                } else if (
                  this.routing.isFolio() &&
                  this.auth.folio &&
                  !this.params.portfolioId
                ) {
                  this.auth.folio.content.forEach((portfolioEntity) => {
                    if (portfolioEntity === item.entity.compound_key) {
                      news.sentiment = item.sentiment;
                      if (!item.implied && item.spans.length > 0) {
                        proportions[item.sentiment] += 1;
                      }
                    }
                  });
                }
              });
              if (
                proportions.pos !== 0 ||
                proportions.neu !== 0 ||
                proportions.neg !== 0
              ) {
                const propSum =
                  proportions.pos + proportions.neu + proportions.neg;
                news.sentiment_proportions = {
                  pos: proportions.pos / propSum,
                  neu: proportions.neu / propSum,
                  neg: proportions.neg / propSum,
                };
              }
              this.newsfeed.push(news);
            }
          });
          this.frametime = {
            today: true,
            twentyfourhours: true,
            week: true,
            month: true,
            quarter: true,
            year: true,
          };
          this.iSort(this.newsfeed);
          this.newsfeed = this.iDuplicate(this.newsfeed);
          this.newsfeed = this.iTitleEntity(this.newsfeed);
          this.newsfeed = this.iLicensedContent(this.newsfeed);
          this.newsfeed.map((news) => this.inFrame(news));
          this.newsfeed.map((news) => this.formatTitle(news));
          this.arraid = this.newsfeed.map((news) => news.id);
        }

        if (this.duplicate) {
          this.scrollnext = 'null';
          this.scrollIndex += 50;
          this.readyscroll = this.scrollIndex !== 0;
        } else {
          // this.scrollnext = result.news_after;
          // this.readyscroll = this.scrollnext !== 'null';
          this.scrollnext = 'null';
          this.scrollIndex += 50;
          this.readyscroll = this.scrollIndex !== 0;
        }
        this.loading = false;

        if (this.newstype === 'Relevant' || this.newstype === 'Chronological') {
          this.scrollIndex = 0;
          this.scrollnext = result.from_index;
          this.readyscroll = this.scrollnext !== 'null';
          this.loading = false;
        }
      });
    } else if (this.routing.isNewsaudit() && this.newstype === 'Flagged') {
      this.auth.getAuditedCases({ num_items: 1000000 }).subscribe((result2) => {
        if (result.news.length > 0) {
          result.news.map((news) => {
            const auditInfoFull = result2.hits.filter((item) => {
              return item.article_id === news.id;
            });
            if (auditInfoFull.length > 0) {
              const auditInfo = auditInfoFull[0];
              news['resolved'] =
                auditInfo.resolve_timestamp !== null ? 'Resolved' : 'Opened';
              news['timestamp'] =
                auditInfo.resolve_timestamp !== null
                  ? auditInfo.resolve_timestamp
                  : auditInfo.open_timestamp;
              news['subjects'] = auditInfoFull.map((element) => {
                return { subject: element.subject, id: element.subject_id };
              });
              news['auditInfo'] = auditInfo;
            }
            this.newsids.push(news.id);
            if (!news.title) {
              news.title = news.content;
            }
            const proportions = { pos: 0, neu: 0, neg: 0 };
            news.matches.forEach((item) => {
              if (
                this.params.type + ':' + this.params.id ===
                item.entity.compound_key
              ) {
                news.sentiment = item.sentiment;
                if (!item.implied && item.spans.length > 0) {
                  proportions[item.sentiment] += 1;
                }
              } else if (
                this.routing.isFolio() &&
                this.auth.folio &&
                !this.params.portfolioId
              ) {
                this.auth.folio.content.forEach((portfolioEntity) => {
                  if (portfolioEntity === item.entity.compound_key) {
                    news.sentiment = item.sentiment;
                    if (!item.implied && item.spans.length > 0) {
                      proportions[item.sentiment] += 1;
                    }
                  }
                });
              }
            });
            if (
              proportions.pos !== 0 ||
              proportions.neu !== 0 ||
              proportions.neg !== 0
            ) {
              const propSum =
                proportions.pos + proportions.neu + proportions.neg;
              news.sentiment_proportions = {
                pos: proportions.pos / propSum,
                neu: proportions.neu / propSum,
                neg: proportions.neg / propSum,
              };
            }
            this.newsfeed.push(news);
          });
          this.frametime = {
            today: true,
            twentyfourhours: true,
            week: true,
            month: true,
            quarter: true,
            year: true,
          };
          this.iSort(this.newsfeed);
          this.newsfeed = this.iDuplicate(this.newsfeed);
          this.newsfeed = this.iTitleEntity(this.newsfeed);
          this.newsfeed = this.iLicensedContent(this.newsfeed);
          this.newsfeed.map((news) => this.inFrame(news));
          this.newsfeed.map((news) => this.formatTitle(news));
          this.arraid = this.newsfeed.map((news) => news.id);
        } else if (
          this.newsfeed.length === 0 &&
          this.params.newstype === undefined
        ) {
          if (this.auth.featureFlags.showTopNews) {
            this.router.navigate([], {
              queryParams: {
                newstype:
                  this.routing.isFolio() || this.routing.isMarket()
                    ? 'Relevant'
                    : 'Chronological',
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          } else {
            this.router.navigate([], {
              queryParams: { newstype: 'Chronological' },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          }
        }

        if (this.duplicate) {
          this.scrollnext = 'null';
          this.scrollIndex += 50;
          this.readyscroll = this.scrollIndex !== 0;
        } else {
          // this.scrollnext = result.news_after;
          // this.readyscroll = this.scrollnext !== 'null';
          this.scrollnext = 'null';
          this.scrollIndex += 50;
          this.readyscroll = this.scrollIndex !== 0;
        }
        this.loading = false;
      });
    } else if (this.routing.isNewsaudit() && this.newstype === 'Dismissed') {
      this.auth
        .getDismissedCases({ num_items: 1000000 })
        .subscribe((result2) => {
          if (result.news.length > 0) {
            result.news.map((news) => {
              const auditInfoFull = result2.hits.filter((item) => {
                return item.article_id === news.id;
              });
              if (auditInfoFull.length > 0) {
                const auditInfo = auditInfoFull[0];
                news['timestamp'] = auditInfo.timestamp;
                news['subjects'] = auditInfoFull.map((element) => {
                  return { subject: element.subject, id: element.subject_id };
                });
                news['auditInfo'] = auditInfo;
              }
              this.newsids.push(news.id);
              if (!news.title) {
                news.title = news.content;
              }
              const proportions = { pos: 0, neu: 0, neg: 0 };
              news.matches.forEach((item) => {
                if (
                  this.params.type + ':' + this.params.id ===
                  item.entity.compound_key
                ) {
                  news.sentiment = item.sentiment;
                  if (!item.implied && item.spans.length > 0) {
                    proportions[item.sentiment] += 1;
                  }
                } else if (
                  this.routing.isFolio() &&
                  this.auth.folio &&
                  !this.params.portfolioId
                ) {
                  this.auth.folio.content.forEach((portfolioEntity) => {
                    if (portfolioEntity === item.entity.compound_key) {
                      news.sentiment = item.sentiment;
                      if (!item.implied && item.spans.length > 0) {
                        proportions[item.sentiment] += 1;
                      }
                    }
                  });
                }
              });
              if (
                proportions.pos !== 0 ||
                proportions.neu !== 0 ||
                proportions.neg !== 0
              ) {
                const propSum =
                  proportions.pos + proportions.neu + proportions.neg;
                news.sentiment_proportions = {
                  pos: proportions.pos / propSum,
                  neu: proportions.neu / propSum,
                  neg: proportions.neg / propSum,
                };
              }
              this.newsfeed.push(news);
            });
            this.frametime = {
              today: true,
              twentyfourhours: true,
              week: true,
              month: true,
              quarter: true,
              year: true,
            };
            this.iSort(this.newsfeed);
            this.newsfeed = this.iDuplicate(this.newsfeed);
            this.newsfeed = this.iTitleEntity(this.newsfeed);
            this.newsfeed = this.iLicensedContent(this.newsfeed);
            this.newsfeed.map((news) => this.inFrame(news));
            this.newsfeed.map((news) => this.formatTitle(news));
            this.arraid = this.newsfeed.map((news) => news.id);
          } else if (
            this.newsfeed.length === 0 &&
            this.params.newstype === undefined
          ) {
            if (this.auth.featureFlags.showTopNews) {
              this.router.navigate([], {
                queryParams: {
                  newstype:
                    this.routing.isFolio() || this.routing.isMarket()
                      ? 'Relevant'
                      : 'Chronological',
                },
                queryParamsHandling: 'merge',
                replaceUrl: true,
              });
            } else {
              this.router.navigate([], {
                queryParams: { newstype: 'Chronological' },
                queryParamsHandling: 'merge',
                replaceUrl: true,
              });
            }
          }

          if (this.duplicate) {
            this.scrollnext = 'null';
            this.scrollIndex += 50;
            this.readyscroll = this.scrollIndex !== 0;
          } else {
            // this.scrollnext = result.news_after;
            // this.readyscroll = this.scrollnext !== 'null';
            this.scrollnext = 'null';
            this.scrollIndex += 50;
            this.readyscroll = this.scrollIndex !== 0;
          }
          this.loading = false;
        });
    } else if (this.routing.isNewsletter()) {
      if (result.news.length > 0) {
        result.news.map((news) => {
          this.newsids.push(news.id);
          if (!news.title) {
            news.title = news.content;
          }
          const proportions = { pos: 0, neu: 0, neg: 0 };
          news.matches.forEach((item) => {
            if (
              this.params.type + ':' + this.params.id ===
              item.entity.compound_key
            ) {
              news.sentiment = item.sentiment;
              if (!item.implied && item.spans.length > 0) {
                proportions[item.sentiment] += 1;
              }
            } else if (
              this.routing.isFolio() &&
              this.auth.folio &&
              !this.params.portfolioId
            ) {
              this.auth.folio.content.forEach((portfolioEntity) => {
                if (portfolioEntity === item.entity.compound_key) {
                  news.sentiment = item.sentiment;
                  if (!item.implied && item.spans.length > 0) {
                    proportions[item.sentiment] += 1;
                  }
                }
              });
            }
          });
          if (
            proportions.pos !== 0 ||
            proportions.neu !== 0 ||
            proportions.neg !== 0
          ) {
            const propSum = proportions.pos + proportions.neu + proportions.neg;
            news.sentiment_proportions = {
              pos: proportions.pos / propSum,
              neu: proportions.neu / propSum,
              neg: proportions.neg / propSum,
            };
          }
          this.newsfeed.push(news);
        });
        this.frametime = {
          today: true,
          twentyfourhours: true,
          week: true,
          month: true,
          quarter: true,
          year: true,
        };
        if (!this.newsfeedData.fromAssistedBookmarking) {
          this.iSort(this.newsfeed);
        }
        this.newsfeed = this.iDuplicate(this.newsfeed);
        this.newsfeed = this.iTitleEntity(this.newsfeed);
        this.newsfeed = this.iLicensedContent(this.newsfeed);
        this.newsfeed.map((news) => this.inFrame(news));
        this.newsfeed.map((news) => this.formatTitle(news));
        this.arraid = this.newsfeed.map((news) => news.id);
      }

      if (this.duplicate) {
        this.scrollnext = 'null';
        this.scrollIndex += 50;
        this.readyscroll = this.scrollIndex !== 0;
      } else {
        // this.scrollnext = result.news_after;
        // this.readyscroll = this.scrollnext !== 'null';
        this.scrollnext = 'null';
        this.scrollIndex += 50;
        this.readyscroll = this.scrollIndex !== 0;
      }
      this.loading = false;

      // this.auth.getNewsletterDismissed().subscribe(res => {
      //
      //   let dismissedIds = [];
      //
      //   for (const prop of Object.keys(res)) {
      //     dismissedIds = dismissedIds.concat(prop);
      //   }
      //
      //   this.newsletterDismissedIds = dismissedIds;
      //
      //   if (result.news.length > 0) {
      //     result.news.map(news => {
      //
      //       const dismissedArticle = dismissedIds.includes(news.id);
      //
      //       if (!dismissedArticle) {
      //         this.newsids.push(news.id);
      //         if (!news.title) { news.title = news.content; }
      //         const proportions = {pos: 0, neu: 0, neg: 0};
      //         news.matches.forEach(item => {
      //           if (this.params.type + ':' + this.params.id === item.entity.compound_key) {
      //             news.sentiment = item.sentiment;
      //             if (!item.implied && (item.spans.length > 0)) {
      //               proportions[item.sentiment] += 1;
      //             }
      //           } else if (this.routing.isFolio() && this.auth.folio && !this.params.portfolioId) {
      //             this.auth.folio.content.forEach(portfolioEntity => {
      //               if (portfolioEntity === item.entity.compound_key) {
      //                 news.sentiment = item.sentiment;
      //                 if (!item.implied && (item.spans.length > 0)) {
      //                   proportions[item.sentiment] += 1;
      //                 }
      //               }
      //             });
      //           }
      //         });
      //         if ((proportions.pos !== 0) || (proportions.neu !== 0) || (proportions.neg !== 0)) {
      //           const propSum = proportions.pos + proportions.neu + proportions.neg;
      //           news.sentiment_proportions = {
      //             pos: (proportions.pos / propSum),
      //             neu: (proportions.neu / propSum),
      //             neg: (proportions.neg / propSum)
      //           };
      //         }
      //         this.newsfeed.push(news);
      //       }
      //
      //     });
      //     this.frametime = {
      //       today: true,
      //       twentyfourhours: true,
      //       week: true,
      //       month: true,
      //       quarter: true,
      //       year: true,
      //     };
      //     if (!this.newsfeedData.fromAssistedBookmarking) {
      //       this.newsfeed.sort((a, b) => new Date(b.publish_time).valueOf() - new Date(a.publish_time).valueOf());
      //     }
      //     this.newsfeed = this.iDuplicate(this.newsfeed);
      //     this.newsfeed = this.iTitleEntity(this.newsfeed);
      //     this.newsfeed = this.iLicensedContent(this.newsfeed);
      //     this.newsfeed.map(news => this.inFrame(news));
      //     this.newsfeed.map(news => this.formatTitle(news));
      //     this.arraid = this.newsfeed.map(news => news.id);
      //   }
      //
      //   if (this.duplicate) {
      //     this.scrollnext = 'null';
      //     this.scrollIndex += 50;
      //     this.readyscroll = this.scrollIndex !== 0;
      //   } else {
      //     this.scrollnext = result.news_after;
      //     this.readyscroll = this.scrollnext !== 'null';
      //   }
      //   this.loading = false;
      //
      // });
    } else if (result.news) {
      if (result.news.length > 0) {
        result.news.map((news) => {
          if (this.routing.isFolio() || this.routing.isHomepage()) {
            let events = [];
            if (news.events && news.events.length !== 0) {
              events = Array.from(
                new Set(news.events.map((event) => event.name)),
              );
            }
            const noEntities = ['No entity matches'];
            let entities = news.matches;
            const portfolioEntities = this.auth.folio
              ? this.auth.folio.content
              : [];
            entities = entities
              .filter((item) => {
                return portfolioEntities.includes(item.entity.compound_key);
              })
              .map((element) => element.entity.name);
            if (entities.length === 0) {
              entities = noEntities;
            }
            news['auditEvents'] = events;
            news['auditEntities'] = entities;
          }

          this.newsids.push(news.id);
          if (!news.title) {
            news.title = news.content;
          }
          const proportions = { pos: 0, neu: 0, neg: 0 };
          news.matches.forEach((item) => {
            if (
              this.params.type + ':' + this.params.id ===
              item.entity.compound_key
            ) {
              news.sentiment = item.sentiment;
              if (!item.implied && item.spans.length > 0) {
                proportions[item.sentiment] += 1;
              }
            } else if (
              (this.routing.isFolio() || this.routing.isHomepage()) &&
              this.auth.folio &&
              !this.params.portfolioId
            ) {
              this.auth.folio.content.forEach((portfolioEntity) => {
                if (portfolioEntity === item.entity.compound_key) {
                  news.sentiment = item.sentiment;
                  if (!item.implied && item.spans && item.spans.length > 0) {
                    proportions[item.sentiment] += 1;
                  }
                }
              });
            }
          });
          if (
            proportions.pos !== 0 ||
            proportions.neu !== 0 ||
            proportions.neg !== 0
          ) {
            const propSum = proportions.pos + proportions.neu + proportions.neg;
            news.sentiment_proportions = {
              pos: proportions.pos / propSum,
              neu: proportions.neu / propSum,
              neg: proportions.neg / propSum,
            };
          }
          this.newsfeed.push(news);
        });
        this.frametime = {
          today: true,
          twentyfourhours: true,
          week: true,
          month: true,
          quarter: true,
          year: true,
        };
        this.iSort(this.newsfeed);
        this.newsfeed = this.iDuplicate(this.newsfeed);
        this.newsfeed = this.iTitleEntity(this.newsfeed);
        this.newsfeed = this.iLicensedContent(this.newsfeed);
        this.newsfeed.map((news) => this.inFrame(news));
        this.newsfeed.map((news) => this.formatTitle(news));
        this.arraid = this.newsfeed.map((news) => news.id);
      } else if (
        this.newsfeed.length === 0 &&
        this.newstype === 'Chronological'
      ) {
        // if ((this.params.title === undefined) && (this.titleEntity)) {
        //   this.titleEntity = false;
        //   this.router.navigate([], {
        //     queryParams: {
        //       title: this.titleEntity
        //     },
        //     queryParamsHandling: 'merge',
        //     replaceUrl: true
        //   });
        // } else if ((this.params.license === undefined) && (this.licensedContent)) {
        //   this.licensedContent = false;
        //   this.router.navigate([], {
        //     queryParams: {
        //       license: this.licensedContent
        //     },
        //     queryParamsHandling: 'merge',
        //     replaceUrl: true
        //   });
        // }
      }

      if (this.duplicate) {
        this.scrollnext = 'null';
        if (this.newstype !== 'Events') {
          this.scrollIndex += 50;
        } else {
          this.scrollIndex += 1000;
        }
        this.readyscroll = this.scrollIndex !== 0;
      } else {
        if (this.newstype === 'Events') {
          this.scrollnext = 'null';
          this.scrollIndex += 1000;
          this.readyscroll = this.scrollIndex !== 0;
        } else {
          // this.scrollnext = result.news_after;
          // this.readyscroll = this.scrollnext !== 'null';
          this.scrollnext = 'null';
          this.scrollIndex += 50;
          this.readyscroll = this.scrollIndex !== 0;
        }
      }
      this.loading = false;
    }

    if (this.newstype === 'Relevant' || this.newstype === 'Chronological') {
      this.scrollIndex = 0;
      this.scrollnext = result.from_index;
      this.readyscroll = this.scrollnext !== 'null';
      this.loading = false;
    }

    // this.scrollnext = result.news_after;
    // this.readyscroll = this.scrollnext !== 'null';
    // this.loading = false;
  }

  inNews() {
    if (
      this.readyscroll &&
      (this.scrollnext != null || this.scrollIndex !== 0)
    ) {
      if (this.newsfeed.length) {
        this.readyscroll = false;
      }

      if (
        this.newsfeedData &&
        this.scrollnext === 'null' &&
        this.scrollIndex === 0
      ) {
        this.injectNewsfeed(this.newsfeedData);
      } else if (this.scrollnext !== 'null' || this.scrollIndex !== 0) {
        this.yukkApi
          .newsfeed({
            returnEvents: this.newstype === 'Events',
            returnRelevantNews: this.newstype === 'Relevant',
            params: this.params,
            returnRecommendations:
              this.routing.isNewsletter() &&
              this.newsletterService &&
              this.newsletterService.newsletter &&
              this.newsletterService.newsletter['contents'].includes(
                'recommended_news',
              ),
            custom: {
              // next: this.scrollnext,
              from_index:
                this.newstype === 'Relevant' ||
                this.newstype === 'Chronological'
                  ? this.scrollnext
                  : this.scrollIndex,
              size: this.newstype !== 'Events' ? 50 : 1000,
              starred_only: this.newstype === 'Bookmarked',
              audited_only: this.newstype === 'Flagged',
              dismissed_only: this.newstype === 'Dismissed',
              exclude_dismissed: this.routing.isNewsletter(),
              newsletter_id:
                this.routing.isNewsletter() &&
                this.previousValue &&
                this.previousValue.id
                  ? this.previousValue.id
                  : null,
              top_news_score_threshold: false,
              license: false,
              title: false,
              phrase:
                this.routing.isNewsletter() &&
                this.newsletterService &&
                this.newsletterService.newsletter &&
                this.newsletterService.newsletter['contents'].includes(
                  'recommended_news',
                )
                  ? this.newsletterService.newsletter[
                      'recommended_news_phrases'
                    ]
                  : null,
              similarity_threshold:
                this.routing.isNewsletter() &&
                this.newsletterService &&
                this.newsletterService.newsletter &&
                this.newsletterService.newsletter['contents'].includes(
                  'recommended_news',
                )
                  ? this.newsletterService.newsletter[
                      'recommended_news_threshold'
                    ]
                  : null,
            },
          })
          .subscribe(
            (result) => {
              this.injectNewsfeed(result);
            },
            () => {
              this.nodata = true;
              this.loading = false;
            },
          );
      } else {
        this.nodata = true;
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  inScrolled() {
    if (this.newstype !== 'Stories') {
      this.inNews();
    }
  }
  inScrolling(event): void {
    if (event.target.scrollTop === 0) {
      this.intopscrol = true;
    } else {
      this.intopscrol = false;
    }
  }

  goNews(newsArg, type) {
    let news;
    if (type === 'feedstories') {
      news = newsArg.news;
      this.storyNewsfeed = newsArg.story.news;
      this.storyNewsIds = newsArg.story.news.map((item) => {
        return item.id;
      });
    } else if (type === 'feedstories2') {
      news = newsArg.news;
      this.storyNewsfeed = [newsArg.story.article];
      this.storyNewsIds = [newsArg.story.article.id];
    } else {
      news = newsArg;
    }
    this.loadnews = news.id;
    if (this.activenews === news.id) {
      this.activenews = undefined;
    } else {
      this.activenews = news.id;
    }
    // let highlight = null;
    // if ( news.hasOwnProperty('query_highlights') && (news.query_highlights.length !== 0) ) {
    //   highlight = encodeURIComponent(JSON.stringify(news.query_highlights));
    // }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        news: news.id,
        // highlight: highlight,
        audit: this.routing.isNewsaudit() ? type === 'audit' : null,
        dismiss: this.routing.isNewsaudit() ? type === 'dismiss' : null,
        resolve: this.routing.isNewsaudit() ? type === 'resolve' : null,
        check: this.routing.isNewsaudit() ? type === 'check' : null,
        note: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
      skipLocationChange: this.auth.featureFlags.showNavigation ? true : false,
    });
  }

  dismissNews(item) {
    let check = false;
    const filteredArticles = this.selectedArticles.filter((news) => {
      if (news.id === item.id) {
        check = true;
      }
      return news.id !== item.id;
    });
    if (check) {
      this.selectedArticles = filteredArticles;
    } else {
      this.selectedArticles.push(item);
    }
  }

  dismissManyNews() {
    if (this.selectedArticles.length !== 0) {
      const newsletterId =
        this.routing.isNewsletter() &&
        this.previousValue &&
        this.previousValue.id
          ? this.previousValue.id
          : null;
      if (
        window.confirm(
          'Do you want to delete all selected articles from the newsletter?',
        )
      ) {
        const newsIds = this.selectedArticles.map((news) => news.id);
        this.auth.newsletterDismissNews(newsIds, newsletterId).subscribe(() => {
          this.selectedArticles.forEach((news) => {
            this.newsfeed = this.newsfeed.filter((result) => {
              return result.id !== news.id;
            });
            this.arraid = this.newsfeed.filter((result) => {
              return result !== news.id;
            });
            this.newsids = this.newsfeed.filter((result) => {
              return result !== news.id;
            });
            this.newsletterDismissedIds.push(news.id);
          });
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
            },
            queryParamsHandling: 'merge',
            replaceUrl: true,
          });
        });
      }
    }
  }

  removeChip() {
    this.router.navigate([], {
      queryParams: { tag: null, newsday: null, note: null },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  getTeaser(news) {
    if (this.params.newstype === 'Events' && this.params.eventid) {
      let title = '';
      const eventSpans = news.events.filter((item) => {
        return item.id === this.params.eventid;
      });
      const firstSpan = eventSpans[0];
      if (firstSpan && firstSpan.where === 'title') {
        title =
          news && news.title
            ? news.title.substring(firstSpan.span.start, firstSpan.span.end)
            : '';
      } else if (firstSpan && firstSpan.where === 'content') {
        title =
          news && news.content
            ? news.content.substring(firstSpan.span.start, firstSpan.span.end)
            : '';
      }
      return title;
    } else if (news.teaser) {
      return news.teaser;
    }
  }

  // @memoize()
  // getTitle(news) {
  //   if (news) {
  //     if (news.reduce_visibility) {
  //       let title = '';
  //       news.matches.map(data => {
  //         title += data.entity.alpha_id + ' ';
  //       });
  //       return title;
  //     } else if ((this.params.newstype === 'Events') && (this.params.eventid)) {
  //       let title = '';
  //       const eventSpans = news.events.filter(item => {
  //         return item.id === this.params.eventid;
  //       });
  //       const firstSpan = eventSpans[0];
  //       if (firstSpan && (firstSpan.where === 'title')) {
  //         title = (news && news.title) ? news.title.substring(firstSpan.span.start, firstSpan.span.end) : '';
  //         const newsCopy = JSON.parse(JSON.stringify(news));
  //         const safeHtmlPipe = new SafeHtmlPipe();
  //         const highlightPipe = new HighlightPipe();
  //         title = safeHtmlPipe.transform(title);
  //         const joinedSpans = {
  //           spans: []
  //         };
  //         newsCopy.matches.forEach(item => {
  //           const titleSpans = item.spans.filter(span => {
  //             return (span.location === 'title');
  //           }).map(span => {
  //             return Object.assign({}, span, {sentiment: 'title'});
  //           });
  //           let tagactive;
  //           if (this.params.tag) {
  //             tagactive = decodeURI(this.params.tag.split('|')[0]).split(':');
  //           }
  //           if (this.params.tag && (tagactive[1] + ':' + tagactive[2] === item.entity.compound_key)) {
  //             if (!item.implied && titleSpans && (titleSpans.length > 0)) {
  //               joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
  //             }
  //           } else if (this.params.participant === item.entity.compound_key) {
  //             if (!item.implied && titleSpans && (titleSpans.length > 0)) {
  //               joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
  //             }
  //           } else if (this.params.type + ':' + this.params.id === item.entity.compound_key) {
  //             if (!item.implied && titleSpans && (titleSpans.length > 0)) {
  //               joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
  //             }
  //           } else if ((this.routing.isFolio() || this.routing.isHomepage()) && this.auth.folio && !this.params.portfolioId) {
  //             this.auth.folio.content.forEach(portfolioEntity => {
  //               if (portfolioEntity === item.entity.compound_key) {
  //                 if (!item.implied && titleSpans && (titleSpans.length > 0)) {
  //                   joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
  //                 }
  //               }
  //             });
  //           }
  //         });
  //         title = highlightPipe.transform(title, 'title', joinedSpans);
  //       } else if (firstSpan && (firstSpan.where === 'content')) {
  //         title = (news && news.content) ? news.content.substring(firstSpan.span.start, firstSpan.span.end) : '';
  //         const newsCopy = JSON.parse(JSON.stringify(news));
  //         const safeHtmlPipe = new SafeHtmlPipe();
  //         const highlightPipe = new HighlightPipe();
  //         title = safeHtmlPipe.transform(title);
  //         const joinedSpans = {
  //           spans: []
  //         };
  //         newsCopy.matches.forEach(item => {
  //           const contentSpans = item.spans.filter(span => {
  //             return ((span.location === 'content') && (span.start >= firstSpan.span.start) && (span.end <= firstSpan.span.end));
  //           }).map(span => {
  //             return Object.assign({}, span, {
  //               sentiment: 'title',
  //               start: (span.start - firstSpan.span.start),
  //               end: (span.end - firstSpan.span.start)
  //             });
  //           });
  //           let tagactive;
  //           if (this.params.tag) {
  //             tagactive = decodeURI(this.params.tag.split('|')[0]).split(':');
  //           }
  //           if (this.params.tag && (tagactive[1] + ':' + tagactive[2] === item.entity.compound_key)) {
  //             if (!item.implied && contentSpans && (contentSpans.length > 0)) {
  //               joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
  //             }
  //           } else if (this.params.participant === item.entity.compound_key) {
  //             if (!item.implied && contentSpans && (contentSpans.length > 0)) {
  //               joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
  //             }
  //           } else if (this.params.type + ':' + this.params.id === item.entity.compound_key) {
  //             if (!item.implied && contentSpans && (contentSpans.length > 0)) {
  //               joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
  //             }
  //           } else if ((this.routing.isFolio() || this.routing.isHomepage()) && this.auth.folio && !this.params.portfolioId) {
  //             this.auth.folio.content.forEach(portfolioEntity => {
  //               if (portfolioEntity === item.entity.compound_key) {
  //                 if (!item.implied && contentSpans && (contentSpans.length > 0)) {
  //                   joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
  //                 }
  //               }
  //             });
  //           }
  //         });
  //         title = highlightPipe.transform(title, 'content', joinedSpans);
  //       }
  //       // if (this.newstype !== 'Events') {
  //       //   if (!title) {
  //       //     title = 'Click to see text - event span unavailable due to reprocessing';
  //       //     news.hidden = true;
  //       //     this.cdr.detectChanges();
  //       //   } else if (this.duplicate) {
  //       //     if (!this.deduplicatedEventsTitles.has(title)) {
  //       //       this.deduplicatedEventsTitles.add(title);
  //       //       this.filteredEvents.push(news.id);
  //       //     }
  //       //     if (this.deduplicatedEventsTitles.has(title) && !this.filteredEvents.includes(news.id)) {
  //       //       news.hidden = true;
  //       //       this.cdr.detectChanges();
  //       //     }
  //       //   }
  //       // }
  //       if (!title) {
  //         title = 'Click to see text - event span unavailable due to reprocessing';
  //         news.hidden = true;
  //         this.cdr.detectChanges();
  //       } else if (this.duplicate) {
  //         if (!this.deduplicatedEventsTitles.has(title)) {
  //           this.deduplicatedEventsTitles.add(title);
  //           this.filteredEvents.push(news.id);
  //         }
  //         if (this.deduplicatedEventsTitles.has(title) && !this.filteredEvents.includes(news.id)) {
  //           news.hidden = true;
  //           this.cdr.detectChanges();
  //         }
  //       }
  //       return title;
  //     } else {
  //       const newsCopy = JSON.parse(JSON.stringify(news));
  //       let title = newsCopy.title;
  //       const safeHtmlPipe = new SafeHtmlPipe();
  //       const highlightPipe = new HighlightPipe();
  //       title = safeHtmlPipe.transform(title);
  //       const joinedSpans = {
  //         spans: []
  //       };
  //       newsCopy.matches.forEach(item => {
  //         item.spans.forEach(span => {
  //           span.sentiment = 'title';
  //         });
  //         let tagactive;
  //         if (this.params.tag) {
  //           tagactive = decodeURI(this.params.tag.split('|')[0]).split(':');
  //         }
  //         if (this.params.tag && (tagactive[1] + ':' + tagactive[2] === item.entity.compound_key)) {
  //           if (!item.implied && item.spans && (item.spans.length > 0)) {
  //             joinedSpans.spans = joinedSpans.spans.concat(item.spans);
  //           }
  //         } else if (this.params.participant === item.entity.compound_key) {
  //           if (!item.implied && item.spans && (item.spans.length > 0)) {
  //             joinedSpans.spans = joinedSpans.spans.concat(item.spans);
  //           }
  //         } else if (this.params.type + ':' + this.params.id === item.entity.compound_key) {
  //           if (!item.implied && item.spans && (item.spans.length > 0)) {
  //             joinedSpans.spans = joinedSpans.spans.concat(item.spans);
  //           }
  //         } else if ((this.routing.isFolio() || this.routing.isHomepage()) && this.auth.folio && !this.params.portfolioId) {
  //           this.auth.folio.content.forEach(portfolioEntity => {
  //             if (portfolioEntity === item.entity.compound_key) {
  //               if (!item.implied && item.spans && (item.spans.length > 0)) {
  //                 joinedSpans.spans = joinedSpans.spans.concat(item.spans);
  //               }
  //             }
  //           });
  //         }
  //       });
  //       title = highlightPipe.transform(title, 'title', joinedSpans);
  //       return title;
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  formatTitle(news) {
    if (news) {
      if (news.reduce_visibility) {
        let title = '';
        news.matches.map((data) => {
          title += data.entity.alpha_id + ' ';
        });
        news.titleFormatted = title;
      } else if (this.params.newstype === 'Events' && this.params.eventid) {
        let title = '';
        const eventSpans = news.events.filter((item) => {
          return item.id === this.params.eventid;
        });
        const firstSpan = eventSpans[0];
        if (firstSpan && firstSpan.where === 'title') {
          title =
            news && news.title
              ? news.title.substring(firstSpan.span.start, firstSpan.span.end)
              : '';
          const newsCopy = JSON.parse(JSON.stringify(news));
          const safeHtmlPipe = new SafeHtmlPipe();
          const highlightPipe = new HighlightPipe();
          title = safeHtmlPipe.transform(title);
          const joinedSpans = {
            spans: [],
          };
          newsCopy.matches.forEach((item) => {
            const titleSpans = item.spans
              .filter((span) => {
                return span.location === 'title';
              })
              .map((span) => {
                return Object.assign({}, span, { sentiment: 'title' });
              });
            let tagactive;
            if (this.params.tag) {
              tagactive = decodeURI(this.params.tag.split('|')[0]).split(':');
            }
            if (
              this.params.tag &&
              tagactive[1] + ':' + tagactive[2] === item.entity.compound_key
            ) {
              if (!item.implied && titleSpans && titleSpans.length > 0) {
                joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
              }
            } else if (this.params.participant === item.entity.compound_key) {
              if (!item.implied && titleSpans && titleSpans.length > 0) {
                joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
              }
            } else if (
              this.params.type + ':' + this.params.id ===
              item.entity.compound_key
            ) {
              if (!item.implied && titleSpans && titleSpans.length > 0) {
                joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
              }
            } else if (
              (this.routing.isFolio() || this.routing.isHomepage()) &&
              this.auth.folio &&
              !this.params.portfolioId
            ) {
              this.auth.folio.content.forEach((portfolioEntity) => {
                if (portfolioEntity === item.entity.compound_key) {
                  if (!item.implied && titleSpans && titleSpans.length > 0) {
                    joinedSpans.spans = joinedSpans.spans.concat(titleSpans);
                  }
                }
              });
            }
          });
          title = highlightPipe.transform(title, 'title', joinedSpans);
        } else if (firstSpan && firstSpan.where === 'content') {
          title =
            news && news.content
              ? news.content.substring(firstSpan.span.start, firstSpan.span.end)
              : '';
          const newsCopy = JSON.parse(JSON.stringify(news));
          const safeHtmlPipe = new SafeHtmlPipe();
          const highlightPipe = new HighlightPipe();
          title = safeHtmlPipe.transform(title);
          const joinedSpans = {
            spans: [],
          };
          newsCopy.matches.forEach((item) => {
            const contentSpans = item.spans
              .filter((span) => {
                return (
                  span.location === 'content' &&
                  span.start >= firstSpan.span.start &&
                  span.end <= firstSpan.span.end
                );
              })
              .map((span) => {
                return Object.assign({}, span, {
                  sentiment: 'title',
                  start: span.start - firstSpan.span.start,
                  end: span.end - firstSpan.span.start,
                });
              });
            let tagactive;
            if (this.params.tag) {
              tagactive = decodeURI(this.params.tag.split('|')[0]).split(':');
            }
            if (
              this.params.tag &&
              tagactive[1] + ':' + tagactive[2] === item.entity.compound_key
            ) {
              if (!item.implied && contentSpans && contentSpans.length > 0) {
                joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
              }
            } else if (this.params.participant === item.entity.compound_key) {
              if (!item.implied && contentSpans && contentSpans.length > 0) {
                joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
              }
            } else if (
              this.params.type + ':' + this.params.id ===
              item.entity.compound_key
            ) {
              if (!item.implied && contentSpans && contentSpans.length > 0) {
                joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
              }
            } else if (
              (this.routing.isFolio() || this.routing.isHomepage()) &&
              this.auth.folio &&
              !this.params.portfolioId
            ) {
              this.auth.folio.content.forEach((portfolioEntity) => {
                if (portfolioEntity === item.entity.compound_key) {
                  if (
                    !item.implied &&
                    contentSpans &&
                    contentSpans.length > 0
                  ) {
                    joinedSpans.spans = joinedSpans.spans.concat(contentSpans);
                  }
                }
              });
            }
          });
          title = highlightPipe.transform(title, 'content', joinedSpans);
        }
        // if (this.newstype !== 'Events') {
        //   if (!title) {
        //     title = 'Click to see text - event span unavailable due to reprocessing';
        //     news.hidden = true;
        //     this.cdr.detectChanges();
        //   } else if (this.duplicate) {
        //     if (!this.deduplicatedEventsTitles.has(title)) {
        //       this.deduplicatedEventsTitles.add(title);
        //       this.filteredEvents.push(news.id);
        //     }
        //     if (this.deduplicatedEventsTitles.has(title) && !this.filteredEvents.includes(news.id)) {
        //       news.hidden = true;
        //       this.cdr.detectChanges();
        //     }
        //   }
        // }
        if (!title) {
          title =
            'Click to see text - event span unavailable due to reprocessing';
          news.hidden = true;
          // this.cdr.detectChanges();
        } else if (this.duplicate) {
          if (!this.deduplicatedEventsTitles.has(title)) {
            this.deduplicatedEventsTitles.add(title);
            this.filteredEvents.push(news.id);
          }
          if (
            this.deduplicatedEventsTitles.has(title) &&
            !this.filteredEvents.includes(news.id)
          ) {
            news.hidden = true;
            // this.cdr.detectChanges();
          }
        }
        news.titleFormatted = title;
      } else {
        const newsCopy = JSON.parse(JSON.stringify(news));
        let title = newsCopy.title;
        const safeHtmlPipe = new SafeHtmlPipe();
        const highlightPipe = new HighlightPipe();
        title = safeHtmlPipe.transform(title);
        const joinedSpans = {
          spans: [],
        };
        newsCopy.matches.forEach((item) => {
          item.spans.forEach((span) => {
            span.sentiment = 'title';
          });
          let tagactive;
          if (this.params.tag) {
            tagactive = decodeURI(this.params.tag.split('|')[0]).split(':');
          }
          if (
            this.params.tag &&
            tagactive[1] + ':' + tagactive[2] === item.entity.compound_key
          ) {
            if (!item.implied && item.spans && item.spans.length > 0) {
              joinedSpans.spans = joinedSpans.spans.concat(item.spans);
            }
          } else if (this.params.participant === item.entity.compound_key) {
            if (!item.implied && item.spans && item.spans.length > 0) {
              joinedSpans.spans = joinedSpans.spans.concat(item.spans);
            }
          } else if (
            this.params.type + ':' + this.params.id ===
            item.entity.compound_key
          ) {
            if (!item.implied && item.spans && item.spans.length > 0) {
              joinedSpans.spans = joinedSpans.spans.concat(item.spans);
            }
          } else if (
            (this.routing.isFolio() || this.routing.isHomepage()) &&
            this.auth.folio &&
            !this.params.portfolioId
          ) {
            this.auth.folio.content.forEach((portfolioEntity) => {
              if (portfolioEntity === item.entity.compound_key) {
                if (!item.implied && item.spans && item.spans.length > 0) {
                  joinedSpans.spans = joinedSpans.spans.concat(item.spans);
                }
              }
            });
          }
        });
        title = highlightPipe.transform(title, 'title', joinedSpans);
        news.titleFormatted = title;
      }
    } else {
      news.titleFormatted = '';
    }
    return news;
  }

  inFrame(news) {
    // if (!this.params.newsday && !this.params.time.split('--')[1]) {
    let itime = false;
    if (this.params.time) {
      itime = this.params.time.split('--')[1];
    }
    if (!this.params.newsday && !itime) {
      const time = moment(news.publish_time).valueOf();
      news.time = undefined;

      if (!this.newsfeedData.fromAssistedBookmarking) {
        if (
          this.frametime.today &&
          moment().subtract(1, 'days').endOf('day').valueOf() < time
        ) {
          this.frametime.today = false;
          news.time = 'TODAY';
        } else if (
          this.frametime.twentyfourhours &&
          moment().subtract(1, 'days').endOf('day').valueOf() > time &&
          moment().subtract(1, 'days').valueOf() < time
        ) {
          this.frametime.twentyfourhours = false;
          news.time = '24 HOURS';
        } else if (
          this.frametime.week &&
          moment().subtract(1, 'days').valueOf() > time &&
          moment().subtract(7, 'days').valueOf() < time
        ) {
          this.frametime.week = false;
          news.time = 'WEEK';
        } else if (
          this.frametime.month &&
          moment().subtract(7, 'days').valueOf() > time &&
          moment().subtract(30, 'days').valueOf() < time
        ) {
          this.frametime.month = false;
          news.time = 'MONTH';
        } else if (
          this.frametime.quarter &&
          moment().subtract(30, 'days').valueOf() > time &&
          moment().subtract(90, 'days').valueOf() < time
        ) {
          this.frametime.quarter = false;
          news.time = 'QUARTER';
        } else if (
          this.frametime.year &&
          moment().subtract(90, 'days').valueOf() > time
        ) {
          this.frametime.year = false;
          news.time = 'YEAR';
        }
      }

      news.hidden = false;
      if (
        this.params.time === '2' &&
        moment().subtract(1, 'days').valueOf() > time &&
        moment().subtract(7, 'days').valueOf() < time
      ) {
        news.hidden = true;
      }
    }
    return news;
  }

  checkTimeframe() {
    if (this.params.time) {
      const days = this.yukkApi.myFromTo(this.params.time).period;
      if (this.params.flagevent) {
        if (days < 465) {
          return true;
        } else {
          return false;
        }
      } else {
        if (days < 100) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  iSort(newsfeed) {
    if (this.newstype && this.newstype !== 'Relevant') {
      newsfeed.sort(
        (a, b) =>
          new Date(b.publish_time).valueOf() -
          new Date(a.publish_time).valueOf(),
      );
    }
  }

  iDuplicate(value) {
    if (this.duplicate) {
      this.deduplicatedEventsTitles = new Set();
      this.filteredEvents = [];
      if (!this.routing.isNewsletter()) {
        value.reverse();
      }
      const seenDuplicateGroupIds = new Set();
      const seenTitles = new Set();
      const filtered = [];
      value.forEach((doc) => {
        if (
          !seenDuplicateGroupIds.has(doc.duplicate_group_id) &&
          (!seenTitles.has(doc.title) || !doc.title)
        ) {
          if (this.newstype !== 'Events') {
            seenDuplicateGroupIds.add(doc.duplicate_group_id);
          }
          seenTitles.add(doc.title);
          filtered.push(doc);
        }
      });
      return !this.routing.isNewsletter() ? filtered.reverse() : filtered;
    } else {
      return value;
    }
  }

  iLicensedContent(newsfeed) {
    if (this.licensedContent) {
      if (
        this.newstype === 'Chronological' &&
        !this.params.license &&
        newsfeed.length === 0
      ) {
        // this.licensedContent = false;
        // this.router.navigate([], {
        //   queryParams: {
        //     license: this.licensedContent
        //   },
        //   queryParamsHandling: 'merge',
        //   replaceUrl: false
        // });
        return newsfeed;
      } else {
        return newsfeed;
      }
    } else {
      return newsfeed;
    }
  }

  iTitleEntity(newsfeed) {
    if (this.titleEntity) {
      if (
        this.newstype === 'Chronological' &&
        !this.params.title &&
        newsfeed.length === 0
      ) {
        // this.titleEntity = false;
        // this.router.navigate([], {
        //   queryParams: {
        //     title: this.titleEntity
        //   },
        //   queryParamsHandling: 'merge',
        //   replaceUrl: false
        // });
        return newsfeed;
      } else {
        return newsfeed;
      }
    } else {
      return newsfeed;
    }
  }

  /**
   * bookmark the news
   */
  addStar(id) {
    if (this.stars.includes(id)) {
      this.auth.delStars(id).subscribe(() => {
        this.stars.splice(this.stars.indexOf(id), 1);
        if (this.config.appConfig.setup.useStorage) {
          localStorage.setItem('news_curation', this.stars.join(','));
        }
      });
    } else {
      this.auth.addStars(id).subscribe(() => {
        this.stars.push(id);
        if (this.config.appConfig.setup.useStorage) {
          localStorage.setItem('news_curation', this.stars.join(','));
        }
      });
    }
  }

  /**
   * display the bookmark
   */
  iBookmark(id) {
    return this.stars.includes(id);
  }

  onMouseEnter(trigger, element) {
    if (element === 'button') {
      this.inButton = true;
      setTimeout(() => {
        if (this.inButton) {
          trigger.openMenu();
        }
      }, 200);
    } else {
      this.inMenu = true;
    }
  }

  onMouseLeave(trigger, element) {
    if (element === 'button') {
      this.inButton = false;
      setTimeout(() => {
        if (!this.inMenu) {
          trigger.closeMenu();
        }
      }, 400);
    } else {
      this.inMenu = false;
      trigger.closeMenu();
    }
  }

  onMouseEnterRow(item) {
    item.visible = true;
  }

  onMouseLeaveRow(item) {
    item.visible = false;
  }

  getMoreNews(story, index) {
    if (story.news.length === 0) {
      this.yukkApi
        .newsfeedStory({ storyId: story.id, params: this.params })
        .subscribe((result) => {
          this.newsfeed[index].news = this.iLicensedContent(
            this.iTitleEntity(this.iDuplicate(result.articles)),
          );
          this.newsfeed[index].news.map((news) => this.formatTitle(news));
          this.morenews[index] = !this.morenews[index];
        });
    } else {
      this.morenews[index] = !this.morenews[index];
    }
  }

  showNewsRecommendations(show) {
    this.showRecommendations = show;
    if (show) {
      const titles = [];
      this.newsfeedCopy = JSON.parse(JSON.stringify(this.newsfeed));
      this.newsfeed.forEach((item) => {
        titles.push(item.title);
      });
      let newsfeedNew = [];
      this.yukkApi
        .newsfeed({
          returnRecommendations: true,
          params: this.params,
          custom: {
            title: titles,
          },
        })
        .subscribe((result) => {
          Object.values(result.news).forEach((item, index) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const articles = item.map((el) => {
              el.article.recommendation = true;
              return el.article;
            });
            newsfeedNew.push(this.newsfeed[index]);
            newsfeedNew = newsfeedNew.concat(articles);
          });
          this.newsfeed = newsfeedNew;
          this.arraid = this.newsfeed.map((news) => news.id);
        });
    } else {
      this.newsfeed = JSON.parse(JSON.stringify(this.newsfeedCopy));
      this.arraid = this.newsfeed.map((news) => news.id);
      // this.router.navigate([], {
      //   queryParams: {
      //     update: Math.random()
      //   },
      //   queryParamsHandling: 'merge',
      //   replaceUrl: true,
      // });
    }
  }

  exportBookmarkedArticles(articles) {
    const today = moment().format('YYYY-MM-DD_hh-mm-ss');
    const bookmarks = {
      bookmarks: articles.map((news) => news.id),
    };
    const bookmarksString = JSON.stringify(bookmarks);
    const blob = new Blob([bookmarksString], { type: 'application/json' });
    FileSaver.saveAs(blob, 'bookmarks_export_' + today + '.json');
  }

  importBookmarkedArticles(event) {
    if (event !== null) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const result = JSON.parse(e.target.result);
            this.auth
              .importStars({
                bookmarks: result.bookmarks,
              })
              .subscribe(() => {
                this.router.navigate([], {
                  queryParams: {
                    update: Math.random(),
                  },
                  queryParamsHandling: 'merge',
                });
              });
          };
          reader.readAsText(event.target.files[i]);
        }
      }
    }
  }

  // openRecommendationsDialog(templateRef) {
  //   this.dialog.closeAll();
  //   const dialogRef = this.dialog.open(templateRef, {
  //     width: '300px'
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.recommendationsApplied = true;
  //   });
  // }

  /**
   * open recommendations setup page aka Assisted Bookmarking Tool
   */
  // openRecommendationsSetup () {
  //   this.auth.newsfeedRef = this.newsfeedRef;
  //   this.dialog.closeAll();
  //   const dialogRef = this.dialog.open(RecommendationsComponent, {
  //     autoFocus: false
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.auth.newsfeedRef = null;
  //     this.recommendationsApplied = true;
  //     this.router.navigate([], {
  //       queryParams: {
  //         update: Math.random()
  //       },
  //       queryParamsHandling: 'merge',
  //       replaceUrl: true
  //     });
  //   });
  // }

  /**
   * build search query based on the entities name to find more articles
   */
  buildSearchQuery() {
    if (this.params.type && this.params.id && this.params.id !== 'default') {
      const compoundKey = this.params.type + ':' + this.params.id;
      this.yukkApi.getEntitiesInfo([compoundKey], 'array').subscribe((res) => {
        // const entityName = this.stripLegalName(res[0].name);
        const entityName = res[0].name;
        const words = entityName.split(' ').filter((item) => item !== '');
        let query = {};
        if (words.length < 3) {
          query = {
            filters: [],
            groups: [],
            operator: 'and',
          };
          query['groups'].push({
            filters: [
              {
                field: 'text',
                negated: false,
                value: entityName,
              },
            ],
            groups: [],
            operator: 'or',
          });
        } else if (words.length === 3) {
          query = {
            filters: [],
            groups: [],
            operator: 'and',
          };
          query['groups'].push({
            filters: [
              {
                field: 'text',
                negated: false,
                value: words[0] + ' ' + words[1] + ' ' + words[2],
              },
              {
                field: 'text',
                negated: false,
                value: words[0] + ' ' + words[1],
              },
            ],
            groups: [],
            operator: 'or',
          });
        } else if (words.length > 3) {
          query = {
            filters: [],
            groups: [],
            operator: 'or',
          };
          query['groups'].push({
            filters: [
              {
                field: 'text',
                negated: false,
                value: entityName,
              },
            ],
            groups: [],
            operator: 'or',
          });
          words.forEach((item, index) => {
            if (index !== 0) {
              query['groups'].push({
                filters: [
                  {
                    field: 'word',
                    negated: false,
                    value: words[0],
                  },
                  {
                    field: 'word',
                    negated: false,
                    value: item,
                  },
                ],
                groups: [],
                slop: 5,
                operator: 'near',
              });
            }
          });
        }
        // const query = {
        //   operator: 'and',
        //   filters: [],
        //   groups: [{
        //     operator: 'and',
        //     groups: [],
        //     filters: [{
        //       field: 'text',
        //       negated: false,
        //       value: entityName
        //     }]
        //   }]
        // };
        let currentView = location.pathname.split('/')[4];
        let iparams = {};
        if (
          !currentView ||
          currentView === 'main' ||
          currentView === 'scanner'
        ) {
          currentView = 'chart';
        }
        this.auth.query = {
          name: query,
          query: query,
        };
        if (this.config.appConfig.setup.useStorage) {
          localStorage.setItem('search', JSON.stringify(this.auth.query));
        }
        const path = '/cockpit/news/search/';
        iparams = {
          type: null,
          id: Math.random(),
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
          participant: null,
          newstype: 'Chronological',
        };
        iparams['newsaudit'] = null;
        iparams['portfolioId'] = null;
        iparams['groupId'] = null;
        iparams['eventsFromScanner'] = null;
        if (!currentView) {
          currentView = '';
        }
        const fullPath = path + currentView;
        this.router.navigate([fullPath], {
          queryParams: iparams,
          queryParamsHandling: 'merge',
        });
      });
    }
  }

  /**
   * remove unwanted strings from text
   */
  stripLegalName(name) {
    const expStr = this.strippersList.join('|');
    return name
      .replace(new RegExp('\\b(' + expStr + ')', 'gi'), ' ')
      .replace(/\s{2,}/g, ' ')
      .trim();
  }

  /**
   * save user setting for automated assisisted bookmarking tool popup
   */
  onPopupChange() {
    if (this.config.appConfig.setup.useStorage) {
      localStorage.setItem(
        'bookmarking_tool_popup',
        this.bookmarkingToolPopup ? 'true' : 'false',
      );
    }
  }

  /*iExport() {
    const today = moment().format('YYYY-MM-DD');
    this.auth.getAuditsXLSX().subscribe(result => {
      FileSaver.saveAs(result, `newsaudits_export_${today}.xlsx`);
    });
  }*/
}
