/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StepsWelcomeComponent } from '../../main-steps/steps-welcome/steps-welcome.component';
// import { MenuFeedsComponent } from '../menu-feeds/menu-feeds.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntypedFormArray } from '@angular/forms';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';
import { UserAccount } from 'src/models/user.types';
import { MenuUserProfileComponent } from 'src/app/cockpit/main-menu/menu-user-profile/menu-user-profile.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 *
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-account',
  templateUrl: './menu-account.component.html',
  styleUrls: ['./menu-account.component.scss'],
})
export class MenuAccountComponent implements OnInit, OnDestroy {
  user: any;
  params: any;
  project: any;
  urlCustomerApi: any;
  frequency = 'DAILY';

  newsletter = new UntypedFormArray([]);

  userAccountInfo: UserAccount;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public auth: AuthService,
    public snackBar: MatSnackBar,
    public routing: RoutingService,
    public config: ConfigService,
  ) {
    this.project = this.config.appConfig.routing.project;
    this.urlCustomerApi = this.config.appConfig.urlCustomerApi;
    this.user = this.auth.authToken();
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.auth.accountInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountInfo) => {
        this.userAccountInfo = accountInfo;
      });
  }

  inTutorial() {
    this.dialog.open(StepsWelcomeComponent);
  }

  onUserAdministration() {
    const urlCustomerApi = this.urlCustomerApi;
    const domainCurrent = this.getDomain(window.location.href);
    const domainCustomerApi = this.getDomain(this.urlCustomerApi);
    let token;
    if (this.config.appConfig.setup.useStorage) {
      token = localStorage.getItem('token');
    }
    if (token && domainCurrent === domainCustomerApi) {
      this.setCookie('yl-api-jwt', token, 1, domainCustomerApi);
      window.open(urlCustomerApi + '/v1');
    } else {
      window.open(urlCustomerApi + '/v1/login');
    }
  }

  setCookie(cName, cValue, expDays, domain) {
    const date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + date.toUTCString();
    document.cookie =
      cName +
      '=' +
      cValue +
      '; ' +
      expires +
      '; path=/; samesite=none; secure; domain=' +
      domain;
  }

  getDomain(url) {
    const hostName = new URL(url).hostname;
    let domain = hostName;
    if (hostName != null) {
      const parts = hostName.split('.').reverse();
      if (parts != null && parts.length > 1) {
        domain = parts[1] + '.' + parts[0];
      }
    }
    return domain;
  }

  getToken() {
    if (this.config.appConfig.setup.useStorage) {
      const token = localStorage.getItem('token');
      this.copyMessage(token);
      this.snackBar.open('Token copied to clipboard.', 'OK', {
        duration: 5000,
      });
    }
  }

  openUserSettingsPopup(): void {
    console.log('openUserSettingsPopup');
    this.dialog.open(MenuUserProfileComponent, {
      disableClose: false,
      closeOnNavigation: true,
      data: {
        userInfo: this.userAccountInfo,
      },
    });
  }

  copyMessage(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  inNewsletter() {
    this.router.navigate(['/newsletter']);
  }
}
