<div
  *ngIf="auth.featureFlags.showMenuLHS"
  class="menu-aside"
  [class.asidetxt]="!asidetxt"
  [class.inblur]="params.tutorial"
  [class.noblur]="params.tutorial === 'menuaside'"
>
  <div class="contop">
    <!--<div class="home" *ngIf="auth.featureFlags.showHomepage && (subscription=='ALL' || subscription==='NEWS') && routing.isDev()">
      <div class="tito">Home</div>
      <div *ngIf="auth.featureFlags.showHomepage && routing.isDev()" class="mainmenu" matTooltip="Homepage" matTooltipPosition="right" routerLink="home" routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,riskevent:null,tag:null,type:null,id:null,portfolioId:null,sort:null}" queryParamsHandling="merge">
        <mat-icon>home</mat-icon>
        <span class="txt">Homepage</span>
      </div>
      <div class="space"></div>
    </div>-->
    <div
      class="news"
      *ngIf="
        auth.featureFlags.showNewsLab &&
        (user?.groups?.includes('SUBSCRIPTION_ALL') ||
          user?.roles?.includes('SUBSCRIPTION_ALL') ||
          user?.groups?.includes('SUBSCRIPTION_NEWS') ||
          user?.roles?.includes('SUBSCRIPTION_NEWS'))
      "
    >
      <div class="tito">News</div>
      <div
        *ngIf="auth.featureFlags.showPortfolio"
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Portfolio"
        matTooltipPosition="right"
        routerLink="news/portfolio"
        routerLinkActive="active"
        [class.active-disabled]="routing.isNetwork()"
        [queryParams]="{
          id: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
        }"
        queryParamsHandling="merge"
      >
        <mat-icon>collections_bookmark</mat-icon>
        <span class="txt">Portfolio</span>
      </div>
      <div
        *ngIf="auth.featureFlags.showCollection"
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Collection"
        matTooltipPosition="right"
        routerLink="news/query"
        routerLinkActive="active"
        [queryParams]="{
          id: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
        }"
        queryParamsHandling="merge"
      >
        <mat-icon>view_module</mat-icon>
        <span class="txt">Collection</span>
      </div>
      <div
        *ngIf="auth.featureFlags.showMarket || routing.isDev()"
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Market"
        matTooltipPosition="right"
        routerLink="news/market"
        routerLinkActive="active"
        [queryParams]="{
          id: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
        }"
        queryParamsHandling="merge"
      >
        <mat-icon>public</mat-icon>
        <span class="txt">Market</span>
      </div>
      <div
        *ngIf="
          auth.featureFlags.showChatLLM || user?.email?.includes('@yukkalab')
        "
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Chat"
        matTooltipPosition="right"
        routerLink="chat"
        routerLinkActive="active"
      >
        <mat-icon>chat_question</mat-icon>
        <span class="txt">Chat</span>
      </div>
    </div>
    <div
      class="trend"
      *ngIf="
        auth.featureFlags.showInvestLab &&
        (user?.groups?.includes('SUBSCRIPTION_ALL') ||
          user?.roles?.includes('SUBSCRIPTION_ALL') ||
          user?.groups?.includes('SUBSCRIPTION_TREND') ||
          user?.roles?.includes('SUBSCRIPTION_TREND'))
      "
    >
      <div
        class="space"
        *ngIf="
          user?.groups?.includes('SUBSCRIPTION_ALL') ||
          user?.roles?.includes('SUBSCRIPTION_ALL')
        "
      ></div>
      <div class="tito">Invest</div>
      <!--<div *ngIf="auth.featureFlags.showSignals" class="mainmenu" matTooltip="Signals" matTooltipPosition="right" routerLink="trend/secsignal"
        routerLinkActive="active">
        <mat-icon>timeline</mat-icon>
        <span class="txt">Signals</span>
      </div>-->
      <div
        *ngIf="auth.featureFlags.showSignals"
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Signals"
        matTooltipPosition="right"
        routerLink="invest/signal"
        routerLinkActive="active"
        [queryParams]="{
          id: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
          newstype: null,
        }"
        queryParamsHandling="merge"
      >
        <mat-icon svgIcon="chart-areaspline"></mat-icon>
        <span class="txt">Signals</span>
      </div>
      <div
        *ngIf="auth.featureFlags.showTrending"
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Trending"
        matTooltipPosition="right"
        routerLink="invest/trending"
        routerLinkActive="active"
        [queryParams]="{
          id: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
          newstype: null,
        }"
        queryParamsHandling="merge"
      >
        <mat-icon>whatshot</mat-icon>
        <span class="txt">Trending</span>
      </div>
      <!-- <div class="mainmenu" *ngIf="routing.isDev()" matTooltip="Multichart" matTooltipPosition="right"
        routerLink="trend/multichart" routerLinkActive="active">
        <mat-icon>multiline_chart</mat-icon>
        <span class="txt">Multichart</span>
      </div> -->
      <div class="space"></div>
    </div>

    <div
      class="track"
      *ngIf="auth.featureFlags.showTrackNetwork || routing.isDev()"
    >
      <!--<div class="space" *ngIf="user?.groups?.includes('SUBSCRIPTION_ALL') || user?.roles?.includes('SUBSCRIPTION_ALL')"></div>-->
      <div class="tito">Track</div>
      <div
        *ngIf="auth.scorelabSettings.defaultScore === 'esg'"
        class="mainmenu"
        [class.light]="routing.theme === 'light'"
        matTooltip="Network"
        matTooltipPosition="right"
        routerLink="news/portfolio/network"
        routerLinkActive="active"
        [queryParams]="{
          id: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          tag: null,
          newstype: null,
        }"
        queryParamsHandling="merge"
      >
        <mat-icon>share</mat-icon>
        <span class="txt">Network</span>
      </div>
      <div
        *ngIf="auth.scorelabSettings.defaultScore !== 'esg'"
        class="mainmenu"
        matTooltip="Please select ESG Score to enable this view"
        matTooltipPosition="right"
        [style.opacity]="0.25"
        (click)="openNetwork()"
      >
        <mat-icon>share</mat-icon>
        <span class="txt">Network</span>
      </div>
      <!-- <div class="mainmenu" *ngIf="routing.isDev()" matTooltip="Multichart" matTooltipPosition="right"
        routerLink="trend/multichart" routerLinkActive="active">
        <mat-icon>multiline_chart</mat-icon>
        <span class="txt">Multichart</span>
      </div> -->
    </div>
  </div>
  <div class="conbot" *ngIf="auth.featureFlags.showUserLab">
    <div class="space"></div>
    <div class="tito">User</div>
    <!-- <div class="mainmenu" *ngIf="routing.isDev()" matTooltip="Release" matTooltipPosition="right" [routerLink]=""
      [queryParams]="{sidenav:params.sidenav=='whatsnew'?null:'whatsnew'}" queryParamsHandling="merge">
      <mat-icon matBadge="3" matBadgeColor="warn">add_alert</mat-icon>
      <span class="txt">Release</span>
    </div> -->
    <div
      *ngIf="auth.featureFlags.showMenuAccount"
      class="mainmenu"
      [class.light]="routing.theme === 'light'"
      matTooltip="Account"
      matTooltipPosition="right"
      [routerLink]="[]"
      [queryParams]="{
        sidenav: params.sidenav === 'account' ? null : 'account',
        reflow: params.reflow === 'true' ? null : 'true',
      }"
      queryParamsHandling="merge"
    >
      <mat-icon>person</mat-icon>
      <span class="txt">Account</span>
    </div>
    <div
      *ngIf="auth.featureFlags.showSettings"
      class="mainmenu"
      [class.light]="routing.theme === 'light'"
      matTooltip="Scores"
      matTooltipPosition="right"
      (click)="openSettings()"
    >
      <span class="scores-icon">
        <svg-icon src="assets/icon/scores.svg"></svg-icon>
      </span>
      <span class="txt">Scores</span>
    </div>
    <div
      *ngIf="auth.featureFlags.showFlagsSetup"
      class="mainmenu"
      [class.light]="routing.theme === 'light'"
      matTooltip="Flags"
      matTooltipPosition="right"
      (click)="openFlags()"
    >
      <mat-icon>flag</mat-icon>
      <span class="txt">Flags</span>
    </div>
    <div
      *ngIf="auth.featureFlags.showNewsletter"
      class="mainmenu"
      [class.light]="routing.theme === 'light'"
      matTooltip="Newsletter"
      matTooltipPosition="right"
      routerLink="newsletter"
      routerLinkActive="active"
      [queryParams]="{
        id: null,
        eventid: null,
        eventype: null,
        scoreevent: null,
        flagevent: null,
        tag: null,
      }"
      queryParamsHandling="merge"
    >
      <mat-icon>email</mat-icon>
      <span class="txt">Newsletter</span>
    </div>
    <div
      *ngIf="auth.featureFlags.showAlertsSetup"
      class="mainmenu"
      [class.light]="routing.theme === 'light'"
      matTooltip="Alerts"
      matTooltipPosition="right"
      (click)="openAlerts()"
      [style.opacity]="eventsList ? 1 : 0.5"
      [style.cursor]="eventsList ? 'pointer' : 'wait'"
    >
      <mat-icon>notifications</mat-icon>
      <span class="txt">Alerts</span>
    </div>
    <!--<div *ngIf="auth.featureFlags.showAlerts" class="mainmenu" matTooltip="Alerts" matTooltipPosition="right" routerLink="alerts" routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null}" queryParamsHandling="merge">
      <mat-icon [matBadge]="notificationsCount" [matBadgeHidden]="notificationsCount === 0" matBadgePosition="before above">notifications</mat-icon>
      <span class="txt">Alerts</span>
    </div>-->
    <div
      *ngIf="routing.isDev()"
      class="mainmenu"
      [class.light]="routing.theme === 'light'"
      matTooltip="Tutorial"
      matTooltipPosition="right"
      (click)="inInfo()"
    >
      <mat-icon>help</mat-icon>
      <span class="txt">Tutorial</span>
    </div>
    <div
      *ngIf="auth.featureFlags.showExpand"
      class="btnmenu"
      [routerLink]="[]"
      [queryParams]="{ reflow: params.reflow === 'true' ? null : 'true' }"
      queryParamsHandling="merge"
      (click)="myAsidetxt()"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
  </div>
</div>
