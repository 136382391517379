<div class="user-settings" [formGroup]="userForm">
  <div mat-dialog-title class="user-settings__title">Settings</div>
  <div mat-dialog-title class="user-settings__subtitle">
    Manage your profile information.
  </div>

  <div mat-dialog-content class="user-settings__content">
    <section class="user-settings__section">
      <div class="user-settings__section-title">General</div>
      <div class="user-settings__form-grid">
        <div class="user-settings__row">
          <mat-label>First Name</mat-label>
          <mat-form-field
            class="user-settings__form-field"
            appearance="outline"
          >
            <input matInput formControlName="first_name" />
          </mat-form-field>
        </div>
        <div class="user-settings__row">
          <mat-label>Last Name</mat-label>
          <mat-form-field
            class="user-settings__form-field"
            appearance="outline"
          >
            <input matInput formControlName="last_name" />
          </mat-form-field>
        </div>
        <div class="user-settings__row">
          <mat-label>Company</mat-label>
          <mat-form-field
            class="user-settings__form-field"
            appearance="outline"
          >
            <input matInput formControlName="company" />
          </mat-form-field>
        </div>
        <div class="user-settings__row">
          <mat-label>Phone</mat-label>
          <mat-form-field
            class="user-settings__form-field"
            appearance="outline"
          >
            <input matInput formControlName="phone" />
          </mat-form-field>
        </div>
      </div>
      <div class="user-settings__form-grid">
        <div class="user-settings__row">
          <mat-label>Job Title</mat-label>
          <mat-form-field
            class="user-settings__form-field"
            appearance="outline"
          >
            <input matInput formControlName="job_title" />
          </mat-form-field>
        </div>
      </div>
    </section>

    <section class="user-settings__section">
      <div class="user-settings__section-title">Role</div>
      <div class="user-settings__section-subtitle">
        Changing the role will influence the chat's responses.
      </div>
      <div class="user-settings__form-grid">
        <div class="user-settings__row">
          <mat-form-field
            appearance="outline"
            class="user-settings__form-field role"
          >
            <mat-select formControlName="role">
              <mat-option *ngFor="let role of rolesOption" [value]="role.value">
                {{ role.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </section>

    <section class="user-settings__section" *ngIf="showSecuritySettings()">
      <div class="user-settings__section-title">Security</div>
      <div class="user-settings__security">
        <span>Change password</span>
        <button mat-icon-button routerLink="/password/change" mat-dialog-close>
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </section>
  </div>
  <div mat-dialog-actions class="user-settings__actions">
    <button mat-raised-button class="cancel-button" mat-dialog-close>
      CANCEL
    </button>
    <button
      mat-raised-button
      class="active-button"
      (click)="saveUserSettings()"
    >
      SAVE
    </button>
  </div>
</div>
