import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatParentStateService {
  private parentChatIdSubject = new BehaviorSubject<string>('');
  private parentChatTitleSubject = new BehaviorSubject<string>('');

  parentChatId$ = this.parentChatIdSubject.asObservable();
  parentChatTitle$ = this.parentChatTitleSubject.asObservable();

  get parentChatId(): string {
    return this.parentChatIdSubject.value;
  }

  setParentChatId(id: string): void {
    this.parentChatIdSubject.next(id);
  }

  setParentChatTitle(title: string): void {
    this.parentChatTitleSubject.next(title);
  }
}
