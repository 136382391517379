<div class="scanner-main">
  <div class="scanner-lhs" [class.mobile]="(screenSize$ | async)?.isMobile">
    <div class="scanner-lhs-header">
      <div class="scanner-lhs-title">
        {{ auth.getScoreName().name + ' Ranking' }}
      </div>
      <button
        mat-flat-button
        class="scanner-lhs-actions"
        *ngIf="
          !config.appConfig.setup.reportsDisabled &&
          [
            'general_risk',
            'immediate_risk',
            'esg',
            'esg_risk',
            'eb_sim',
            'eb_sim_risk',
            'credit_risk',
          ].includes(auth.scorelabSettings.defaultScore) &&
          isReportAvailable
        "
      >
        <div
          class="report"
          [matTooltip]="
            'Get a PDF summary on top ' +
            auth.getScoreName().name +
            ' Stories, Score Trends and Peer-Group Benchmarks.'
          "
          (click)="openReportDialog()"
        >
          <span>Report</span>
          <mat-icon>picture_as_pdf</mat-icon>
          <span class="info-label">BETA</span>
        </div>
      </button>
    </div>

    <div class="scanner-lhs-content">
      <app-scanner-items></app-scanner-items>
    </div>
  </div>

  <div
    class="scanner-rhs"
    *appShowOn="{ desktop: true, tablet: true, largeDesktop: true }"
  >
    <div class="scanner-insights">
      <app-scanner-insights></app-scanner-insights>
    </div>
    <div class="scanner-news">
      <app-news-newsfeed></app-news-newsfeed>
    </div>
  </div>
</div>
