import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';

@Component({
  selector: 'app-news-chartmap',
  templateUrl: './news-chartmap.component.html',
  styleUrls: ['./news-chartmap.component.scss'],
})
export class NewsChartmapComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  screenSize$ = this.breakpointService.screenSize$;
  constructor(
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private breakpointService: BreakpointService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }
}
