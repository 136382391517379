/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogReportComponent } from '../../../micro-components/dialog-report/dialog-report.component';
import { PortfolioSetupComponent } from 'src/app/project/settings/portfolio-setup/portfolio-setup.component';
import * as moment from 'moment/moment';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scanner-main',
  templateUrl: './scanner-main.component.html',
  styleUrls: ['./scanner-main.component.scss'],
})
export class ScannerMainComponent {
  params: any;
  previousValue: any;

  isReportAvailable = false;
  reportStatus: string;

  isDialogOpen: boolean = false;
  screenSize$ = this.breakpointService.screenSize$;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
    public config: ConfigService,
    public dialog: MatDialog,
    private breakpointService: BreakpointService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (params.context === 'regular_signup' && !this.isDialogOpen) {
        this.isDialogOpen = true;
        this.dialog
          .open(PortfolioSetupComponent)
          .afterClosed()
          .subscribe(() => {
            this.isDialogOpen = false;
            this.router.navigate([], {
              queryParams: {
                context: null,
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
          });
      }

      if (routing.reFresh(params, this.previousValue, ['id'])) {
        this.isReportAvailable = false;

        const date = moment(new Date()).format('YYYY-MM-DD');
        let type = this.auth.scorelabSettings.defaultScore;

        if (type === 'credit_risk') {
          type = 'credit_watch';
        }
        if (
          !this.config.appConfig.setup.reportsDisabled &&
          [
            'general_risk',
            'immediate_risk',
            'esg',
            'esg_risk',
            'eb_sim',
            'eb_sim_risk',
            'credit_watch',
          ].includes(type)
        ) {
          const portfolio = this.auth.folio.name;
          const companies = this.auth.folio.content.filter(
            (entity) => entity.split(':')[0] === 'company',
          );
          this.auth
            .getReportStatusPortfolio(portfolio, companies, type, date)
            .subscribe((res) => {
              this.reportStatus = res['status'];
              if (res['status'] !== 'not_enough_data') {
                this.isReportAvailable = true;
              }
            });
        }
      }
    });
  }

  /**
   * open popup dialog with reports details
   */
  openReportDialog() {
    this.dialog.open(DialogReportComponent, {
      data: {
        type: this.auth.scorelabSettings.defaultScore,
        entityId: this.auth.folio.uid,
        portfolioName: this.auth.folio.name,
        portfolioContent: this.auth.folio.content.filter(
          (entity) => entity.split(':')[0] === 'company',
        ),
        titleLine1: this.auth.folio.name,
        titleLine2: this.auth.getScoreName().name + ' Portfolio Report',
        messageLine:
          this.auth.folio.content.filter(
            (entity) => entity.split(':')[0] === 'company',
          ).length !== this.auth.folio.content.length
            ? 'Your portfolio includes entities other than companies that won’t be covered by the report.'
            : null,
        status: this.reportStatus,
      },
    });
  }
}
