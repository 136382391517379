<div class="chat__header">
  <mat-icon class="chat__header-icon" (click)="chatNavigationMenuOpen()"
    >menu</mat-icon
  >
  <div class="chat__header-title">
    <span>News Assistant</span>
    <span class="info-label">BETA</span>
  </div>

  <span
    class="chat__header-icon"
    (click)="startNewChat()"
    style="opacity: unset"
  >
    <svg-icon src="assets/icon/jam_write.svg"></svg-icon>
  </span>
</div>
