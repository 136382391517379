import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { BreakpointService, ScreenSize } from '../services/breakpoints.service';
import { takeUntil } from 'rxjs/operators';

type ShowOnConfig = {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  largeDesktop?: boolean;
};

@Directive({
  selector: '[appShowOn]',
})
export class ResponsiveDirective implements OnInit, OnDestroy {
  @Input('appShowOn') config: ShowOnConfig = {};
  private destroy$ = new Subject<void>();
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private breakpointService: BreakpointService,
  ) {}

  ngOnInit() {
    this.breakpointService.screenSize$
      .pipe(takeUntil(this.destroy$))
      .subscribe((size) => {
        const shouldShow = this.shouldShowForSize(size);

        if (shouldShow && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!shouldShow && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  private shouldShowForSize(size: ScreenSize): boolean {
    if (Object.keys(this.config).length === 0) return true;

    return (
      (this.config.mobile && size.isMobile) ||
      (this.config.tablet && size.isTablet) ||
      (this.config.desktop && size.isDesktop) ||
      (this.config.largeDesktop && size.isLargeDesktop)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
