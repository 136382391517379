export const allRoles = [
  { label: 'Financial Analyst', value: 'financial_analyst' },
  { label: 'Investment Management', value: 'investment_management' },
  { label: 'Portfolio Management', value: 'portfolio_management' },
  { label: 'Private Investor', value: 'private_investor' },
  { label: 'Private', value: 'private' },
  { label: 'Individual', value: 'individual' },
  { label: 'Business Development', value: 'business_development' },
  { label: 'Consulting', value: 'consulting' },
  { label: 'Management', value: 'management' },
  { label: 'Sales', value: 'sales' },
  { label: 'Strategy', value: 'strategy' },
  { label: 'ESG Analyst', value: 'esg_analyst' },
  { label: 'ESG Investor', value: 'esg_investor' },
  { label: 'ESG Risk Manager', value: 'esg_risk_manager' },
  { label: 'Compliance/KYC', value: 'compliance_kyc' },
  { label: 'Credit Underwriter', value: 'credit_underwriter' },
  { label: 'Purchasing', value: 'purchasing' },
  { label: 'Risk Analyst', value: 'risk_analyst' },
  { label: 'Risk Manager', value: 'risk_manager' },
  { label: 'Supply Chain', value: 'supply_chain' },
];
