<div
  *ngIf="relatedQuestions?.length > 0"
  class="chat__related-questions"
  [class.mobile]="(screenSize$ | async)?.isMobile"
>
  <div>Related questions</div>
  <div
    class="related-questions__container"
    [class.mobile]="(screenSize$ | async)?.isMobile"
  >
    <div
      id="chat-follow_up_question"
      *ngFor="let question of relatedQuestions; let i = index"
      class="related-questions__box"
      [id]="'related-question-' + i"
      (click)="onClickQuestionBox(question)"
    >
      {{ question }}
    </div>
  </div>
</div>
