/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../service/routing.service';
import { YukkApi } from '../../service/yukkapi.service';
import { AuthService } from '../../service/auth.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ChatDrawerService } from '../llm/services/chat-drawer-service/chat-drawer-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements AfterViewInit, OnDestroy {
  @ViewChild('drawer') drawer: MatDrawer;
  isDrawerOpen = false;
  private destroy$ = new Subject<void>();
  hierarchy: boolean;
  entity: boolean;
  folios: any;
  params: any;
  user: any;
  listsignal: any;
  sentiment: any;
  promptEvent: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    private yukkApi: YukkApi,
    private auth: AuthService,
    public dialog: MatDialog,
    private chatDrawerService: ChatDrawerService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.hierarchy = params.type === 'hierarchy';
      this.entity = params.type && params.type !== 'hierarchy';
      this.params = params;
    });

    this.folios = this.auth.portFolios();

    this.yukkApi.getSignalsList().subscribe((result) => {
      this.listsignal = result.entities;
    });
  }

  logOut() {
    this.auth.authLogout();
  }

  ngAfterViewInit() {
    this.chatDrawerService.drawerState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        if (this.drawer) {
          if (state) {
            this.drawer.open();
          } else {
            this.drawer.close();
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
