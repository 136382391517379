import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectGuard } from './guard/redirect.guard';
import { LoginRedirectGuard } from './guard/login-redirect.guard';
import { AuthGuard } from './guard/auth.guard';
import { AdminGuard } from './guard/admin.guard';
import { ReviewGuard } from './guard/review.guard';
import { FolioGuard } from './guard/folio.guard';
import { QueryGuard } from './guard/query.guard';
import { MarketGuard } from './guard/market.guard';
import { SearchGuard } from './guard/search.guard';
import { NewsGuard } from './guard/news.guard';
import { TrendGuard } from './guard/trend.guard';
import { FiltersGuard } from './guard/filters.guard';
import { ScannerGuard } from './guard/scanner.guard';
import { ChatGuard } from './guard/chat.guard';

import { LoginComponent } from './admin/user/login/login.component';
import { TrendIndicatorComponent } from './cockpit/main-trend/trend-indicator/trend-indicator.component';
import { MainNewsfeedComponent } from './cockpit/main-news/news-newsfeed/news-newsfeed.component';
import { MainNewsComponent } from './cockpit/main-news/main-news.component';
import { MainTrendComponent } from './cockpit/main-trend/main-trend.component';
import { TagcloudComponent } from './cockpit/main-news/news-tagcloud/news-tagcloud.component';
import { MessageComponent } from './admin/user/message/message.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { ReportListComponent } from './admin/report/report-list/report-list.component';
import { AdminComponent } from './admin/admin.component';
import { NewsPortfolioComponent } from './cockpit/main-news/news-portfolio/news-portfolio.component';
import { NewsRadarComponent } from './cockpit/main-news/news-radar/news-radar.component';
import { NewsParticipantsComponent } from './cockpit/main-news/news-participants/news-participants.component';
import { NewsBoardComponent } from './cockpit/main-news/news-board/news-board.component';
import { NewsChartComponent } from './cockpit/main-news/news-chart/news-chart.component';
import { NewsTrendingComponent } from './cockpit/main-news/news-trending/news-trending.component';
import { AdminAnnotationComponent } from './admin/annotation/annotation.component';
import { WidgetComponent } from './project/widget/widget.component';
import { WidgetGalleryComponent } from './project/widget/widget-gallery/widget-gallery.component';
import { WidgetIframeComponent } from './project/widget/widget-iframe/widget-iframe.component';
import { PasswordComponent } from './admin/user/password/password.component';
import { NewsChartmapComponent } from './cockpit/main-news/news-chartmap/news-chartmap.component';
import { NewsTreemapComponent } from './cockpit/main-news/news-treemap/news-treemap.component';
import { NewsletterComponent } from './project/newsletter/newsletter.component';
import { NewsletterPreviewComponent } from './project/newsletter/newsletter-preview/newsletter-preview.component';
import { QueryAlertComponent } from './cockpit/main-news/news-query/query-alert/query-alert.component';
import { ChartmapCompanyComponent } from './cockpit/main-news/news-chartmap/chartmap-company/chartmap-company.component';
import { QueryFolioComponent } from './cockpit/main-news/news-query/query-folio/query-folio.component';
import { NewsOutletComponent } from './cockpit/main-news/news-outlet/news-outlet.component';
import { QueryChartComponent } from './cockpit/main-news/news-query/query-chart/query-chart.component';
import { BoardEventComponent } from './cockpit/main-news/news-board/board-event/board-event.component';
import { ScannerComponent } from './cockpit/scanner/scanner.component';
import { StatsComponent } from './project/stats/stats.component';
import { ChatComponent } from './project/llm/chat/chat.component';
import { DynamicContainerComponent } from './container/dynamic-container.component';

const routes: Routes = [
  { path: '', redirectTo: 'cockpit', pathMatch: 'full' },

  { path: 'iframe', redirectTo: 'widget', pathMatch: 'full' },
  { path: 'logout', redirectTo: '/message/logout', pathMatch: 'full' },
  {
    path: 'cockpit',
    component: DynamicContainerComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'news', pathMatch: 'full' },
      {
        path: 'news',
        component: NewsOutletComponent,
        canActivate: [RedirectGuard, NewsGuard, FiltersGuard],
        children: [
          { path: '', redirectTo: 'portfolio', pathMatch: 'full' },

          { path: 'board', redirectTo: 'market', pathMatch: 'full' },

          {
            path: 'market',
            component: MainNewsComponent,
            canActivate: [MarketGuard],
            runGuardsAndResolvers: 'always',
            children: [
              { path: '', redirectTo: 'main', pathMatch: 'full' },
              { path: 'main', component: NewsBoardComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'audit', component: NewsChartmapComponent },
            ],
          },

          {
            path: 'portfolio',
            component: MainNewsComponent,
            canActivate: [RedirectGuard, ScannerGuard, FolioGuard],
            runGuardsAndResolvers: 'always',
            children: [
              { path: '', redirectTo: 'scanner', pathMatch: 'full' },
              { path: 'scanner', component: ScannerComponent },
              { path: 'main', component: NewsPortfolioComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              { path: 'network', component: NewsChartmapComponent },
              { path: 'audit', component: NewsChartmapComponent },
            ],
          },

          {
            path: 'query',
            component: MainNewsComponent,
            canActivate: [QueryGuard],
            runGuardsAndResolvers: 'always',
            children: [
              { path: '', redirectTo: 'main', pathMatch: 'full' },
              { path: 'main', component: QueryFolioComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              { path: 'audit', component: NewsChartmapComponent },
            ],
          },

          {
            path: 'search',
            component: MainNewsComponent,
            canActivate: [SearchGuard],
            children: [
              { path: '', redirectTo: 'chart', pathMatch: 'full' },
              { path: 'main', component: NewsChartmapComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              // { path: 'network', component: NetworkComponent },
              { path: 'audit', component: NewsChartmapComponent },
            ],
          },
        ],
      },

      {
        path: 'invest',
        component: MainTrendComponent,
        canActivate: [TrendGuard],
        children: [
          { path: '', redirectTo: 'signal', pathMatch: 'full' },
          {
            path: 'signal',
            canActivate: [TrendGuard],
            component: TrendIndicatorComponent,
          },

          { path: 'trending', component: NewsTrendingComponent },
        ],
      },

      {
        path: 'chat',
        component: ChatComponent,
        canActivate: [ChatGuard],
        children: [],
      },
      {
        path: 'newsletter',
        component: NewsletterComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', redirectTo: 'preview', pathMatch: 'full' },
          { path: 'preview', component: NewsletterPreviewComponent },
        ],
      },

      {
        path: 'alerts',
        component: QueryAlertComponent,
        canActivate: [AuthGuard],
        children: [],
      },
    ],
  },

  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'report', pathMatch: 'full' },
      {
        path: 'report',
        canActivate: [ReviewGuard],
        component: ReportListComponent,
      },
      {
        path: 'feedback',
        canActivate: [ReviewGuard],
        component: FeedbackComponent,
      },
      {
        path: 'annotation',
        canActivate: [ReviewGuard],
        component: AdminAnnotationComponent,
      },
    ],
  },

  {
    path: 'stats',
    component: StatsComponent,
    canActivate: [AuthGuard, AdminGuard],
  },

  {
    path: 'widget',
    component: WidgetComponent,
    canActivate: [AuthGuard, RedirectGuard],
    children: [
      { path: 'gallery', component: WidgetGalleryComponent },
      {
        path: 'portfolio',
        component: WidgetIframeComponent,
        canActivate: [AuthGuard, FolioGuard],
        runGuardsAndResolvers: 'always',
        children: [
          { path: '', component: NewsPortfolioComponent },
          { path: 'chart', component: NewsChartComponent },
          { path: 'chart2', component: QueryChartComponent },
          { path: 'radar', component: NewsRadarComponent },
          { path: 'participants', component: NewsParticipantsComponent },
          // { path: 'network', component: NetworkComponent },
        ],
      },
      {
        path: 'query',
        component: WidgetIframeComponent,
        canActivate: [AuthGuard, QueryGuard],
        runGuardsAndResolvers: 'always',
        children: [
          { path: '', component: QueryFolioComponent },
          { path: 'chart', component: QueryChartComponent },
          { path: 'radar', component: NewsRadarComponent },
          { path: 'participants', component: NewsParticipantsComponent },
          // { path: 'network', component: NetworkComponent },
        ],
      },
      {
        path: '',
        component: WidgetIframeComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
          { path: 'newsfeed', component: MainNewsfeedComponent },
          { path: 'chart', component: NewsChartComponent },
          { path: 'treemap', component: NewsTreemapComponent },
          { path: 'company', component: ChartmapCompanyComponent },
          // { path: 'network', component: NetworkComponent },
          { path: 'trending', component: NewsTrendingComponent },
          { path: 'topicloud', component: TagcloudComponent },
          { path: 'radar', component: NewsRadarComponent },
          { path: 'participants', component: NewsParticipantsComponent },
          // { path: 'secsignal', component: TrendSecsignalComponent },
          { path: 'signal', component: TrendIndicatorComponent },
          { path: 'event', component: BoardEventComponent },
        ],
      },
      {
        path: 'reader',
        component: WidgetIframeComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'story',
        component: WidgetIframeComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
      },
    ],
  },

  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginRedirectGuard],
  },
  // { path: 'registration', component: RecordComponent },
  { path: 'password', component: PasswordComponent },
  { path: 'password/:id', component: PasswordComponent },
  { path: 'message/:id', component: MessageComponent },

  { path: '**', redirectTo: 'message/error404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
