/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { RoutingService } from '../../service/routing.service';
import { YukkApi } from '../../service/yukkapi.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { MatLegacyTabNav as MatTabNav } from '@angular/material/legacy-tabs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { PortfolioNameComponent } from './news-portfolio/portfolio-name/portfolio-name.component';
import { QueryNameComponent } from './news-query/query-name/query-name.component';
import { ConfigService } from 'src/app/service/config.service';
import { PortfolioImportComponent } from './news-portfolio/portfolio-import/portfolio-import.component';
import { autofolio } from './news-portfolio/preset-folio';
import { autocollection } from './news-query/query-folio/preset-collection';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';
import { take } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-main-news',
  templateUrl: './main-news.component.html',
  styleUrls: ['./main-news.component.scss'],
})
export class MainNewsComponent {
  params: any;
  previousValue: any;
  previousUrl: any;
  portfolios: any;
  collections: any;
  scrollDistance = 0;
  tabWidth = 247;
  tabsNumber = 0;
  selectedPortfolio: any;
  screenSize$ = this.breakpointService.screenSize$;
  @ViewChild(MatTabNav) public matTabNav: MatTabNav;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public yukkapi: YukkApi,
    public auth: AuthService,
    public config: ConfigService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private breakpointService: BreakpointService,
  ) {
    if (window.Cypress) {
      console.log('MainNewsComponent running Cypress');
      window.MainNewsComponent = this;
    }

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url.split('?')[0];
        if (currentUrl !== this.previousUrl) {
          if (this.routing.isFolio() && this.portfolios) {
            let tabIndex = 0;
            const sortedFolios = JSON.parse(JSON.stringify(this.portfolios));
            sortedFolios.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            sortedFolios.map((portfolio, index) => {
              if (
                portfolio.uid === this.params.id ||
                portfolio.uid === this.params.portfolioId
              ) {
                tabIndex = index;
              }
            });
            this.scrollDistance = this.tabWidth * tabIndex;
            this.tabsNumber = this.portfolios.length;
          }
          if (this.routing.isQuery() && this.collections) {
            let tabIndex = 0;
            const sortedCollections = JSON.parse(
              JSON.stringify(this.collections),
            );
            sortedCollections.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            sortedCollections.map((collection, index) => {
              if (collection.uid === this.params.groupId) {
                tabIndex = index;
              }
            });
            this.scrollDistance = this.tabWidth * tabIndex;
            this.tabsNumber = this.collections.length;
          }

          if (this.matTabNav) {
            setTimeout(() => {
              this.matTabNav.scrollDistance = this.scrollDistance;
            }, 100);
          }
        }
        this.previousUrl = currentUrl;
      }
    });

    this.route.data.subscribe((fragment) => {
      return fragment;
    });

    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (params.project) {
        this.yukkapi.setProject(params.project);
      }

      if (
        this.routing.isFolio() &&
        (!this.previousValue ||
          (!this.params?.news && !this.previousValue?.news))
      ) {
        this.portfolios = this.auth.folios;
        if (this.portfolios && this.portfolios.length > 0) {
          if (
            !['sentiment'].includes(this.auth.scorelabSettings.defaultScore)
          ) {
            const paramsChange = Object.assign({}, this.params, {
              scorePortfolio: true,
              scoreForPortfolio: true,
              scoreForTabs: true,
            });
            this.yukkapi
              .scoresTimeSeries('score', paramsChange, '')
              .subscribe((result) => {
                this.portfolios = this.auth.folios.map((portfolio) => {
                  if (
                    portfolio.uid === this.params.id ||
                    portfolio.uid === this.params.portfolioId
                  ) {
                    return Object.assign({}, portfolio, {
                      score:
                        result !== undefined &&
                        result['score_ts'][result['score_ts'].length - 1]
                          .score !== null
                          ? result['score_ts'][
                              result['score_ts'].length - 1
                            ].score.toFixed(1)
                          : undefined,
                    });
                  } else {
                    return Object.assign({}, portfolio, { score: undefined });
                  }
                });
              });
          } else {
            const paramsChange = this.params.portfolioId
              ? Object.assign({}, this.params, {
                  portfolio: true,
                  scoreForTabs: true,
                })
              : Object.assign({}, this.params, { scoreForTabs: true });
            this.yukkapi.isentiment(paramsChange, true).subscribe((result) => {
              this.portfolios = this.auth.folios.map((portfolio) => {
                if (
                  portfolio.uid === this.params.id ||
                  portfolio.uid === this.params.portfolioId
                ) {
                  return Object.assign({}, portfolio, {
                    sentiment:
                      result !== undefined ? result.sentiment : undefined,
                  });
                } else {
                  return Object.assign({}, portfolio, {
                    sentiment: {
                      count: undefined,
                      sentiment: undefined,
                      sentiment_delta: undefined,
                      volume_ratio: undefined,
                    },
                  });
                }
              });
            });
          }
        }
      }

      if (
        this.routing.isQuery() &&
        (!this.previousValue ||
          (!this.params?.news && !this.previousValue?.news))
      ) {
        if (this.auth.querys && this.auth.querys.length > 0) {
          this.collections = this.auth.querys;
          if (
            this.collections &&
            this.collections.length > 0 &&
            this.params.groupId !== 'default'
          ) {
            this.yukkapi
              .isentiment(
                Object.assign({}, this.params, { collection: true }),
                true,
              )
              .subscribe((result) => {
                this.collections = this.auth.querys.map((collection) => {
                  if (collection.uid === this.params.groupId) {
                    return Object.assign({}, collection, {
                      sentiment:
                        result !== undefined ? result.sentiment : undefined,
                    });
                  } else {
                    return Object.assign({}, collection, {
                      sentiment: {
                        count: undefined,
                        sentiment: undefined,
                        sentiment_delta: undefined,
                        volume_ration: undefined,
                      },
                    });
                  }
                });
              });
          }
        } else {
          this.collections = [];
        }
      }

      if (this.routing.reFresh(this.params, this.previousValue, ['id'])) {
        if (this.routing.isFolio() && !this.params.type) {
          this.selectedPortfolio = this.params.id;
          if (this.portfolios.length > this.tabsNumber) {
            let tabIndex = 0;
            const sortedFolios = JSON.parse(JSON.stringify(this.portfolios));
            sortedFolios.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            sortedFolios.map((portfolio, index) => {
              if (portfolio.uid === this.params.id) {
                tabIndex = index;
              }
            });
            this.scrollDistance = this.tabWidth * tabIndex;
          } else if (this.portfolios.length === this.tabsNumber) {
            let tabIndex = 0;
            const sortedFolios = JSON.parse(JSON.stringify(this.portfolios));
            sortedFolios.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            sortedFolios.map((portfolio, index) => {
              if (portfolio.uid === this.params.id) {
                tabIndex = index;
              }
            });
            if (tabIndex === 0) {
              this.scrollDistance = 0;
            }
          } else if (this.portfolios.length < this.tabsNumber) {
            this.scrollDistance = 0;
          }
          this.tabsNumber = this.portfolios.length;
        }

        if (this.routing.isQuery()) {
          if (this.collections.length > this.tabsNumber) {
            let tabIndex = 0;
            const sortedCollections = JSON.parse(
              JSON.stringify(this.collections),
            );
            sortedCollections.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            sortedCollections.map((collection, index) => {
              if (collection.uid === this.params.groupId) {
                tabIndex = index;
              }
            });
            this.scrollDistance = this.tabWidth * tabIndex;
          } else if (this.collections.length === this.tabsNumber) {
            let tabIndex = 0;
            const sortedCollections = JSON.parse(
              JSON.stringify(this.collections),
            );
            sortedCollections.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
            sortedCollections.map((collection, index) => {
              if (collection.uid === this.params.groupId) {
                tabIndex = index;
              }
            });
            if (tabIndex === 0) {
              this.scrollDistance = 0;
            }
          } else if (this.collections.length < this.tabsNumber) {
            this.scrollDistance = 0;
          }
          this.tabsNumber = this.collections.length;
        }

        if (this.matTabNav) {
          setTimeout(() => {
            this.matTabNav.scrollDistance = this.scrollDistance;
          }, 100);
        }
      }

      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        setTimeout(() => {
          if (this.matTabNav) {
            this.matTabNav._alignInkBarToSelectedTab();
          }
        }, 500);
      }
      this.previousValue = this.params;
    });
  }

  noBlur(value) {
    return (
      value === 'market' ||
      value === 'portfolio' ||
      value === 'collection' ||
      value === 'query' ||
      value === 'entity' ||
      value === 'aggregation' ||
      value === 'radar' ||
      value === 'network' ||
      value === 'trending' ||
      value === 'multichart' ||
      value === 'chart' ||
      value === 'newsviewinsights'
    );
  }

  inTabQuery(uid) {
    this.scrollDistance = this.matTabNav.scrollDistance;
    this.router.navigate([], {
      queryParams: {
        id: uid,
        groupId: uid,
        defaultsort: null,
        newstype: null,
        eventsFromScanner: null,
        tabsEventsFromScanner: this.params.eventsFromScanner ? 'true' : null,
        eventid: null,
        eventype: null,
        queryid: null,
        newsfeedFromQuery: null,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }

  inTabFolio(uid) {
    this.screenSize$.pipe(take(1)).subscribe((screenSize) => {
      if (!screenSize.isMobile) {
        this.scrollDistance = this.matTabNav.scrollDistance;
      }
      this.router.navigate([], {
        queryParams: {
          id: uid,
          type: null,
          portfolioId: null,
          tag: null,
          defaultsort: null,
          newstype: null,
          eventsFromScanner: null,
          tabsEventsFromScanner: this.params.eventsFromScanner ? 'true' : null,
          eventid: null,
          eventype: null,
          score: null,
          time: this.params.score ? null : this.params.time,
        },
        queryParamsHandling: 'merge',
      });
    });
  }

  editPortfolio(id) {
    if (id === this.auth.folio.uid) {
      this.dialog.open(PortfolioNameComponent, {
        data: {
          action: 'edit',
        },
      });
    }
  }

  editCollection() {
    this.dialog.open(QueryNameComponent, {
      data: this.auth.query ? this.auth.query : {},
    });
  }

  addPortfolio() {
    this.dialog.open(PortfolioNameComponent, {
      data: {
        action: 'new',
      },
    });
  }

  loadSamplePortfolio() {
    this.auth.setFolios(autofolio).subscribe((result) => {
      const portfolioId = JSON.parse(result).id[0];
      // this.router.navigate([], { queryParams: { folioupdate: Math.random() }, queryParamsHandling: 'merge' });
      this.router.navigate([], {
        queryParams: {
          id: portfolioId,
          update: Math.random(),
        },
        queryParamsHandling: 'merge',
      });
    });
  }

  loadSampleCollections() {
    for (let i = 0; i < autocollection.length; i++) {
      const result = autocollection[i];
      this.auth.addGroupQuery(result.name).subscribe((result2) => {
        const queriesArray = [];
        result.content.forEach((query) => {
          queriesArray.push(
            Object.assign({}, query, {
              groups: [result2.id],
            }),
          );
        });
        this.auth.addQueries(queriesArray).subscribe(() => {
          this.router.navigate([], {
            queryParams: {
              id: result2.id,
              groupId: result2.id,
              update: Math.random(),
            },
            queryParamsHandling: 'merge',
          });
        });
      });
    }
  }

  onDragOver(event) {
    event.preventDefault();
  }

  onDropSuccess(event) {
    event.preventDefault();
    this.iImport(event, 'portfolioDrop');
  }

  /**
   * Import list of isins or portfolios from files
   */
  iImport(event, type) {
    if (event === null && type === 'isinsCreate') {
      this.dialog.open(PortfolioImportComponent, {
        data: {
          create: true,
          // uid: this.params.folio,
        },
      });
    } else if (event === null && type === 'isinsAdd') {
      this.dialog.open(PortfolioImportComponent, {
        data: {
          create: false,
          // uid: this.params.folio,
        },
      });
    } else if (event !== null && type === 'portfolio') {
      if (
        this.auth.folios &&
        event.target.files &&
        this.auth.folios.length + event.target.files.length >
          this.auth.featureFlags.portfoliosLimit
      ) {
        this.snackBar.open(
          'Number of portfolios is limited to ' +
            this.auth.featureFlags.portfoliosLimit +
            '.',
          'OK',
          { duration: 5000 },
        );
      } else {
        if (event.target.files && event.target.files[0]) {
          for (let i = 0; i < event.target.files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const result = JSON.parse(e.target.result);
              if (
                result.content &&
                result.content.length >
                  this.auth.featureFlags.entitiesPerPortfoliosLimit
              ) {
                this.snackBar.open(
                  'Number of entities per portfolio is limited to ' +
                    this.auth.featureFlags.entitiesPerPortfoliosLimit +
                    '.',
                  'OK',
                  { duration: 5000 },
                );
              } else {
                this.auth.newFolio(result.name).subscribe((result2) => {
                  this.auth
                    .addEntitiesToPortfolios(result.content, [result2.id])
                    .subscribe(() => {
                      this.router.navigate([], {
                        queryParams: {
                          id: result2.id,
                          update: Math.random(),
                        },
                        queryParamsHandling: 'merge',
                      });
                    });
                });
              }
            };
            reader.readAsText(event.target.files[i]);
          }
        }
      }
    } else if (event !== null && type === 'portfolioDrop') {
      if (
        this.auth.folios &&
        event.dataTransfer.files &&
        this.auth.folios.length + event.dataTransfer.files.length >
          this.auth.featureFlags.portfoliosLimit
      ) {
        this.snackBar.open(
          'Number of portfolios is limited to ' +
            this.auth.featureFlags.portfoliosLimit +
            '.',
          'OK',
          { duration: 5000 },
        );
      } else {
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
          for (let i = 0; i < event.dataTransfer.files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const result = JSON.parse(e.target.result);
              if (
                result.content &&
                result.content.length >
                  this.auth.featureFlags.entitiesPerPortfoliosLimit
              ) {
                this.snackBar.open(
                  'Number of entities per portfolio is limited to ' +
                    this.auth.featureFlags.entitiesPerPortfoliosLimit +
                    '.',
                  'OK',
                  { duration: 5000 },
                );
              } else {
                this.auth.newFolio(result.name).subscribe((result2) => {
                  this.auth
                    .addEntitiesToPortfolios(result.content, [result2.id])
                    .subscribe(() => {
                      this.router.navigate([], {
                        queryParams: {
                          id: result2.id,
                          update: Math.random(),
                        },
                        queryParamsHandling: 'merge',
                      });
                    });
                });
              }
            };
            reader.readAsText(event.dataTransfer.files[i]);
          }
        }
      }
    } else if (event !== null && type === 'collection') {
      if (
        this.auth.querys &&
        event.target.files &&
        this.auth.querys.length + event.target.files.length >
          this.auth.featureFlags.collectionsLimit
      ) {
        this.snackBar.open(
          'Number of collections is limited to ' +
            this.auth.featureFlags.collectionsLimit +
            '.',
          'OK',
          { duration: 5000 },
        );
      } else {
        if (event.target.files && event.target.files[0]) {
          for (let i = 0; i < event.target.files.length; i++) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const result = JSON.parse(e.target.result);
              if (
                result.content &&
                result.content.length >
                  this.auth.featureFlags.queriesPerCollectionsLimit
              ) {
                this.snackBar.open(
                  'Number of queries per collection is limited to ' +
                    this.auth.featureFlags.queriesPerCollectionsLimit +
                    '.',
                  'OK',
                  { duration: 5000 },
                );
              } else {
                this.auth.addGroupQuery(result.name).subscribe((result2) => {
                  const queriesArray = [];
                  result.content.forEach((query) => {
                    queriesArray.push(
                      Object.assign({}, query, {
                        groups: [result2.id],
                      }),
                    );
                  });
                  this.auth.addQueries(queriesArray).subscribe(() => {
                    this.router.navigate([], {
                      queryParams: {
                        id: result2.id,
                        groupId: result2.id,
                        update: Math.random(),
                      },
                      queryParamsHandling: 'merge',
                    });
                  });
                });
              }
            };
            reader.readAsText(event.target.files[i]);
          }
        }
      }
    }
  }

  addCollection() {
    if (
      this.auth.querys &&
      this.auth.querys.length >= this.auth.featureFlags.collectionsLimit
    ) {
      this.snackBar.open(
        'Number of collections is limited to ' +
          this.auth.featureFlags.collectionsLimit +
          '.',
        'OK',
        { duration: 5000 },
      );
    } else {
      this.dialog.open(QueryNameComponent, {
        // data: {}
      });
    }
  }

  checkCurrentPortfolioIndex(item, allItems) {
    const currentPortfolioId = this.params.portfolioId
      ? this.params.portfolioId
      : this.params.id;
    let current;
    let previous;
    allItems.forEach((el, index) => {
      if (currentPortfolioId === el.uid) {
        current = el.uid;
        if (index > 0) {
          previous = allItems[index - 1].uid;
        }
      }
    });
    if (current === item) {
      return true;
    } else if (previous && previous === item) {
      return true;
    } else {
      return false;
    }
  }

  checkCurrentCollectionIndex(item, allItems) {
    const currentCollectionId = this.params.groupId;
    let current;
    let previous;
    allItems.forEach((el, index) => {
      if (currentCollectionId === el.uid) {
        current = el.uid;
        if (index > 0) {
          previous = allItems[index - 1].uid;
        }
      }
    });
    if (current === item) {
      return true;
    } else if (previous && previous === item) {
      return true;
    } else {
      return false;
    }
  }

  iManage() {
    this.router.navigate([], {
      queryParams: {
        id: 'default',
        groupId: 'default',
        defaultsort: 'abc',
        queryid: null,
        newsfeedFromQuery: null,
      },
      queryParamsHandling: 'merge',
      relativeTo: this.route,
    });
  }
}
