/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-query-name',
  templateUrl: './query-name.component.html',
  styleUrls: ['./query-name.component.scss'],
})
export class QueryNameComponent {
  namefolio: UntypedFormControl;
  namequery: UntypedFormControl;
  deleteQueries: boolean;
  collectionsList = [];
  selectedCollections = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
    public snackBar: MatSnackBar,
  ) {
    this.auth.getGroupsQuery().subscribe((result) => {
      this.collectionsList = result;
    });

    this.namefolio = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);

    this.namequery = new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3),
    ]);

    if (
      this.data &&
      this.data.action !== 'create' &&
      this.data.action !== 'add'
    ) {
      this.namefolio.setValue(this.auth.query.name);
    }
  }

  iSave() {
    if (this.namefolio.valid) {
      if (this.data) {
        this.auth.editGroupQuery(this.namefolio.value).subscribe(() => {
          this.dialog.closeAll();
          this.router.navigate([], {
            queryParams: {
              update: Math.random(),
            },
            queryParamsHandling: 'merge',
          });
        });
      } else {
        if (
          this.collectionsList.length < this.auth.featureFlags.collectionsLimit
        ) {
          this.auth.addGroupQuery(this.namefolio.value).subscribe((result) => {
            this.dialog.closeAll();
            this.router.navigate([], {
              queryParams: {
                id: result.id,
                groupId: result.id,
                update: Math.random(),
              },
              queryParamsHandling: 'merge',
            });
          });
        } else {
          this.snackBar.open(
            'Number of collections is limited to ' +
              this.auth.featureFlags.collectionsLimit +
              '.',
            'OK',
            { duration: 5000 },
          );
        }
      }
    }
  }

  iDelete() {
    if (this.deleteQueries) {
      const requestList = [];
      const queriesIds = this.auth.iquery.map((query) => query.uid);
      const request = this.auth.delQueries(queriesIds);
      requestList.push(request);
      requestList.push(this.auth.deleteGroupQuery());
      forkJoin(requestList).subscribe(() => {
        this.dialog.closeAll();
        this.router.navigate([], {
          queryParams: {
            id: 'default',
            groupId: 'default',
            update: Math.random(),
          },
          queryParamsHandling: 'merge',
        });
      });
    } else {
      this.auth.deleteGroupQuery().subscribe(() => {
        this.dialog.closeAll();
        this.router.navigate([], {
          queryParams: {
            id: 'default',
            groupId: 'default',
            update: Math.random(),
          },
          queryParamsHandling: 'merge',
        });
      });
    }
  }

  /**
   * create portfolio with provided entity
   */
  iCreate(openCollection) {
    if (this.namefolio.valid && this.namequery.valid) {
      if (
        this.collectionsList.length < this.auth.featureFlags.collectionsLimit
      ) {
        this.auth.addGroupQuery(this.namefolio.value).subscribe((result) => {
          const data = {
            groups: [result.id],
            name: this.namequery.value,
            query: this.auth.query.query,
          };
          this.auth.addQuery(data).subscribe(() => {
            this.dialog.closeAll();
            this.snackBar.open(
              'Collection ' + this.namefolio.value + ' created.',
              'OK',
              { duration: 5000 },
            );
            if (openCollection) {
              this.router.navigate(['/cockpit/news/query'], {
                queryParams: {
                  id: result.id,
                  groupId: result.id,
                  update: Math.random(),
                },
                queryParamsHandling: 'merge',
              });
            }
          });
        });
      } else {
        this.snackBar.open(
          'Number of collections is limited to ' +
            this.auth.featureFlags.collectionsLimit +
            '.',
          'OK',
          { duration: 5000 },
        );
      }
    }
  }

  /**
   * add query to selected collections
   */
  iAdd(openCollection) {
    if (this.namequery.valid && this.selectedCollections.length > 0) {
      const data = {
        groups: this.selectedCollections,
        name: this.namequery.value,
        query: this.auth.query.query,
      };
      this.auth.addQuery(data).subscribe(() => {
        this.dialog.closeAll();
        this.snackBar.open('Query added to selected collections.', 'OK', {
          duration: 5000,
        });
        if (openCollection && this.selectedCollections.length === 1) {
          this.router.navigate(['/cockpit/news/query'], {
            queryParams: {
              id: this.selectedCollections[0],
              groupId: this.selectedCollections[0],
              update: Math.random(),
            },
            queryParamsHandling: 'merge',
          });
        }
      });
    }
  }
}
