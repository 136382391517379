<div class="main-news">
  <div class="main-news-aside">
    <div class="main-news-aside-inner">
      <div class="main-news-top" *ngIf="!routing.isNetwork()">
        <div
          class="collections-header"
          *ngIf="
            routing.isFolio() &&
            !params.portfolioId &&
            !(screenSize$ | async)?.isMobile
          "
        >
          <div
            *ngIf="
              (auth.featureFlags.createPortfolios ||
                auth.featureFlags.importPortfolios) &&
              (routing.isMain() || routing.isScanner())
            "
            class="edit-button"
            (dragover)="onDragOver($event)"
            (drop)="onDropSuccess($event)"
          >
            <mat-icon
              matTooltip="Add Portfolio"
              [matMenuTriggerFor]="menuPortfolioAdd"
              >add</mat-icon
            >
            <mat-menu #menuPortfolioAdd="matMenu">
              <button
                *ngIf="auth.featureFlags.createPortfolios"
                mat-menu-item
                (click)="addPortfolio()"
              >
                Create
              </button>
              <button mat-menu-item [matMenuTriggerFor]="menuImportPortfolio">
                Import
              </button>
            </mat-menu>
            <mat-menu #menuImportPortfolio="matMenu">
              <button
                mat-menu-item
                *ngIf="!auth.folio"
                (click)="loadSamplePortfolio()"
              >
                Pre-load Example Portfolio
              </button>
              <button
                mat-menu-item
                *ngIf="auth.featureFlags.createPortfolios"
                (click)="iImport(null, 'isinsCreate')"
              >
                ISINs list
              </button>
              <button
                mat-menu-item
                *ngIf="auth.featureFlags.importPortfolios"
                (click)="fileInputPortfolio.click()"
              >
                Portfolios (from files)
              </button>
              <input
                #fileInputPortfolio
                class="inptfile"
                type="file"
                accept=".json"
                value=""
                (click)="fileInputPortfolio.value = null"
                (change)="iImport($event, 'portfolio')"
                multiple
              />
            </mat-menu>
          </div>
          <nav mat-tab-nav-bar *ngIf="routing.isFolio()">
            <a
              mat-tab-link
              *ngFor="let folio of portfolios | orderBy: 'name'"
              [active]="folio.uid === params.id"
              (click)="inTabFolio(folio.uid)"
            >
              <div class="tab-name" [matTooltip]="folio.name">
                <span>{{ folio.name }}</span>
              </div>
              <div
                class="tab-info"
                *ngIf="
                  ['sentiment'].includes(auth.scorelabSettings.defaultScore)
                "
                [style.color]="
                  folio.uid !== params.id
                    ? 'transparent'
                    : ((folio.sentiment ? folio.sentiment.sentiment : undefined)
                      | color)
                "
              >
                <ng-container
                  *ngIf="folio.uid === params.id && folio.sentiment"
                >
                  <!--<span>&nbsp;-&nbsp;</span>-->
                  <span>{{
                    (folio.sentiment ? folio.sentiment.sentiment : undefined)
                      | percent
                  }}</span>
                  <span>&nbsp;</span>
                  <span class="brak">(</span>
                  <span>{{
                    (folio.sentiment
                      ? folio.sentiment.sentiment_delta * 100
                      : undefined
                    )
                      | round
                      | trend
                  }}</span>
                  <span>{{
                    (folio.sentiment
                      ? folio.sentiment.sentiment_delta * 100
                      : undefined
                    )
                      | round
                      | negative
                  }}</span>
                  <span class="brak">)</span>
                </ng-container>
              </div>
              <div
                class="tab-info"
                *ngIf="
                  !['sentiment'].includes(auth.scorelabSettings.defaultScore)
                "
                [style.color]="
                  folio.uid !== params.id
                    ? 'transparent'
                    : auth.getScoreType() === 'pos'
                      ? (folio.score | colorScore2)
                      : (folio.score | colorScore)
                "
              >
                <ng-container *ngIf="folio.uid === params.id && folio.score">
                  <!--<span>&nbsp;-&nbsp;</span>-->
                  <span>{{ folio.score }}</span>
                </ng-container>
              </div>
              <div
                class="tab-edit"
                *ngIf="!folio.sharing_mode && !folio.is_aggregated"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="Edit Portfolio"
              >
                <mat-icon *ngIf="folio.uid === params.id">create</mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.sharing_mode === 'read' && !folio.is_aggregated"
                matTooltip="Shared Portfolio (READ access only)"
              >
                <mat-icon svgIcon="folder-lock"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.sharing_mode === 'write' && !folio.is_aggregated"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="{{
                  folio.uid === params.id
                    ? 'Edit Shared Portfolio (WRITE access)'
                    : 'Shared Portfolio (WRITE access)'
                }}"
              >
                <mat-icon svgIcon="folder-edit"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.sharing_mode === 'admin' && !folio.is_aggregated"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="{{
                  folio.uid === params.id
                    ? 'Edit Shared Portfolio (ADMIN access)'
                    : 'Shared Portfolio (ADMIN access)'
                }}"
              >
                <mat-icon svgIcon="folder-cog"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.is_aggregated && !folio.sharing_mode"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="{{
                  folio.uid === params.id
                    ? 'Edit Aggregated Portfolio'
                    : 'Aggregated Portfolio'
                }}"
              >
                <mat-icon svgIcon="folder-network"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.is_aggregated && folio.sharing_mode"
                matTooltip="Shared Aggregated Portfolio (non-editable)"
              >
                <mat-icon svgIcon="folder-key-network"></mat-icon>
              </div>
              <span
                class="tab-divider"
                [class.tab-divider-transparent]="
                  checkCurrentPortfolioIndex(
                    folio.uid,
                    portfolios | orderBy: 'name'
                  )
                "
              ></span>
            </a>
          </nav>
        </div>

        <div
          class="collections-header"
          *ngIf="
            routing.isFolio() &&
            params.portfolioId &&
            !(screenSize$ | async)?.isMobile
          "
        >
          <div
            *ngIf="auth.featureFlags.createPortfolios && routing.isMain()"
            class="edit-button"
            (click)="$event.stopPropagation(); addPortfolio()"
            matTooltip="Add Portfolio"
          >
            <mat-icon>add</mat-icon>
          </div>
          <nav mat-tab-nav-bar *ngIf="routing.isFolio()">
            <a
              mat-tab-link
              *ngFor="let folio of portfolios | orderBy: 'name'"
              [active]="folio.uid === params.portfolioId"
              (click)="inTabFolio(folio.uid)"
            >
              <div class="tab-name" [matTooltip]="folio.name">
                <span>{{ folio.name }}</span>
              </div>
              <div
                class="tab-info"
                *ngIf="
                  ['sentiment'].includes(auth.scorelabSettings.defaultScore)
                "
                [style.color]="
                  folio.uid !== params.portfolioId
                    ? 'transparent'
                    : ((folio.sentiment ? folio.sentiment.sentiment : undefined)
                      | color)
                "
              >
                <ng-container
                  *ngIf="folio.uid === params.portfolioId && folio.sentiment"
                >
                  <!--<span>&nbsp;-&nbsp;</span>-->
                  <span>{{
                    (folio.sentiment ? folio.sentiment.sentiment : undefined)
                      | percent
                  }}</span>
                  <span>&nbsp;</span>
                  <span class="brak">(</span>
                  <span>{{
                    (folio.sentiment
                      ? folio.sentiment.sentiment_delta * 100
                      : undefined
                    )
                      | round
                      | trend
                  }}</span>
                  <span>{{
                    (folio.sentiment
                      ? folio.sentiment.sentiment_delta * 100
                      : undefined
                    )
                      | round
                      | negative
                  }}</span>
                  <span class="brak">)</span>
                </ng-container>
              </div>
              <div
                class="tab-info"
                *ngIf="
                  !['sentiment'].includes(auth.scorelabSettings.defaultScore)
                "
                [style.color]="
                  folio.uid !== params.portfolioId
                    ? 'transparent'
                    : auth.getScoreType() === 'pos'
                      ? (folio.score | colorScore2)
                      : (folio.score | colorScore)
                "
              >
                <ng-container
                  *ngIf="folio.uid === params.portfolioId && folio.score"
                >
                  <!--<span>&nbsp;-&nbsp;</span>-->
                  <span>{{ folio.score }}</span>
                </ng-container>
              </div>
              <div
                class="tab-edit"
                *ngIf="!folio.sharing_mode && !folio.is_aggregated"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="Edit Portfolio"
              >
                <mat-icon *ngIf="folio.uid === params.portfolioId"
                  >create</mat-icon
                >
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.sharing_mode === 'read' && !folio.is_aggregated"
                matTooltip="Shared Portfolio (READ access only)"
              >
                <mat-icon svgIcon="folder-lock"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.sharing_mode === 'write' && !folio.is_aggregated"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="{{
                  folio.uid === params.portfolioId
                    ? 'Edit Shared Portfolio (WRITE access)'
                    : 'Shared Portfolio (WRITE access)'
                }}"
              >
                <mat-icon svgIcon="folder-edit"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.sharing_mode === 'admin' && !folio.is_aggregated"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="{{
                  folio.uid === params.portfolioId
                    ? 'Edit Shared Portfolio (ADMIN access)'
                    : 'Shared Portfolio (ADMIN access)'
                }}"
              >
                <mat-icon svgIcon="folder-cog"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.is_aggregated && !folio.sharing_mode"
                (click)="$event.stopPropagation(); editPortfolio(folio.uid)"
                matTooltip="{{
                  folio.uid === params.portfolioId
                    ? 'Edit Aggregated Portfolio'
                    : 'Aggregated Portfolio'
                }}"
              >
                <mat-icon svgIcon="folder-network"></mat-icon>
              </div>
              <div
                class="tab-edit"
                *ngIf="folio.is_aggregated && folio.sharing_mode"
                matTooltip="Shared Aggregated Portfolio (non-editable)"
              >
                <mat-icon svgIcon="folder-key-network"></mat-icon>
              </div>
              <span
                class="tab-divider"
                [class.tab-divider-transparent]="
                  checkCurrentPortfolioIndex(
                    folio.uid,
                    portfolios | orderBy: 'name'
                  )
                "
              ></span>
            </a>
          </nav>
        </div>

        <div
          class="collections-header"
          *ngIf="routing.isQuery() && !(screenSize$ | async)?.isMobile"
        >
          <div
            *ngIf="
              (auth.featureFlags.createCollections ||
                auth.featureFlags.importCollections) &&
              routing.isMain()
            "
            class="edit-button"
          >
            <mat-icon
              matTooltip="Add Collection"
              [matMenuTriggerFor]="menuCollectionAdd"
              >add</mat-icon
            >
            <mat-menu #menuCollectionAdd="matMenu">
              <button
                *ngIf="auth.featureFlags.createCollections"
                mat-menu-item
                (click)="addCollection()"
              >
                Create
              </button>
              <button mat-menu-item [matMenuTriggerFor]="menuImportCollection">
                Import
              </button>
            </mat-menu>
            <mat-menu #menuImportCollection="matMenu">
              <button
                mat-menu-item
                *ngIf="
                  params.id === 'default' &&
                  (!auth.querys || auth.querys.length === 0)
                "
                (click)="loadSampleCollections()"
              >
                Pre-load Sample Collections
              </button>
              <button
                mat-menu-item
                *ngIf="auth.featureFlags.importCollections"
                (click)="fileInputCollection.click()"
              >
                Collections (from files)
              </button>
              <input
                #fileInputCollection
                class="inptfile"
                type="file"
                accept=".json"
                value=""
                (click)="fileInputCollection.value = null"
                (change)="iImport($event, 'collection')"
                multiple
              />
            </mat-menu>
          </div>
          <nav mat-tab-nav-bar>
            <a
              mat-tab-link
              *ngFor="let tab of collections | orderBy: 'name'"
              [active]="tab.uid === params.groupId"
              (click)="inTabQuery(tab.uid)"
            >
              <div class="tab-name" [matTooltip]="tab.name">
                <span>{{ tab.name }}</span>
              </div>
              <div
                class="tab-info"
                [style.color]="
                  tab.uid !== params.groupId
                    ? 'transparent'
                    : ((tab.sentiment ? tab.sentiment.sentiment : undefined)
                      | color)
                "
              >
                <ng-container
                  *ngIf="tab.uid === params.groupId && tab.sentiment"
                >
                  <!--<span>&nbsp;-&nbsp;</span>-->
                  <span>{{
                    (tab.sentiment ? tab.sentiment.sentiment : undefined)
                      | percent
                  }}</span>
                  <span>&nbsp;</span>
                  <span class="brak">(</span>
                  <span>{{
                    (tab.sentiment
                      ? tab.sentiment.sentiment_delta * 100
                      : undefined
                    )
                      | round
                      | trend
                  }}</span>
                  <span>{{
                    (tab.sentiment
                      ? tab.sentiment.sentiment_delta * 100
                      : undefined
                    )
                      | round
                      | negative
                  }}</span>
                  <span class="brak">)</span>
                </ng-container>
              </div>
              <div
                class="tab-edit"
                (click)="$event.stopPropagation(); editCollection()"
                matTooltip="Edit Collection"
              >
                <mat-icon *ngIf="tab.uid === params.groupId">create</mat-icon>
              </div>
              <span
                class="tab-divider"
                [class.tab-divider-transparent]="
                  checkCurrentCollectionIndex(
                    tab.uid,
                    collections | orderBy: 'name'
                  )
                "
              ></span>
            </a>
          </nav>
          <div class="manage-queries-container" *ngIf="routing.isMain()">
            <div class="manage-queries-inner-container">
              <div
                class="manage-queries-button"
                (click)="iManage()"
                [class.active-management]="params.id === 'default'"
              >
                Manage queries
              </div>
            </div>
          </div>
        </div>

        <div
          class="collections-header"
          *ngIf="(screenSize$ | async)?.isMobile && routing.isFolio()"
        >
          <mat-form-field appearance="fill">
            <mat-label>Portfolio</mat-label>
            <mat-select
              [(value)]="selectedPortfolio"
              (selectionChange)="inTabFolio($event.value)"
            >
              <mat-option
                *ngFor="let folio of portfolios | orderBy: 'name'"
                [value]="folio.uid"
                >{{ folio.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="main-news-bottom">
        <div
          class="asidenav"
          [class.mobile]="(screenSize$ | async)?.isMobile"
          *ngIf="
            !(routing.isFolio() && !params.id) &&
            !(routing.isQuery() && params.id === 'default') &&
            !routing.isScanner() &&
            !(routing.isChart() && (screenSize$ | async)?.isMobile)
          "
          [class.inblur]="params.tutorial"
          [class.noblur]="
            params.tutorial === 'newsfeed' ||
            params.tutorial === 'newsviewfeeds'
          "
          [class.hide]="routing.isHot()"
          [class.audit]="routing.isNewsaudit()"
          [class.market]="routing.isMarket()"
        >
          <app-news-newsfeed></app-news-newsfeed>
        </div>
        <div
          class="main-news-cont"
          *ngIf="!(routing.isNewsaudit() && (screenSize$ | async)?.isMobile)"
          [class.inblur]="params.tutorial"
          [class.noblur]="noBlur(params.tutorial)"
          [class.audit]="routing.isNewsaudit()"
        >
          <div class="cont-abso">
            <div
              class="main-news-cont-top"
              [class.benchmark]="
                (routing.isFolio() && routing.isChart2()) ||
                (routing.isQuery() && routing.isChart2()) ||
                (routing.isSearch() && routing.isChart2())
              "
            >
              <div
                *ngIf="!((screenSize$ | async)?.isMobile && routing.isFeed())"
                class="router-outlet"
              >
                <router-outlet></router-outlet>
              </div>
            </div>

            <div
              class="main-news-cont-bot"
              [class.benchmark]="
                (routing.isFolio() && routing.isChart2()) ||
                (routing.isQuery() && routing.isChart2()) ||
                (routing.isSearch() && routing.isChart2())
              "
              *ngIf="
                (routing.isFolio() && routing.isChart2()) ||
                (routing.isQuery() && routing.isChart2()) ||
                (routing.isSearch() && routing.isChart2())
              "
            >
              <app-chartmap-company></app-chartmap-company>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      !(routing.isFolio() && !params.id) &&
      !routing.isHot() &&
      !(screenSize$ | async)?.isMobile &&
      !routing.isNetwork()
    "
    class="main-news-bside"
    [class.inblur]="params.tutorial"
    [class.noblur]="
      params.tutorial === 'menubside' ||
      params.tutorial === 'trending2' ||
      params.tutorial === 'dataoptionhome'
    "
  >
    <app-menu-bside></app-menu-bside>
  </div>
</div>
<ng-template #loading>
  <div class="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>
