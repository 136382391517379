import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, shareReplay, map } from 'rxjs/operators';

export interface ScreenSize {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isLargeDesktop: boolean;
  currentSize: 'XS' | 'SM' | 'MD' | 'LG' | 'XL';
}

@Injectable({
  providedIn: 'root',
})
export class BreakpointService {
  private readonly breakpoints = {
    XS: '(max-width: 575.98px)',
    SM: '(min-width: 576px) and (max-width: 767.98px)',
    MD: '(min-width: 768px) and (max-width: 991.98px)',
    LG: '(min-width: 992px) and (max-width: 1199.98px)',
    XL: '(min-width: 1200px)',
  };

  readonly screenSize$: Observable<ScreenSize>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.screenSize$ = this.breakpointObserver
      .observe(Object.values(this.breakpoints))
      .pipe(
        map(this.getScreenSize),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        shareReplay(1),
      );
  }

  private getScreenSize(breakpointState: BreakpointState): ScreenSize {
    const { breakpoints } = breakpointState;

    const size: ScreenSize = {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      isLargeDesktop: false,
      currentSize: 'MD',
    };

    if (breakpoints['(max-width: 575.98px)']) {
      size.isMobile = true;
      size.currentSize = 'XS';
    } else if (breakpoints['(min-width: 576px) and (max-width: 767.98px)']) {
      size.isMobile = true;
      size.currentSize = 'SM';
    } else if (breakpoints['(min-width: 768px) and (max-width: 991.98px)']) {
      size.isTablet = true;
      size.currentSize = 'MD';
    } else if (breakpoints['(min-width: 992px) and (max-width: 1199.98px)']) {
      size.isDesktop = true;
      size.currentSize = 'LG';
    } else {
      size.isLargeDesktop = true;
      size.currentSize = 'XL';
    }

    return size;
  }
}
