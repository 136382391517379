import { Component } from '@angular/core';
import { ChatDrawerService } from '../../services/chat-drawer-service/chat-drawer-service';
import { ChatNavigationService } from '../../services/chat-navigation-service/chat-navigation-service';

@Component({
  selector: 'app-chat-mobile-header',
  templateUrl: './chat-mobile-header.component.html',
  styleUrls: ['./chat-mobile-header.component.scss'],
})
export class ChatMobileHeaderComponent {
  constructor(
    private chatDrawerService: ChatDrawerService,
    private chatNavigationService: ChatNavigationService,
  ) {}

  chatNavigationMenuOpen() {
    this.chatDrawerService.openDrawer();
  }

  startNewChat() {
    this.chatNavigationService.startNewChat();
  }
}
