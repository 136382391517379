/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from '../guard/auth.guard';
import { NewsGuard } from '../guard/news.guard';
import { TrendGuard } from '../guard/trend.guard';
import * as moment from 'moment';

@Injectable()
export class ConfigService {
  configURL = 'assets/config/config.json';
  appConfig: any;

  constructor(private http: HttpClient) {
    if (window.Cypress) {
      console.log('ConfigService running Cypress');
      window.ConfigService = this;
    }
  }

  loadAppConfig() {
    return this.http
      .get(this.configURL)
      .toPromise()
      .then((data) => {
        // enabling longer data history for admins on prod
        // let startDate = null;
        // const token = localStorage.getItem('token');
        // if (token) {
        //   const user = jwt_decode(token);
        //   if (user.roles.includes('ADMIN') && (data['environment'] === 'prod')) {
        //     startDate = '2015-01-01';
        //   }
        // }

        const startDate = moment()
          .subtract(data['dataRangeInDays'], 'days')
          .format('YYYY-MM-DD');

        const noTrackingBool =
          data['noTracking'] === 'true' || data['noTracking'] === true;
        const reportsDisabledBool =
          data['reportsDisabled'] === 'true' ||
          data['reportsDisabled'] === true;

        this.appConfig = {
          version: data['version'],
          environment: data['environment'],
          production: data['production'],
          urlDataApi: data['urlDataApi'],
          urlMetadataApi: data['urlMetadataApi'],
          urlCustomerApi: data['urlCustomerApi'],
          urlQuantApi: data['urlQuantApi'],
          urlChatLLMApi: data['urlChatLLMApi'],
          urlNewsletterApi: data['urlNewsletterApi'],
          urlReportingServiceApi: data['urlReportingServiceApi'],
          urlAlertingServiceApi: data['urlAlertingServiceApi'],
          urlSentry: data['urlSentry'],
          urlLoginRedirect: data['urlLoginRedirect'],
          routing: {
            name: data['routing']['name'],
            theme: data['routing']['theme'],
            apiproj: data['routing']['apiproj'],
            authpro: data['routing']['authpro'],
            project: data['routing']['project'],
          },
          setup: {
            // startDate: startDate ? startDate : data['startDate']
            startDate: startDate,
            noTracking: noTrackingBool,
            reportsDisabled: reportsDisabledBool,
          },
        };

        if (this.isLocalStorageAvailable()) {
          this.appConfig.setup.useStorage = true;
        } else {
          this.appConfig.setup.useStorage = false;
        }

        this.appConfig.guards = {
          project: [],
          cockpit: [AuthGuard],
          news: [NewsGuard],
          trend: [TrendGuard],
          mobile: [AuthGuard],
          iframe: [AuthGuard],
        };
      });
  }

  isLocalStorageAvailable() {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  }
}
