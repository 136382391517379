/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChatNavigationService } from '../services/chat-navigation-service/chat-navigation-service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { ChatParentStateService } from '../services/chat-parent-state.service';
@Component({
  selector: 'app-chat-navigation',
  templateUrl: './chat-navigation.component.html',
  styleUrls: ['./chat-navigation.component.scss'],
})
export class ChatNavigationComponent implements OnInit, OnDestroy {
  // chat history
  chats: any[] = [];
  defaultTitle: string = 'New Chat';
  activeChatId: string | null = null;
  form: FormGroup;
  editMode: { [key: string]: boolean } = {};
  originalTitles: { [key: string]: string } = {};

  private chatListSubscription?: Subscription;
  private routeSubscription?: Subscription;
  private destroy$ = new Subject<void>();
  isLoading: boolean;
  initialLoadComplete: any;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private chatNavigationService: ChatNavigationService,
    private chatParentStateService: ChatParentStateService,
  ) {
    this.form = this.fb.group({
      title: [null, Validators.required],
    });

    this.chatNavigationService.chatList$.subscribe({
      next: (data) => {
        this.chats = data;
      },
    });

    combineLatest([
      this.chatParentStateService.parentChatId$,
      this.chatParentStateService.parentChatTitle$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([id, title]) => !!id && !!title),
      )
      .subscribe(([chatId, chatTitle]) => {
        this.chatNavigationService.updateChatTitleIfDefaultTitle(
          chatId,
          chatTitle,
        );
      });
  }

  ngOnInit(): void {
    this.getCharts();

    this.routeSubscription = this.route.queryParams
      .pipe(filter((params) => params['chatId']))
      .subscribe((params) => {
        this.activeChatId = params['chatId'];
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.routeSubscription?.unsubscribe();
    this.chatListSubscription?.unsubscribe();
  }

  getCharts(): void {
    this.chatNavigationService.fetchChats().subscribe({
      next: (data) => {
        this.chats = data;

        this.redirectToFirstChatOrStartNew();
        setTimeout(() => {
          const target = document.getElementById('llmContainer');
          target.scrollTo(0, target.scrollHeight);
        }, 500);
      },
    });
  }

  // redirecting url into first chat or start a new chat if no chats
  redirectToFirstChatOrStartNew(): void {
    if (this.chats && this.chats.length > 0) {
      if (!this.activeChatId) {
        const firstChatId = this.chats[0].chat_id;
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: { chatId: firstChatId },
            queryParamsHandling: 'merge',
          })
          .then(() => {
            this.activeChatId = firstChatId;
          })
          .catch((err) => {
            console.error('Navigation error:', err);
          });
      }
    } else {
      this.startNewChat();
    }
  }

  // Onclick method when clicking on a chatId
  goToChatConversation(chat: any): void {
    if (chat && chat.chat_id) {
      this.chatNavigationService.goToChat(chat.chat_id);
    } else {
      console.error('chat id is undefined or chat object is invalid', chat);
    }
  }

  // New Chat button onclick method
  startNewChat(): void {
    this.chatNavigationService.startNewChat();
  }

  // edit chatId title
  editChat(chatId: string, event: Event): void {
    event.stopPropagation();
    const chat = this.chats.find((chat) => chat.chat_id === chatId);
    if (chat) {
      this.originalTitles[chatId] = chat.title;
      this.editMode[chatId] = true;
    }
  }

  saveChatTitle(chat: any): void {
    this.chatNavigationService.editChatTitle(chat.chat_id, chat.title);
    this.editMode[chat.chat_id] = false;
  }

  // cancel editing chatId title
  abortEditChat(chat: any): void {
    this.editMode[chat.chat_id] = false;
    chat.title = this.originalTitles[chat.chat_id];
  }

  // delete a chatId
  deleteChat(chatId: string): void {
    if (chatId) {
      this.auth.deleteChat(chatId).subscribe({
        next: () => {
          this.getChatsAfterDeletion(chatId);
        },
        error: () => {
          console.error('There was an error deleting the chat');
        },
      });
    }
  }

  // refreshing new list of chats after changes
  getChatsAfterDeletion(deletedChatId: string): void {
    this.chatNavigationService.fetchChats().subscribe({
      next: (data) => {
        this.chats = data;
        if (this.activeChatId === deletedChatId) {
          this.activeChatId = null;
          this.redirectToFirstChatOrStartNew();
        }
      },
      error: (err) => console.error(err),
    });
  }

  onMouseEnter(preset) {
    preset.visible = true;
  }

  onMouseLeave(preset) {
    preset.visible = false;
  }
}
