import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import * as jwt_decode from 'jwt-decode';
import { ConfigService } from '../service/config.service';

/**
 * This guard check if you are registered or if you was logged and your token is not expired
 */
@Injectable()
export class AuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
    public config: ConfigService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const params = state.root.queryParams;
    const token = state.root.queryParams.jwt;
    const redirectURL = decodeURIComponent(state.root.queryParams.redirectURL);
    if (params.impersonate) {
      let email = 'true';
      if (token) {
        const user = jwt_decode(token);
        email = user.email;
      } else if (this.auth.userdata) {
        email = this.auth.userdata.email;
      }
      if (this.config.appConfig.setup.useStorage) {
        localStorage.setItem('impersonate', email);
      }
      this.router.navigate([state.url.split('?')[0]], {
        queryParams: Object.assign({}, state.root.queryParams, {
          impersonate: null,
        }),
        queryParamsHandling: 'merge',
      });
      return false;
    } else if (params.score) {
      this.router.navigate([state.url.split('?')[0]], {
        queryParams: Object.assign({}, state.root.queryParams, {
          score: null,
          time: null,
        }),
        queryParamsHandling: 'merge',
      });
      return false;
    } else if (token || this.auth.jwtAuthorized === true) {
      if (this.auth.jwtAuthorized === true) {
        return true;
      } else {
        return this.auth.jwtAuth(token).pipe(
          mergeMap(() => this.auth.loadFeatureFlags(token)),
          map(() => true),
          catchError(() => {
            if (redirectURL) {
              window.location.href = redirectURL;
            } else {
              this.router.navigate(['/message/autherror']);
            }
            return of(false);
          }),
        );
      }
    } else {
      return this.auth.authLoged().pipe(
        mergeMap(() => {
          let mytoken;
          if (this.config.appConfig.setup.useStorage) {
            mytoken = localStorage.getItem('token');
          }
          if (mytoken) {
            return this.auth.loadFeatureFlags(mytoken);
          } else {
            return of(false);
          }
        }),
        map(() => {
          const user = this.auth.authToken();
          if (user) {
            if (
              user.roles?.includes('ADMIN') ||
              user.groups?.includes('ADMIN')
            ) {
              // return true;

              const date1 = new Date(user.exp * 1000);
              const date2 = new Date();
              if (date1 < date2) {
                this.router.navigate(['/login'], {
                  queryParams: { url: state.url },
                });
                return false;
              } else {
                return true;
              }
            } else {
              const date1 = new Date(user.exp * 1000);
              const date2 = new Date();
              if (date1 < date2) {
                this.router.navigate(['/login'], {
                  queryParams: { url: state.url },
                });
                return false;
              } else {
                return true;
              }
            }
          } else {
            this.router.navigate(['/login'], {
              queryParams: { url: state.url },
            });
            return false;
          }
        }),
        catchError((err) => {
          if (err === 'Unauthorized') {
            this.router.navigate(['/login'], {
              queryParams: { url: state.url },
            });
            return of(false);
          } else {
            return of(true);
          }
        }),
      );
    }
  }
}
