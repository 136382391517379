import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';

@Component({
  selector: 'app-related-questions',
  templateUrl: './chat-related-questions.component.html',
  styleUrls: ['./chat-related-questions.component.scss'],
})
export class RelatedQuestionsComponent {
  @Input() relatedQuestions: string[] = [];
  @Output() submitRelatedQuestion = new EventEmitter<string>();

  screenSize$ = this.breakpointService.screenSize$;

  constructor(private breakpointService: BreakpointService) {}

  onClickQuestionBox(question: string) {
    this.submitRelatedQuestion.emit(question);
  }
}
