import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AIMessage,
  ChatAIMessage,
  ChatMessage,
  SourceDoc,
} from '../interfaces/chart-details.interface';
import moment from 'moment';
import { YukkApi } from '../../../service/yukkapi.service';
import { ModalReadonlyStateService } from '../../../cockpit/main-news/news-newsfeed/news-view/news-view-readonly-state-service';
import { NewsViewComponent } from 'src/app/cockpit/main-news/news-newsfeed/news-view/news-view.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';
import { ChatParentStateService } from '../services/chat-parent-state.service';

@Component({
  selector: 'app-chat-message-container',
  templateUrl: './chat-message-container.component.html',
  styleUrls: ['./chat-message-container.component.scss'],
})
export class ChatMessageContainerComponent implements OnInit {
  @Input() chatDetails: (ChatMessage | ChatAIMessage)[] = [];
  @Input() loading: boolean = false;
  @Input() error: boolean = false;
  @Output() relatedQuestionSubmitted = new EventEmitter<string>();
  @Output() errorTriggered = new EventEmitter();

  userAvatar = 'YOU';
  llmAvatar = 'YUKKA';

  // closing or opening chat charts component and storing its state in localStorage
  chartVisible: boolean = true;

  screenSize$ = this.breakpointService.screenSize$;

  parentChatId: string = '';

  constructor(
    public snackBar: MatSnackBar,
    public auth: AuthService,
    public yukkApi: YukkApi,
    public dialog: MatDialog,
    private readonlyStateService: ModalReadonlyStateService,
    private breakpointService: BreakpointService,
    private chatParentStateService: ChatParentStateService,
  ) {}

  ngOnInit(): void {
    this.loadChartVisibility();
  }

  // prevents re-rendering sub-components
  trackByChatId(index, chat) {
    return chat.message_id;
  }
  /**
   * Checks if the given message is an instance of AIMessage.
   *
   * @param message - The message to check.
   * @returns True if the message is an instance of AIMessage, false otherwise.
   */
  isAIMessage(message: string | AIMessage): message is AIMessage {
    return (message as AIMessage).entityName !== undefined;
  }

  showTheAricleView(
    companyName: string,
    companyType: string,
    article: SourceDoc,
  ): void {
    const { id: articleId, publish_time, eventNames } = article;
    const publishedDate = publish_time.split('T')[0];
    const dateFrom = publishedDate;
    const dateTo = moment(dateFrom).add(1, 'days');
    const formattedDateStringTo = dateTo.format('YYYY-MM-DD');

    if (articleId) {
      this.openTheNewsDetailModal(
        companyName,
        companyType,
        articleId,
        dateFrom,
        formattedDateStringTo,
        eventNames,
      );
    } else {
      console.error('No articleId provided');
    }
  }

  navigateToCompany(companyName: string, companyType: string): void {
    const url = `/cockpit/news/market/chart?id=${companyName}&type=${companyType}`;
    window.open(url, '_blank');
  }

  private openTheNewsDetailModal(
    companyName: string,
    companyType: string,
    articleId: string,
    dateFrom: string,
    dateTo: string,
    eventNames?: string[],
  ): void {
    const params = {
      news: articleId,
      time: `${dateFrom}--${dateTo}`,
      type: companyType,
      id: companyName,
    };

    let newsContent;
    this.yukkApi.newsviewNew({ id: params.news, params: params }).subscribe(
      (result) => {
        newsContent = JSON.parse(JSON.stringify(result));
        if (eventNames.length > 0) {
          const extractTheEventInformation = newsContent.events.find(
            (event: { name: string; [key: string]: unknown }) =>
              event.name.trim() === eventNames[0].trim(),
          );
          if (extractTheEventInformation) {
            Object.assign(params, {
              eventid: extractTheEventInformation.id,
              eventype: extractTheEventInformation.type,
              newstype: 'Events',
            });
          }
        }
        this.readonlyStateService.setModalReadonly(true);
        this.dialog
          .open(NewsViewComponent, {
            autoFocus: false,
            panelClass: 'panelNews',
            disableClose: false,
            closeOnNavigation: true,
            data: {
              newsinfo: newsContent,
              params: params,
            },
          })
          .afterClosed()
          .subscribe(() => {
            this.readonlyStateService.setModalReadonly(false);
          });
      },
      () => {
        this.handleError();
      },
    );
  }

  chatFeedback(messageId: string, feedback: string) {
    const chatId = this.chatParentStateService.parentChatId;
    const chat = this.chatDetails.find(
      (chat: ChatMessage) => chat.message_id === messageId,
    );

    if (!chat) {
      console.log(`Chat with message_id ${messageId} not found`);
      return;
    }

    if (chat) {
      const feedbackValue = feedback === 'pos' ? 1 : -1;

      // check if feedback already exists
      if (((chat as ChatMessage).feedback ?? null) === feedbackValue) {
        this.auth.deleteChatFeedback(chatId, messageId).subscribe({
          next: () => {
            // console.log('Feedback deleted', res);
            (chat as ChatMessage).feedback = null; // set back to null
          },
          error: (error) => {
            console.log('Failed to delete feedback', error);
          },
        });
      } else {
        // Set feedback
        this.auth.postChatFeedback(chatId, messageId, feedback).subscribe({
          next: () => {
            // console.log('Feedback sent', res);
            (chat as ChatMessage).feedback = feedbackValue;
          },
          error: (error) => {
            console.log('Failed to send feedback', error);
            alert('Failed to send feedback, please try again');
          },
        });
      }
    }
  }

  postRelatedQuestions(question: string) {
    this.relatedQuestionSubmitted.emit(question);
  }

  // closing or opening chat charts component and storing its state in localStorage

  updateChartVisibility(isVisible: boolean) {
    this.chartVisible = isVisible;
    this.saveChartVisibility();
  }

  private handleError() {
    this.errorTriggered.emit();
    this.snackBar.open(
      'Aricle is not available, Something went wrong. Please try again later.',
      'OK',
      {
        duration: 10000,
      },
    );
  }

  private saveChartVisibility() {
    localStorage.setItem('chartVisible', JSON.stringify(this.chartVisible));
  }

  private loadChartVisibility() {
    const savedVisibility = localStorage.getItem('chartVisible');
    if (savedVisibility !== null) {
      this.chartVisible = JSON.parse(savedVisibility);
    }
  }

  shouldShowChatCharts(chatMessage: AIMessage): boolean {
    return (
      chatMessage.companyName &&
      chatMessage.companyName !== 'all' &&
      chatMessage.companyName !== '' &&
      chatMessage.recommended_charts?.length > 0 &&
      chatMessage.recommended_charts.every(
        (chart) => chart.score_type !== null,
      ) &&
      chatMessage.companyType === 'company' &&
      chatMessage.metadata.tool_name !== 'Elastic Search Based QA Tool'
    );
  }

  shouldShowOpenChartsIcon(chatMessage: AIMessage): boolean {
    return !this.chartVisible && this.shouldShowChatCharts(chatMessage);
  }
}
