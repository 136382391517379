<div
  class="menu-above"
  *ngIf="auth.featureFlags.showMenuTop"
  [class.inblur]="params.tutorial"
  [class.noblur]="params.tutorial === 'menuabove'"
>
  <div
    class="menu-above-left"
    [class.mobile]="(screenSize$ | async)?.isMobile"
    *ngIf="auth.featureFlags.showLogo"
  >
    <img
      *ngIf="routing.theme !== 'light'"
      class="navlogo"
      src="assets/logo/{{ logo }}_menu.svg"
      alt="yukkalab"
      (click)="inLogo()"
    />
    <img
      *ngIf="routing.theme === 'light'"
      class="navlogo"
      src="assets/logo/{{ logo }}_menu_light.svg"
      alt="yukkalab"
      (click)="inLogo()"
    />
  </div>
  <div class="menu-above-rigt">
    <div class="abovel">
      <app-menu-search [component]="'menuabove'"></app-menu-search>
    </div>
    <div class="abover" [class.mobile]="(screenSize$ | async)?.isMobile">
      <app-menu-options></app-menu-options>
    </div>
  </div>
</div>
