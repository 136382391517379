import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UserAccount } from 'src/models/user.types';
import { allRoles } from './user-role-options';
import { AuthService } from 'src/app/service/auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-user-profile',
  templateUrl: './menu-user-profile.component.html',
  styleUrls: ['./menu-user-profile.component.scss'],
})
export class MenuUserProfileComponent implements OnInit {
  rolesOption = allRoles;
  userInfo: UserAccount;
  userForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userInfo: UserAccount },
    private dialogRef: MatDialogRef<MenuUserProfileComponent>,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.userInfo = this.data.userInfo;
    this.userForm = this.fb.group({
      first_name: [this.userInfo.first_name],
      last_name: [this.userInfo.last_name],
      company: [this.userInfo.company],
      phone: [this.userInfo.phone],
      job_title: [this.userInfo.job_title],
      role: [this.userInfo.role],
    });
  }

  showSecuritySettings(): boolean {
    return this.auth.featureFlags.showPassword;
  }

  saveUserSettings() {
    const accountData: Partial<UserAccount> = this.userForm.value;
    console.log('Saving user settings:', accountData);
    this.updateUserSettings(accountData).subscribe({
      next: () => {
        this.dialogRef.close();
      },
      error: () => {
        this.snackBar.open(`Error updating user settings`, 'OK', {
          duration: 5000,
        });
      },
    });
  }

  updateUserSettings(userData: Partial<UserAccount>): Observable<UserAccount> {
    const userAccountInfo = this.userInfo;
    const updatedUserData = {
      ...userAccountInfo,
      ...userData,
    };
    return this.auth.updateAccount(updatedUserData).pipe(
      tap(() => {
        this.auth.updateAccountInfo(updatedUserData);
      }),
    );
  }
}
