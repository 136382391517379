/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { BreakpointService } from 'src/app/shared/services/breakpoints.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scanner-insights',
  templateUrl: './scanner-insights.component.html',
  styleUrls: ['./scanner-insights.component.scss'],
})
export class ScannerInsightsComponent {
  /**
   * query parameters
   */
  qparam: any;

  /**
   * query parameters refresh
   */
  previousValue: any;

  /**
   * check if there is a company in the tag
   */
  isCompanyTag = false;

  /**
   * check if there is a pne in the tag
   */
  isPneTag = false;

  /**
   * array of events
   */
  events: any;

  /**
   * array of score events
   */
  scoreEvents: any;

  /**
   * score to be displayed on the mosaic (portfolio or entity score based on tag)
   */
  mosaicScore: any;

  /**
   * when there is no data for a particular mosaic
   */
  nodata: boolean;

  /**
   * loading mosaic
   */
  loading: boolean;

  /**
   * when a particular mosaic is ready
   */
  iready: boolean;
  /**
   * screenSize mobile or desktop
   */
  screenSize$ = this.breakpointService.screenSize$;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public auth: AuthService,
    private breakpointService: BreakpointService,
  ) {
    this.route.queryParams.subscribe((qparam) => {
      this.qparam = JSON.parse(JSON.stringify(qparam));

      if (
        this.routing.reFresh(this.qparam, this.previousValue, [
          'update',
          'tag',
          'updatePayload',
          'type',
          'id',
          'time',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks',
          'panels',
          'period',
        ])
      ) {
        if (this.qparam.tag && this.qparam.tag.split(':')[1] === 'company') {
          this.isCompanyTag = true;
          this.isPneTag = false;
        } else if (this.qparam.tag && this.qparam.tag.split(':')[1] === 'pne') {
          this.isCompanyTag = false;
          this.isPneTag = true;
        } else {
          this.isCompanyTag = false;
          this.isPneTag = false;
        }

        this.nodata = false;
        this.loading = true;
        this.iready = false;

        // EVENTS ---------------------------------------------

        if (auth.scorelabSettings.defaultScore === 'sentiment') {
          let paramsChange = this.qparam.portfolioId
            ? Object.assign({}, this.qparam, { portfolio: true })
            : this.qparam;

          if (this.qparam.tag) {
            const tagType = this.qparam.tag.split(':')[1];
            const tagId = this.qparam.tag.split(':')[2];
            paramsChange = Object.assign({}, this.qparam, {
              type: tagType,
              id: tagId,
              requestEntity: true,
            });
          }

          this.yukkApi.isentiment(paramsChange, true).subscribe((result) => {
            this.mosaicScore =
              result !== undefined
                ? (result.sentiment.sentiment * 100).toFixed()
                : undefined;
          });

          this.yukkApi.relatedEvents(paramsChange, 'sub_events').subscribe(
            (result) => {
              this.events = result.events;
              let nonEmpty = false;
              this.events.forEach((item) => {
                if (item.count > 0) {
                  nonEmpty = true;
                }
              });
              this.nodata = false;
              if (nonEmpty) {
                this.iready = true;
              } else {
                this.iready = false;
                this.loading = false;
              }
            },
            () => {
              this.nodata = true;
              this.loading = false;
            },
          );
        }

        // SCORELAB EVENTS ---------------------------------------

        if (auth.scorelabSettings.defaultScore !== 'sentiment') {
          let paramsChange;
          if (this.qparam.tag) {
            const tagType = this.qparam.tag.split(':')[1];
            const tagId = this.qparam.tag.split(':')[2];
            paramsChange = Object.assign({}, this.qparam, {
              type: tagType,
              id: tagId,
              scoreScanner: true,
            });
          } else {
            paramsChange = Object.assign({}, this.qparam, {
              // scorePortfolio: true,
              scoreForPortfolio: true,
              scoreScanner: true,
            });
          }

          this.yukkApi.scoresTimeSeries('score', paramsChange, '').subscribe(
            (result) => {
              this.mosaicScore =
                result !== undefined &&
                result['score_ts'][result['score_ts'].length - 1].score !== null
                  ? result['score_ts'][
                      result['score_ts'].length - 1
                    ].score.toFixed(1)
                  : undefined;
              if (auth.scorelabSettings.defaultScore === 'credit_risk') {
                this.yukkApi
                  .scoresTimeSeries(
                    'score',
                    Object.assign({}, this.qparam, paramsChange, {
                      with_top_events: true,
                      top_events_sign:
                        this.mosaicScore && this.mosaicScore > 0 ? 'neg' : null,
                    }),
                    'chart',
                  )
                  .subscribe(
                    (result2) => {
                      this.scoreEvents = result2.top_events
                        ? result2.top_events
                        : [];
                      let nonEmpty = false;
                      this.scoreEvents.forEach((item) => {
                        if (item.contrib_amount > 0) {
                          nonEmpty = true;
                        }
                      });
                      this.nodata = false;
                      if (nonEmpty) {
                        this.iready = true;
                      } else {
                        this.iready = false;
                        this.loading = false;
                      }
                    },
                    () => {
                      this.nodata = true;
                      this.loading = false;
                    },
                  );
              }
            },
            () => {
              if (auth.scorelabSettings.defaultScore === 'credit_risk') {
                this.nodata = true;
                this.loading = false;
              }
            },
          );
          if (!(auth.scorelabSettings.defaultScore === 'credit_risk')) {
            this.yukkApi
              .scoresTimeSeries(
                'score',
                Object.assign({}, this.qparam, paramsChange, {
                  with_top_events: true,
                }),
                'chart',
              )
              .subscribe(
                (result) => {
                  this.scoreEvents = result.top_events ? result.top_events : [];
                  let nonEmpty = false;
                  this.scoreEvents.forEach((item) => {
                    if (item.contrib_amount > 0) {
                      nonEmpty = true;
                    }
                  });
                  this.nodata = false;
                  if (nonEmpty) {
                    this.iready = true;
                  } else {
                    this.iready = false;
                    this.loading = false;
                  }
                },
                () => {
                  this.nodata = true;
                  this.loading = false;
                },
              );
          }
        }
      }

      if (this.routing.reFresh(this.qparam, this.previousValue, ['newstype'])) {
        if (this.qparam.newstype === 'Events' && !this.qparam.eventid) {
          if (
            this.auth.scorelabSettings.defaultScore !== 'sentiment' &&
            this.scoreEvents !== undefined
          ) {
            const eventsCopy = JSON.parse(JSON.stringify(this.scoreEvents));
            if (eventsCopy.length > 0) {
              const topEvent = eventsCopy.reduce((prev, current) =>
                prev.contrib_amount > current.contrib_amount ? prev : current,
              );
              if (topEvent && topEvent.contrib_amount) {
                this.iScoreEvent(topEvent);
              }
            }
          } else if (
            this.auth.scorelabSettings.defaultScore === 'sentiment' &&
            this.events !== undefined
          ) {
            const eventsCopy = JSON.parse(JSON.stringify(this.events));
            if (eventsCopy.length > 0) {
              const topEvent = eventsCopy.reduce((prev, current) =>
                prev.count > current.count ? prev : current,
              );
              if (topEvent && topEvent.contrib_amount) {
                this.iEvent(topEvent);
              }
            }
          }
        }
      }

      this.previousValue = this.qparam;
    });
  }

  /**
   * percentage of the event bar
   */
  iBaro(array, value) {
    const counts = array.map((event) => event.count);
    const maxcount = Math.max(...counts);
    return (value * 100) / maxcount;
  }

  /**
   * percentage of the scorelab event bar
   */
  iBaroScore(array, value) {
    const scores = array.map((event) => event.contrib_amount);
    const maxscore = Math.max(...scores);
    return (value * 100) / maxscore;
  }

  /**
   * click specific event
   */
  iEvent(item) {
    let eventid = null;
    let eventype = null;
    // let newstype = null;
    const newstype = this.qparam.newstype
      ? this.qparam.newstype
      : this.qparam.tag
        ? 'Events'
        : null;
    if (this.qparam.eventid === item.event.id) {
      item.expand = false;
      // newstype = null;
    } else {
      item.expand = true;
      eventid = item.event.id;
      eventype = item.event.type;
      // newstype = 'Events';
    }
    this.router.navigate([], {
      queryParams: {
        eventid: eventid,
        eventype: eventype,
        newstype: newstype,
        newsday: null,
        eventsFromScanner: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  /**
   * click specific scorelab event
   */
  iScoreEvent(item) {
    let eventid = null;
    let eventype = null;
    let scoreevent = null;
    // let newstype = null;
    const newstype = this.qparam.newstype
      ? this.qparam.newstype
      : this.qparam.tag
        ? 'Events'
        : null;
    if (this.qparam.eventid === item.id) {
      item.expand = false;
      // newstype = null;
    } else {
      item.expand = true;
      eventid = item.id;
      eventype = item.type;
      scoreevent = true;
      // newstype = 'Events';
    }
    this.router.navigate([], {
      queryParams: {
        eventid: eventid,
        eventype: eventype,
        scoreevent: scoreevent,
        newstype: newstype,
        newsday: null,
        eventsFromScanner: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }
}
