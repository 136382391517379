<div class="chat-mobile__navigation">
  <div class="chat-mobile__navigation__header">
    <mat-icon
      class="chat-mobile__navigation__header__icon"
      (click)="closeMobileChatDrawer()"
      >close</mat-icon
    >
    <button
      class="chat__button"
      id="chat-start-new-chat"
      (click)="startNewChat()"
    >
      New Chat
    </button>
  </div>
  <div *ngIf="chats && chats.length > 0">
    <h4 class="chat__h4">Past conversations</h4>
  </div>
  <div class="chats__container">
    <ng-container *ngFor="let chat of chats">
      <div
        class="chat__title__container"
        [ngClass]="{
          'chat__title__container--active': chat.chat_id === activeChatId,
        }"
        (click)="goToChatConversation(chat)"
      >
        <div
          class="chat__title"
          [ngClass]="{
            'chat__title--active': chat.chat_id === activeChatId,
          }"
        >
          {{ chat.title }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
