<div class="main-trending">
  <div class="trending-lhs" [class.mobile]="(screenSize$ | async)?.isMobile">
    <div class="trending-header">
      <div class="trending-header-info">
        <p class="trending-header-info-header">Trending Board</p>
        <p>Filter & sort to identify the top 100 from 45k listed companies.</p>
      </div>

      <div class="trending-header-content">
        <mat-form-field floatLabel="never" class="trending-country">
          <mat-label>Countries</mat-label>
          <input
            type="text"
            placeholder="Select Countries"
            matInput
            [matAutocomplete]="auto"
            [formControl]="countriesControl"
            (focus)="openAutocompletePanel()"
            #autocompleteTrigger="matAutocompleteTrigger"
          />
        </mat-form-field>

        <mat-autocomplete
          #auto="matAutocomplete"
          class="trending-country-autocomplete"
          [displayWith]="displayFnCountries"
        >
          <mat-option
            *ngFor="let country of filteredCountries | async"
            [value]="selectedCountries"
            (click)="$event.stopPropagation()"
          >
            <div>
              <mat-checkbox
                [checked]="country.selected"
                (change)="toggleCountriesSelection(country)"
                (click)="$event.stopPropagation()"
              >
                {{ country.value.toUpperCase() + ' - ' + country.label }}
              </mat-checkbox>
            </div>
          </mat-option>
        </mat-autocomplete>

        <mat-form-field class="trending-index">
          <mat-label>Indices</mat-label>
          <mat-select
            [(ngModel)]="selectedIndexes"
            name="index"
            multiple
            (openedChange)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let index of indices"
              [value]="index"
              (click)="$event.stopPropagation()"
            >
              {{ index }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="trending-industry">
          <mat-label>Industries</mat-label>
          <mat-select
            [(ngModel)]="selectedIndustries"
            name="industry"
            multiple
            (openedChange)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let industry of industries"
              [value]="industry.value"
              (click)="$event.stopPropagation()"
            >
              {{ industry.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="trending-events">
          <mat-label>Events</mat-label>
          <mat-select
            [(ngModel)]="selectedEvents"
            multiple
            (openedChange)="onSelectionChange()"
          >
            <ng-container
              *ngFor="let superevent of events | orderBy: 'entity.name'"
            >
              <mat-optgroup
                class="middleevent"
                *ngFor="
                  let event of superevent['children'] | orderBy: 'entity.name'
                "
                [label]="
                  superevent['entity']['name'] + ' - ' + event['entity']['name']
                "
              >
                <mat-option
                  class="subevent"
                  *ngFor="
                    let subevent of event.children | orderBy: 'entity.name'
                  "
                  [value]="subevent.entity"
                  (click)="$event.stopPropagation()"
                >
                  {{ subevent.entity.name }}
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="trending-time"
          *ngIf="selectedEvents && selectedEvents.length > 0"
        >
          <mat-label>Timeframe</mat-label>
          <mat-select
            [(ngModel)]="selectedTimeHorizon"
            name="time"
            (openedChange)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let time of time_horizon"
              [value]="time.value"
              (click)="$event.stopPropagation()"
            >
              {{ time.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="trending-cont">
      <app-trending-column
        [trending]="entitiesList"
        [searchTerm]="searchTerm"
        [loading]="loading"
        [nodata]="nodata"
      ></app-trending-column>
    </div>
  </div>
</div>
