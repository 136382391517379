import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { BehaviorSubject, of } from 'rxjs';
import {
  ChatDetailsResponse,
  ChatResponse,
} from '../../interfaces/chart-details.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs-compat';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatNavigationService {
  private chatListSubject = new BehaviorSubject<ChatDetailsResponse[]>([]);

  // Public observable for components to subscribe
  chatList$ = this.chatListSubject.asObservable();
  defaultTitle: string = 'New Chat';
  private isLoading = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  fetchChats(): Observable<ChatDetailsResponse[]> {
    if (this.isLoading) {
      return of([]);
    }

    this.isLoading = true;

    return this.auth.getChats().pipe(
      map((data: ChatResponse) => {
        const chats = data.hits;
        this.chatListSubject.next(chats);
        this.isLoading = false;
        return chats;
      }),
      catchError((error) => {
        console.error('Error fetching chats:', error);
        this.chatListSubject.next([]);
        return of([]);
      }),
    );
  }

  startNewChat(): void {
    this.auth.startNewChat(this.defaultTitle).subscribe({
      next: (response) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newChatId = (response as any).uid;
        if (newChatId) {
          this.router
            .navigate([], {
              relativeTo: this.route,
              queryParams: { chatId: newChatId },
              queryParamsHandling: 'merge',
            })
            .then(() => {
              this.fetchChats().subscribe();
            })
            .catch((err) => {
              console.error('Navigation error:', err);
            });
        }
      },
      error: (err) => console.error('Error starting new chat:', err),
    });
  }

  deleteChat(chatId: string): void {
    this.auth.deleteChat(chatId).subscribe({
      next: () => this.fetchChats().subscribe(),
      error: (err) => console.error('Failed to delete chat:', err),
    });
  }

  editChatTitle(chatId: string, title: string): void {
    this.auth.editChatTitle(title, chatId).subscribe({
      next: () => this.fetchChats().subscribe(),
      error: (error) => console.error('Failed to update chat title', error),
    });
  }

  updateChatTitleIfDefaultTitle(chatId: string, title: string): void {
    const chat = this.chatListSubject.value.find((c) => c.chat_id === chatId);
    if (chat && chat.title === this.defaultTitle) {
      this.auth.editChatTitle(title, chatId).subscribe({
        next: () => {
          chat.title = title;
        },
        error: (error) => console.error('Failed to update chat title', error),
      });
    }
  }

  goToChat(chatId: string): void {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: { chatId: chatId },
        queryParamsHandling: 'merge',
      })
      .then(() => {
        // console.log(`Navigated to chat with ID: ${chat.chat_id}`);
      })
      .catch((err) => {
        console.error('Navigation error:', err);
      });
  }
}
