<div
  class="menu-account"
  *ngIf="user"
  [class.inblur]="params.tutorial"
  [class.noblur]="params.tutorial == 'menuaside'"
>
  <ng-container [ngSwitch]="params.sidenav">
    <div class="navcont" *ngSwitchDefault>
      <div class="account-container">
        <div *ngIf="auth.featureFlags.showEmail" class="account">
          <div class="email">
            <span [matTooltip]="user.email" matTooltipPosition="above">{{
              user.email
            }}</span>
            <mat-icon
              *ngIf="routing.isDev()"
              class="dev-token"
              matTooltip="Copy user's token to clipboard"
              matTooltipPosition="above"
              (click)="getToken()"
              >account_circle</mat-icon
            >
            <mat-icon *ngIf="!routing.isDev()">account_circle</mat-icon>
          </div>
        </div>

        <div class="navbtn">
          <button
            *ngIf="routing.isDev()"
            mat-raised-button
            [routerLink]="[]"
            [queryParams]="{ sidenav: null }"
            queryParamsHandling="merge"
            (click)="inTutorial()"
          >
            <span>{{ 'MENU_ACCOUNT.TUTORIAL' | translate }}</span>
            <mat-icon>help</mat-icon>
          </button>
          <button
            mat-raised-button
            routerLink="/stats"
            *ngIf="
              auth.featureFlags.showStats &&
              (user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN'))
            "
          >
            <span>{{ 'MENU_ACCOUNT.STATS' | translate }}</span>
            <mat-icon>table_chart</mat-icon>
          </button>
          <button
            mat-raised-button
            routerLink="/admin"
            *ngIf="
              auth.featureFlags.showAdmin &&
              (user.roles?.includes('ADMIN') ||
                user.roles?.includes('REVIEW') ||
                user.groups?.includes('ADMIN') ||
                user.groups?.includes('REVIEW'))
            "
          >
            <span>{{ 'MENU_ACCOUNT.REPORTS' | translate }}</span>
            <mat-icon>assignment_late</mat-icon>
          </button>
          <button
            mat-raised-button
            (click)="onUserAdministration()"
            *ngIf="
              auth.featureFlags.showAdmin &&
              (user.roles?.includes('ADMIN') || user.groups?.includes('ADMIN'))
            "
          >
            <span>{{ 'MENU_ACCOUNT.ADMIN' | translate }}</span>
            <mat-icon>recent_actors</mat-icon>
          </button>
          <button
            mat-raised-button
            [routerLink]="[]"
            [queryParams]="{ sidenav: null }"
            queryParamsHandling="merge"
            (click)="openUserSettingsPopup()"
          >
            <span>Settings</span>
            <mat-icon>settings</mat-icon>
          </button>
          <button
            *ngIf="
              auth.featureFlags.showLogout && !config.appConfig.urlLoginRedirect
            "
            class="logout"
            mat-raised-button
            routerLink="/logout"
          >
            <span>{{ 'MENU_ACCOUNT.LOGOUT' | translate }}</span>
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
